import { Injectable } from "@angular/core";
import { Event, Util } from "@auvious/common";
import {
  canvasToBlob,
  captureBlobImage,
  merge as imageMerge,
  mirror,
  useCanvas,
} from "@auvious/media-tools";
import { IEndpoint, IStream } from "@auvious/rtc";
import {
  ISnapshot,
  SnapshotAcquiredEvent,
  SnapshotUploadedEvent,
  SnapshotCameraRequestedEvent,
  SnapshotRequestedEvent,
  SnapshotService as RTCSnapshotService,
  IUploadSnapshotParams,
} from "@auvious/snapshot";
import { BehaviorSubject, merge, Subject } from "rxjs";
import { distinctUntilChanged, filter, switchAll } from "rxjs/operators";

import {
  DEFAULT_SNAPSHOT_TYPES,
  StreamTrackKindEnum,
  TorchStateEnum,
  UserCapabilityEnum,
  UserRoleEnum,
  VideoFacingModeEnum,
} from "../core-ui.enums";
import {
  AgentParam,
  IInteraction,
  ISnapshotMetadata,
  IStreamMetadata,
  ITag,
  PublicParam,
} from "../models";
import { BaseEvent } from "../models/IEvent";
import { SnapshotMetadata } from "../models/Metadata";
import { ActivityIndicatorService } from "./activity-indicator.service";
import { AppConfigService } from "./app.config.service";
import { ApplicationService } from "./application.service";
import { ConferenceService } from "./conference.service";
import { LocalMediaService } from "./local.media.service";
import { NotificationService } from "./notification.service";
import { AuviousRtcService } from "./rtc.service";
import { SketchService } from "./sketch.service";
import { UserService } from "./user.service";
import { base64ToBlob, debug, debugError } from "./utils";

export class SnapshotActivatedEvent extends BaseEvent {
  public static type = "SnapshotActivatedEvent";
  constructor(
    public targetUserId: string,
    public targetUserEndpointId: string,
    public senderUserId: string,
    public senderEndpointId: string
  ) {
    super("SnapshotActivatedEvent");
  }
}

export class SnapshotDeactivatedEvent extends BaseEvent {
  public static type = "SnapshotDeactivatedEvent";
  constructor(
    public targetUserId: string,
    public targetUserEndpointId: string,
    public senderUserId: string,
    public senderEndpointId: string
  ) {
    super("SnapshotDeactivatedEvent");
  }
}

const ERROR_TORCH_NOT_SUPPORTED = "torch-not-supported";

@Injectable()
export class SnapshotService {
  private _requested = new Subject<IStream>();
  public requested$ = this._requested.asObservable();

  private _started = new Subject<SnapshotActivatedEvent>();
  public started$ = this._started.asObservable();

  private _ended = new Subject<SnapshotDeactivatedEvent>();
  public ended$ = this._ended.asObservable();

  private _review = new Subject<void>();
  public reviewing$ = this._review.asObservable();

  private _snapshotsAvailable = new BehaviorSubject<ISnapshot[]>([]);
  public snapshotsAvailable$ = this._snapshotsAvailable.asObservable();

  private _torchStageChanged = new BehaviorSubject<{
    endpoint: string;
    state: TorchStateEnum;
  }>({ endpoint: null, state: null });
  public torchStateChanged$ = this._torchStageChanged.asObservable();

  private _cameraFacingModeStateChanged = new BehaviorSubject<{
    endpoint: string;
    success: boolean;
  }>({ endpoint: null, success: null });
  public cameraFacingModeStateChanged$ =
    this._cameraFacingModeStateChanged.asObservable();

  private snapshotMap = new Map<string, ISnapshot>();
  private torchMap = new Map<string, boolean>();
  private snapshotMetadataMap = new Map<string, ISnapshotMetadata>();
  private snapshotSegmentMap = new Map<string, string>();

  private targetEndpoint: IEndpoint;
  private requesterEndpoint: IEndpoint;

  private orderIndex = 0;
  private reviewResolve;
  private isAgent;

  constructor(
    private remoteCameraService: RTCSnapshotService,
    private applicationService: ApplicationService,
    private notification: NotificationService,
    private rtcService: AuviousRtcService,
    private conferenceService: ConferenceService,
    private local: LocalMediaService,
    private config: AppConfigService,
    private activityService: ActivityIndicatorService,
    private sketchService: SketchService,
    userService: UserService
  ) {
    this.isAgent = userService.getActiveUser()?.hasRole(UserRoleEnum.agent);

    this.rtcService
      .getEventObservableAvailable()
      .pipe(
        switchAll(),
        filter(
          ({
            payload: ev,
          }: Event<SnapshotRequestedEvent | SnapshotCameraRequestedEvent>) =>
            (ev.type === "SnapshotRequestedEvent" ||
              ev.type === "SnapshotCameraRequestedEvent") &&
            ev.targetUserId === this.rtcService.identity() &&
            ev.targetUserEndpointId === this.rtcService.endpoint()
        ),
        distinctUntilChanged((x, y) => x.id === y.id)
      )
      .subscribe((e) => {
        switch (e.payload.type) {
          case "SnapshotRequestedEvent":
            this.acquireSnapshot(e.payload as SnapshotRequestedEvent);
            break;
          case "SnapshotCameraRequestedEvent":
            this.handleCameraRequestEvent(
              e.payload as SnapshotCameraRequestedEvent
            );
            break;
        }
      });

    merge(
      this.conferenceService.streamMutedChange$,
      this.local.streamMutedChange$
    )
      .pipe(
        filter(
          (s) =>
            s.muted &&
            s.trackKind === StreamTrackKindEnum.video &&
            this.isTarget(s.stream?.originator.endpoint) &&
            this.isRequester(this.myself.username)
        )
      )
      .subscribe((mute) => {
        this.stop(mute.stream.originator);
      });

    this.remoteCameraService
      .events()
      .pipe(filter((e) => e.type === "SnapshotUploadedEvent"))
      .subscribe(async (e: SnapshotUploadedEvent) => {
        debug("snapshot uploaded", e);

        const removed = this.snapshotMap.get(e.relatedSnapshotId);

        const snapshots = await this.remoteCameraService.findByInteractionId(
          this.snapshotSegmentMap.get(e.relatedSnapshotId)
        );
        const updated = snapshots.find((s) => s.id === e.snapshotId);
        updated.type = removed.type;

        this.discardSnapshot(removed);
        this.snapshotMap.set(updated.id, updated);
        this.notifySnapshotsChanged();

        this.snapshotSegmentMap.delete(e.relatedSnapshotId);
      });

    this.remoteCameraService
      .events()
      .pipe(
        filter(
          (e: SnapshotAcquiredEvent) =>
            e.type === "SnapshotAcquiredEvent" &&
            e.requesterUserId !== this.myself.username &&
            e.userId !== this.myself.username &&
            this.isAgent
        )
      )
      .subscribe((e: SnapshotAcquiredEvent) => {
        const snapshot: ISnapshot = {
          id: e.id,
          type: e.type,
          userId: e.userId,
          userEndpointId: e.userEndpointId,
          requesterUserEndpointId: e.requesterUserEndpointId,
          requesterUserId: e.requesterUserId,
          approved: false,
          signedUrl: null,
          state: "UPLOADED",
        };
        this.snapshotMap.set(snapshot.id, snapshot);
        this.notifySnapshotsChanged();
      });

    this.conferenceService.conferenceMetadataSet$
      .pipe(filter((data) => data instanceof SnapshotMetadata))
      .subscribe((data: SnapshotMetadata) => {
        const action = new SnapshotActivatedEvent(
          data.target.username,
          data.target.endpoint,
          data.userId,
          data.userEndpointId
        );
        this.requesterEndpoint = {
          username: data.userId,
          endpoint: data.userEndpointId,
        };
        this.targetEndpoint = {
          endpoint: data.target.endpoint,
          username: data.target.username,
        };
        this._started.next(action);
        this.activityService.showIconMessage("camera", "Snapshot activated", 1);
      });
    this.conferenceService.conferenceMetadataRemoved$
      .pipe(filter((data) => data instanceof SnapshotMetadata))
      .subscribe((data: SnapshotMetadata) => {
        const action = new SnapshotDeactivatedEvent(
          data.target.username,
          data.target.endpoint,
          data.userId,
          data.userEndpointId
        );
        this.requesterEndpoint = null;
        this.targetEndpoint = null;
        this._ended.next(action);

        this.activityService.showIconMessage(
          "camera",
          "Snapshot deactivated",
          1
        );
      });

    this.conferenceService.endpointLeft$
      .pipe(
        filter(
          (s) => this.isTarget(s?.endpoint) || this.isRequester(s?.username)
        )
      )
      .subscribe((s) => this.stop(s));

    this.conferenceService.streamRemoved$
      .pipe(
        filter(
          (s) =>
            this.isTarget(s?.originator.endpoint) ||
            this.isRequester(s?.originator.username)
        )
      )
      .subscribe((s) => this.stop(s.originator));
  }

  // getters ------------>

  public get snapshotTypes(): ITag[] {
    return this.config.agentParamEnabled(
      AgentParam.SNAPSHOT_DEFAULT_TYPES_APPENDED
    )
      ? [
          ...this.config.agentParam(AgentParam.SNAPSHOT_TYPES),
          ...DEFAULT_SNAPSHOT_TYPES,
        ]
      : this.config.agentParam(AgentParam.SNAPSHOT_TYPES)?.length === 0
      ? [...DEFAULT_SNAPSHOT_TYPES]
      : [...this.config.agentParam(AgentParam.SNAPSHOT_TYPES)];
  }
  public isTarget(endpoint: string): boolean {
    return !!endpoint && this.targetEndpoint?.endpoint === endpoint;
  }
  public isRequester(username: string): boolean {
    return !!username && this.requesterEndpoint?.username === username;
  }
  public get isStarted() {
    return !!this.targetEndpoint;
  }

  public get snapshotsTakenByMe(): boolean {
    return (
      Array.from(this.snapshotMap.values()).filter(
        (s) => s.requesterUserId === this.myself.username
      ).length > 0
    );
  }

  public isTorchSupported(id: string): boolean {
    const stream = this.conferenceService.getStream(id);
    if (!stream) {
      return false;
    }
    const metadata: IStreamMetadata = stream.getMetadata();
    if (!metadata?.video?.capabilities) {
      return false;
    }
    return "torch" in metadata.video.capabilities;
  }

  public get isCameraSwitchAvailable() {
    return this.config.agentParamEnabled(
      AgentParam.SNAPSHOT_SWITCH_CUSTOMER_CAM_ENABLED
    );
  }

  public hasMutlipleCameras(participant: IEndpoint) {
    const p = this.conferenceService.getParticipants()?.[participant.endpoint];

    return !p
      ? false
      : p.metadata?.mediaDevices.filter((m) => m.kind === "videoinput").length >
          1;
  }

  public supported(originator: IEndpoint): boolean {
    const endpoint =
      this.conferenceService.getParticipants()?.[originator.endpoint];
    if (!endpoint) {
      return false;
    }
    const roles = endpoint?.metadata?.roles || [];
    const capabilities = endpoint?.metadata?.capabilities || [];

    const hasCustomerRole = roles.includes(UserRoleEnum.customer);
    const hasCapability = capabilities.includes(UserCapabilityEnum.snapshot);

    return hasCustomerRole && hasCapability;
  }

  // public ------------->

  public async load(interaction: IInteraction) {
    try {
      const snapshots = await this.remoteCameraService.findByInteractionId(
        interaction.getId()
      );
      for (let i = 0; i < snapshots.length; i++) {
        const s = snapshots[i];
        // if we have a state of null, then no images were uploaded
        if (!s.state || s.state === "DISCARDED") {
          continue;
        }
        const metadata: ISnapshotMetadata = { order: i };
        if (s.type === "undefined" || s.type === "null") {
          s.type = null;
        }
        this.snapshotMap.set(s.id, s);
        this.snapshotMetadataMap.set(s.id, metadata);
      }
      this.orderIndex = snapshots.length;
      this.notifySnapshotsChanged();
    } catch (ex) {
      debug(ex);
    }
  }

  public findByInteractionId(id: string) {
    return this.remoteCameraService.findByInteractionId(id);
  }

  /** request snapshot session for stream id */
  public request(id: string) {
    const stream = this.conferenceService.getStream(id);

    if (stream) {
      this._requested.next(stream);
    }
  }

  public start(id: string) {
    const stream = this.conferenceService.getStream(id);

    if (stream) {
      this.targetEndpoint = stream.originator;
      this.requesterEndpoint = this.myself;
      this.sendSnapshotStateChangedEvent(stream.originator.endpoint, true);
    }
  }

  public stop(originator: IEndpoint) {
    this.sendSnapshotStateChangedEvent(originator.endpoint, false);

    const target = this.targetEndpoint;
    const requester = this.requesterEndpoint;
    this.targetEndpoint = null;
    this.requesterEndpoint = null;

    this._ended.next(
      new SnapshotDeactivatedEvent(
        target.username,
        target.endpoint,
        requester.username,
        requester.endpoint
      )
    );
  }

  public destroy() {
    this.snapshotMap.clear();
    this.torchMap.clear();
    this.snapshotMetadataMap.clear();

    this.targetEndpoint = null;
    this.requesterEndpoint = null;

    // reset behaviourSubject observables
    // this.requestedSubject.next(null);
    this._snapshotsAvailable.next([]);
    this._torchStageChanged.next({ endpoint: null, state: null });
    this._cameraFacingModeStateChanged.next({
      endpoint: null,
      success: null,
    });
  }

  public async review(): Promise<void> {
    return new Promise((resolve) => {
      this.reviewResolve = resolve;
      this._review.next();
    });
  }

  public async requestSnapshot(
    participant: IEndpoint,
    interaction: IInteraction,
    type?: string
  ): Promise<ISnapshot> {
    const tempSnap: ISnapshot = {
      id: Util.uuidgen(),
      type,
      userId: participant.username,
      userEndpointId: participant.endpoint,
      requesterUserEndpointId: this.myself.endpoint,
      requesterUserId: this.myself.username,
      approved: false,
      signedUrl: null,
      state: null,
    };
    this.snapshotMap.set(tempSnap.id, tempSnap);
    const order = this.orderIndex++;
    this.setSnapshotMedatata(tempSnap, { order });

    this.notifySnapshotsChanged();

    try {
      const snapshot = await this.remoteCameraService.requestSnapshot({
        applicationId: this.applicationService.getActiveApplication()?.getId(),
        targetEndpoint: participant,
        interactionId: interaction.getId(),
        sessionId: interaction.getRoom(),
        sessionType: "CONFERENCE",
        timeoutSeconds: 60 * 1000,
        // snapshotType: null // 'CAMERA',
      });
      snapshot.type = type;
      // transfer metadata
      const metadata = this.getSnapshotMetadata(tempSnap.id);
      this.setSnapshotMedatata(snapshot, metadata);
      this.snapshotMetadataMap.delete(tempSnap.id);

      // remove temp and add new
      this.snapshotMap.delete(tempSnap.id);
      this.snapshotMap.set(snapshot.id, snapshot);
      this.notifySnapshotsChanged();
      return snapshot;
    } catch (ex) {
      debugError(ex);
      this.snapshotMap.delete(tempSnap.id);
      this.notifySnapshotsChanged();
      this.notification.error("Snapshot failed", { body: ex.message || ex });
    }
  }

  public tagSnapshot(snapshot: ISnapshot, tag: ITag) {
    snapshot.type = tag.value;
    this.snapshotMap.set(snapshot.id, snapshot);
    this.notifySnapshotsChanged();
  }

  public unTagSnapshot(snapshot: ISnapshot) {
    snapshot.type = null;
    this.snapshotMap.set(snapshot.id, snapshot);
    this.notifySnapshotsChanged();
  }

  public skipApproval() {
    this.reviewResolve?.();
  }

  public approveSnapshots(snapshots: ISnapshot[]): Promise<void> {
    return Promise.all(
      snapshots.map((s) =>
        this.remoteCameraService
          .approve(s, s.type)
          .then((_) => s)
          .catch((ex) => ex)
      )
    ).then((response) => {
      response.forEach((s) => {
        // filter out the failed ones
        if (!(s instanceof Error)) {
          s.state = "APPROVED";
          this.snapshotMap.set(s.id, s);
        }
      });
      this.notifySnapshotsChanged();
      // check if all snapshots were approved. if not, return error
      const completedCount = response.filter(
        (s) => !(s instanceof Error)
      ).length;
      if (completedCount === snapshots.length) {
        this.reviewResolve?.();
      } else {
        const error = response.find((s) => s instanceof Error);
        throw error || Error("Some failed. Please try again");
      }
    });
  }

  public async approveSnapshot(snapshot: ISnapshot, type?: string) {
    try {
      await this.remoteCameraService.approve(snapshot, type);
      snapshot.state = "APPROVED";
      snapshot.type = type ? type : snapshot.type;
      this.snapshotMap.set(snapshot.id, snapshot);
      this.notifySnapshotsChanged();
    } catch (ex) {
      debugError(ex);
      this.notification.error("Could not approve snapshot", {
        body: ex.message || ex,
      });
    }
  }

  public async discardSnapshot(snapshot: ISnapshot) {
    try {
      this.snapshotMap.delete(snapshot.id);
      this.notifySnapshotsChanged();
      await this.remoteCameraService.discard(snapshot);
      return;
    } catch (ex) {
      debugError(ex);
      this.notification.error("Could not discard snapshot", {
        body: ex.message || ex,
      });
    }
  }

  public setSnapshotMedatata(snapshot: ISnapshot, metadata: ISnapshotMetadata) {
    const existingMetadata = this.getSnapshotMetadata(snapshot.id);
    this.snapshotMetadataMap.set(snapshot.id, {
      ...existingMetadata,
      ...metadata,
    });
    debug("snapshot metadata updated ", this.snapshotMetadataMap);
  }

  public getSnapshotMetadata(id: string): ISnapshotMetadata {
    return this.snapshotMetadataMap.get(id);
  }

  /** private */

  private notifySnapshotsChanged() {
    const arr = Array.from(this.snapshotMap.values()).sort(
      (a, b) =>
        this.getSnapshotMetadata(a.id)?.order -
          this.getSnapshotMetadata(b.id)?.order || 0
    );
    this._snapshotsAvailable.next(arr);
  }

  private sendSnapshotStateChangedEvent(targetEndpointId, enabled: boolean) {
    const endpoints = this.conferenceService.getParticipants();
    const targetEndpoint = endpoints[targetEndpointId];
    // target may have left the moment we send the event
    if (!targetEndpoint) {
      return;
    }

    const { username, endpoint } = targetEndpoint;

    // update conference metadata
    const meta = new SnapshotMetadata(
      this.conferenceService.myself,
      { username, endpoint } // filters out targetEndpoint extra metadata
    );

    if (enabled) {
      this.conferenceService.setConferenceMetadata(meta);
    } else {
      this.conferenceService.removeConferenceMetadata(meta.key);
    }
  }

  private async acquireSnapshot(ev: SnapshotRequestedEvent): Promise<void> {
    // todo: add support for screen share snapshot
    if (
      this.conferenceService.localMediaStream?.getVideoTracks().length === 0
    ) {
      // todo: notify of failure?
      return;
    }
    this.notification.info("Click!", { body: "Snapshot taken" });
    // errors handled by sentry
    const stream = this.conferenceService.localStream;
    const video = this.conferenceService.getElementById(
      stream.id
    ) as HTMLVideoElement;
    const sketch = this.sketchService.getInstanceByTarget(this.myself.endpoint);

    let blob: Blob;

    if (sketch) {
      let source = useCanvas(video);

      if (
        this.conferenceService.getFacingModeForStream(stream) ===
        VideoFacingModeEnum.User
      ) {
        source = mirror(source);
      }

      blob = await canvasToBlob(imageMerge(source, sketch.snapshot()));
    } else {
      blob = await captureBlobImage(video);
    }

    await this.remoteCameraService.acquireSnapshot(ev, blob);
  }

  public async updateSnapshot(
    snapshot: ISnapshot,
    interaction: IInteraction,
    base64: string
  ) {
    this.snapshotSegmentMap.set(snapshot.id, interaction.getId());

    const blob = base64ToBlob(base64);
    const params: IUploadSnapshotParams = {
      blob,
      applicationId: this.applicationService.getActiveApplication()?.getId(),
      interactionId: interaction.getId(),
      sessionId: interaction.getRoom(),
      sessionType: "CONFERENCE",
      timeoutSeconds: 60 * 1000,
      snapshotType: snapshot.type,
      targetEndpoint: {
        username: snapshot.userId,
        endpoint: snapshot.userEndpointId,
      },
      relatedSnapshotId: snapshot.id,
    };
    try {
      await this.remoteCameraService.uploadSnapshot(params);
    } catch (ex) {
      throw ex;
    }
  }

  /*** switch cam */

  public async requestSwitchCamera(originator: IEndpoint) {
    try {
      await this.remoteCameraService.requestCameraSwitch(originator);
      // when camera is switched, torched is auto-turned off
      this.torchMap.set(originator.endpoint, false);
      this._cameraFacingModeStateChanged.next({
        endpoint: originator.endpoint,
        success: true,
      });
    } catch (ex) {
      this.notification.error("Camera switch failed", {
        body: ex.message || ex,
      });
      this._cameraFacingModeStateChanged.next({
        endpoint: originator.endpoint,
        success: false,
      });
    }
  }

  /*** Torch  ***/

  public async requestTorchChange(originator: IEndpoint) {
    const on = this.torchMap.get(originator.endpoint) || false;
    try {
      await this.remoteCameraService.requestCameraTorch(originator, !on);
      this.torchMap.set(originator.endpoint, !on);
      this._torchStageChanged.next({
        endpoint: originator.endpoint,
        state: !on ? TorchStateEnum.off : TorchStateEnum.off,
      });
    } catch (ex) {
      let body = ex.message || ex;
      if (ex.message === ERROR_TORCH_NOT_SUPPORTED) {
        body = "The remote device does not provide access to the torch.";
      }
      this.notification.error("Torch failed", { body });
      this._torchStageChanged.next({
        endpoint: originator.endpoint,
        state: TorchStateEnum.error,
      });
    }
  }

  private async handleCameraRequestEvent(ev: SnapshotCameraRequestedEvent) {
    try {
      switch (ev.cameraRequestType) {
        case "CAMERA_SWITCH":
          await this.local.switchCamera();
          break;
        case "FLASH_ON":
          await this.setTorch(true);
          break;
        case "FLASH_OFF":
          await this.setTorch(false);
          break;
        default:
          throw new Error(`unknown camera request ${ev.cameraRequestType}`);
      }
      this.remoteCameraService.cameraRequestSuccessReport(ev);
    } catch (error) {
      let additionalInformation = `camera request of type ${ev.cameraRequestType} failed`;
      if (error instanceof Error) {
        additionalInformation = `${additionalInformation}: ${error.name}:${error.message}`;
      } else if (error instanceof String) {
        additionalInformation = `${additionalInformation}: $error`;
      }
      this.remoteCameraService.cameraRequestFailureReport(
        ev,
        error.message || error
      );
      debugError("error on cam request", additionalInformation);
    }
  }

  private async setTorch(on: boolean) {
    const track = this.conferenceService.localMediaStream.getVideoTracks()?.[0];
    if (!track) {
      throw new Error("No video track found on Torch request");
    }
    return this.toggleTorchForMediaStreamTrack(track, on);
  }

  private async toggleTorchForMediaStreamTrack(
    track: MediaStreamTrack,
    value = true
  ) {
    const capabilities = track.getCapabilities();

    if (capabilities?.torch) {
      await track.applyConstraints({
        advanced: [{ torch: value }],
      } as any);
    } else {
      throw new Error(ERROR_TORCH_NOT_SUPPORTED);
    }
  }

  private get myself(): IEndpoint {
    return this.rtcService.myself;
  }
}
