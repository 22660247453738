import { ApiResource, PagedCollection } from "@auvious/common";
import {
  ITranscriptStatus,
  ITranscriptTransformStatus,
} from "../../interfaces";
import {
  ITranscriptTransformOptions,
  ITranscriptStrategy,
  ITranscriptTransformStrategy,
  IProviderModel,
  IProvisionLanguageOptions,
  IProvisionedModel,
} from "../ITranscriptStrategy";
import {
  ApplicationService,
  AuviousRtcService,
  GenericErrorHandler,
} from "../../../services";
import {
  ApplicationProviderTypeEnum,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../../core-ui.enums";
import {
  IOrganizationLanguage,
  IProviderLanguages,
  OrganizationLanguage,
  SpeechToTextProviderType,
} from "@auvious/asr";
import {
  IProviderLanguage,
  ProviderOrganizationLanguage,
} from "../../ProviderLanguage";
import { IVisionStrategy } from "../IVisionStrategy";

interface IOpenAITranscriptStatus {
  id: string;
  conversationId: string;
  state: TranscriptStateEnum;
  language: string;
  createdBy: string;
  createdAt: Date;
}

interface IOpenAIProvisionedLanguages {
  languages: { code: string; provider: ApplicationProviderTypeEnum }[];
}

class OpenAITranscriptStatus implements ITranscriptStatus {
  constructor(private entity: IOpenAITranscriptStatus) {}
  get id() {
    return this.entity.id;
  }
  get state() {
    return this.entity.state;
  }
  get language() {
    return this.entity.language;
  }
  get createdAt() {
    return new Date(this.entity.createdAt);
  }
}

export class OpenAITranscriptStrategy
  implements ITranscriptStrategy, ITranscriptTransformStrategy, IVisionStrategy
{
  private resource: ApiResource;
  private apiEntityMap: Record<TranscriptTransformType, string> = {
    sentiment: "sentiments",
    summary: "summaries",
    translation: "translations",
  };

  // private languageProviderMap: Record<SpeechToTextProviderType, string> = {
  //   GOOGLE: "google",
  //   OPEN_AI: "openai",
  //   AMAZON: "amazon",
  //   MICROSOFT: "microsoft",
  // };

  constructor(
    private logger: GenericErrorHandler,
    rtc: AuviousRtcService,
    private application: ApplicationService
  ) {
    rtc.common$.subscribe((c) => {
      this.resource = c.apiResourceFactory("api/ai/");
    });
  }

  private get appID() {
    return this.application.getActiveApplication().getId();
  }

  createTranscriptRequest(
    conversationId: string,
    organizationLanguageId: string
  ): Promise<{ conversationId: string; id: string }> {
    return this.resource
      .create(
        { language: organizationLanguageId },
        {
          urlPostfix: [
            this.appID,
            "conversations",
            conversationId,
            "transcriptions",
          ].join("/"),
        }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  async getTranscriptsForConversation(
    conversationId: string
  ): Promise<ITranscriptStatus[]> {
    try {
      const response: { transcriptions: IOpenAITranscriptStatus[] } =
        await this.resource.get({
          urlPostfix: [
            this.appID,
            "conversations",
            conversationId,
            "transcriptions",
          ].join("/"),
        });
      return response.transcriptions.map((t) => new OpenAITranscriptStatus(t));
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranscriptStatusForConversation(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscriptStatus> {
    try {
      const response: IOpenAITranscriptStatus = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
        ].join("/"),
      });
      return new OpenAITranscriptStatus(response);
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  public getTranscriptURL(
    conversationId: string,
    transcriptId: string,
    type: "inline" | "attachment"
  ): Promise<{ url: string; validUntil: string }> {
    return this.resource
      .get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "content",
        ].join("/"),
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  removeTranscript(conversationId: string, transcriptId: string) {
    return this.resource
      .delete({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
        ].join("/"),
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public async getProvisionedLanguages(
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IOrganizationLanguage>> {
    try {
      // const [all, provisioned] = await Promise.all([
      //   this.getProviderLanguages(
      //     ApplicationProviderTypeEnum.OPEN_AI,
      //     "transcriptions",
      //     page,
      //     pageSize
      //   ),
      //   this.resource.get({
      //     urlPostfix: [this.appID, "languages"].join("/"),
      //   }),
      // ]);
      const provisioned = await this.resource.get({
        urlPostfix: [this.appID, "languages"].join("/"),
      });

      const languages = (
        provisioned as IOpenAIProvisionedLanguages
      ).languages.map((language) => {
        return new OrganizationLanguage({
          languageName: language.code,
          // languageName: all.content.find(
          //   (l) => l.id.languageCode === language.code
          // )?.languageName,
          languageCode: language.code,
          organizationLanguageId: language.code,
          translateProviderType: ApplicationProviderTypeEnum.OPEN_AI,
        });
      });

      return new PagedCollection<IOrganizationLanguage>({
        content: languages,
        totalElements: languages.length,
        totalPages: 1,
        size: languages.length,
        number: 0,
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getProviderLanguages(
    usage: "translations" | "transcriptions",
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IProviderLanguages>> {
    try {
      const response: { [code: string]: string } = await this.resource.get({
        urlPostfix: [
          this.appID,
          "providers",
          ApplicationProviderTypeEnum.OPEN_AI.toLowerCase(),
          "languages",
          usage,
        ].join("/"),
      });

      const languages: IProviderLanguages[] = Object.keys(response).map(
        (code) => {
          return {
            id: {
              languageCode: code,
              providerType: SpeechToTextProviderType.OPEN_AI,
            },
            languageName: response[code],
            model: ApplicationProviderTypeEnum.OPEN_AI,
          };
        }
      );

      return new PagedCollection<IProviderLanguages>({
        content: languages,
        totalElements: languages.length,
        totalPages: 1,
        size: languages.length,
        number: 0,
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  provisionLanguage(
    language: IProviderLanguage,
    options: IProvisionLanguageOptions
  ): Promise<{ organizationLanguageId: string }> {
    return this.resource
      .create(
        {
          provider: ApplicationProviderTypeEnum.OPEN_AI,
          code: language.code,
          // setDefault: options.setDefault || "transcription",
        },
        { urlPostfix: [this.appID, "languages"].join("/") }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  removeProvisionedLanguage(
    provider: ApplicationProviderTypeEnum,
    language: ProviderOrganizationLanguage
  ) {
    return this.resource
      .delete({
        urlPostfix: [
          this.appID,
          "providers",
          provider,
          "languages",
          language.code,
        ].join("/"),
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  async getProviderModels(
    providerType: ApplicationProviderTypeEnum
  ): Promise<IProviderModel[]> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "providers",
          providerType.toLowerCase(),
          "models",
        ].join("/"),
      });
      return response.models;
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
    }
  }

  async getProvisionedModel(): Promise<IProvisionedModel[]> {
    try {
      const response = await this.resource.get({
        urlPostfix: [this.appID, "models"].join("/"),
      });
      return response.models;
    } catch (ex) {
      if (ex.response?.status === 404) {
        return [];
      }
      this.logger.handleNotAuthenticatedError(ex);
    }
  }

  provisionModel(
    provider: ApplicationProviderTypeEnum,
    model: IProvisionedModel
  ): Promise<{ organizationModelId: string }> {
    return this.resource
      .create(
        { provider, id: model.id, setDefault: model.usage },
        { urlPostfix: [this.appID, "models"].join("/") }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  removeProvisionedModel(
    provider: ApplicationProviderTypeEnum,
    model: IProvisionedModel
  ): Promise<void> {
    return this.resource
      .delete({
        urlPostfix: [this.appID, "models", model.id].join("/"),
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  /** transformations */

  transformTranscriptForConversation(
    conversationId: string,
    transcriptId: string,
    type: TranscriptTransformType,
    options?: ITranscriptTransformOptions
  ) {
    return this.resource
      .create(options, {
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          this.apiEntityMap[type],
        ].join("/"),
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  async getTransformsForConversation(
    conversationId: string,
    transcriptId: string,
    transform: TranscriptTransformType
  ): Promise<ITranscriptTransformStatus[]> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          this.apiEntityMap[transform],
        ].join("/"),
      });
      return response[this.apiEntityMap[transform]];
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTransformStatusForConversation(
    conversationId: string,
    transcriptId: string,
    transformId: string,
    transform: TranscriptTransformType
  ): Promise<ITranscriptTransformStatus> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          this.apiEntityMap[transform],
          transformId,
        ].join("/"),
      });
      return response[this.apiEntityMap[transform]];
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  getTransformURL(
    conversationId: string,
    transcriptId: string,
    transformId: string,
    transform: TranscriptTransformType
  ) {
    return this.resource
      .get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          this.apiEntityMap[transform],
          transformId,
          "content",
        ].join("/"),
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  removeTransform(
    conversationId: string,
    transcriptId: string,
    transformId: string,
    transformType: TranscriptTransformType
  ): Promise<void> {
    return this.resource
      .delete({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          this.apiEntityMap[transformType],
          transformId,
        ].join("/"),
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  isFeatureSupported(feature: TranscriptTransformType): boolean {
    return ["sentiment", "summary", "translation"].includes(feature);
  }

  /** vision */
  recognize(prompt: string, imageBase64: string, model?: string) {
    return this.resource
      .create(
        {
          prompt,
          image_url: imageBase64,
          model,
        },
        {
          urlPostfix: [this.appID, "vision", "recognition"].join("/"),
        }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }
}
