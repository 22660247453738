<ng-container *ngIf="!!snapshot()">
  <app-snapshot
    [size]="videoSize()"
    [snapshot]="snapshot()"
    (ready)="snapshotReady($event)"
    (error)="snapshotError($event)"
  ></app-snapshot>
</ng-container>

{{ state()?.state }}
<ng-container *ngIf="!!result()">
  <div class="vision-message" [innerHTML]="result() | markdown"></div>
  <div class="vision-disclaimer">
    &centerdot; <span translate>Check important info for mistakes</span>
  </div>
</ng-container>
