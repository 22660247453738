import { Component, OnDestroy, OnInit, signal } from "@angular/core";
import { SnapshotService } from "../../../../core-ui/services";
import { filter, map, mergeMap, Subscription } from "rxjs";
import { ISnapshot } from "@auvious/snapshot";
import { IArea, IVisionSnapshotState } from "../../../../core-ui/models";
import { VisionService } from "../../../../core-ui/services/vision.service";
// import { SNAPSHOT_VISION_TYPE } from "../../../../core-ui/core-ui.enums";

@Component({
  selector: "av-vision",
  templateUrl: "./vision.component.html",
  styleUrls: ["./vision.component.scss"],
})
export class AIAssistantVisionComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  snapshot = signal<ISnapshot>(undefined);
  videoSize = signal<IArea>({ width: 0, height: 0 });
  result = signal<string>(undefined);
  state = signal<IVisionSnapshotState>(undefined);

  constructor(private visionService: VisionService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.visionService.imageChange$
        .pipe(filter((s) => !!s))
        .subscribe((s) => {
          this.snapshot.set(s);
        })
    );
    this.subscription.add(
      this.visionService.imageStateChange$
        .pipe(filter((s) => !!s))
        .subscribe((s) => {
          this.state.set(s);
          if (s.state === "analysisReady") {
            this.result.set(s.result);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  snapshotReady(s: ISnapshot) {}

  snapshotError(s: ISnapshot) {}
}
