import { Event, Util } from "@auvious/common";
import { INotificationEvent } from "../INotificationEvent";
import {
  NotificationTypeEnum,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../core-ui.enums";

export class TranscriptNotification implements INotificationEvent {
  private _type: NotificationTypeEnum;
  private _timestamp: Date;
  private _id: string;

  constructor(
    event: Event,
    private _state: TranscriptStateEnum,
    private _transformType?: TranscriptTransformType
  ) {
    if (!event.payload) {
      return null;
    }
    try {
      this._id = Util.uuidgen();
      this._type = NotificationTypeEnum.transcript;
      this._timestamp = new Date(event.payload.timestamp);
    } catch (ex) {
      return null;
    }
  }

  getType(): NotificationTypeEnum {
    return this._type;
  }
  getSentAt(): Date {
    throw this._timestamp;
  }
  getTitle(): string {
    return "Call transcript";
  }
  getTransformType(): TranscriptTransformType {
    return this._transformType;
  }
  getBody(): string {
    switch (this._state) {
      case TranscriptStateEnum.completed:
        return "Transcript has finished processing";
      case TranscriptStateEnum.failed:
        return "Transcript failed to process";
    }
  }

  getColor(): "danger" | "warning" | "info" | "success" | "container" {
    return "container";
  }

  canShow(): boolean {
    return true;
  }
  getId(): string | number {
    return this._id;
  }

  getTimeToLive(): number {
    return 0;
  }
}
