import { Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRouteSnapshot } from "@angular/router";

import { PureCloudService } from "../../services/purecloud.service";
import { IGenesysTokenResponse } from "./../IGenesysTokenResponse";
import {
  GenesysCloudInteractionStrategy,
  GenesysCloudAnalyticsStrategy,
  GenesysCloudQueueStrategy,
  GenesysCloudInvitationStrategy,
  GenesysCloudNotificationStrategy,
  GenesysCloudIntegrationStrategy,
  GenesysCloudConversationStrategy,
} from "../strategies";
import { PARAM_GENESYS_ENV } from "../../../app/app.enums";

import { AuviousRtcService } from "../../../core-ui/services/rtc.service";
import { UserService } from "../../../core-ui/services/user.service";
import { AuthState } from "../../../core-ui/models/AuthState";
import { ITokenResponse } from "../../../core-ui/models/ITokenResponse";
import {
  IApplication,
  IApplicationDTO,
  IApplicationLifecycleEventHandlers,
  IGenesysApplicationConfig,
} from "../../../core-ui/models/IApplication";
import {
  ApplicationTypeEnum,
  MediaFilterTypeEnum,
} from "../../../core-ui/core-ui.enums";
import {
  IAuthenticationStrategy,
  IInteractionStrategy,
  IAnalyticsStrategy,
  IIntegrationStrategy,
  IQueueStrategy,
  IInvitationStrategy,
  INotificationStrategy,
  IConversationStrategy,
  OAuth2CodeGrantAuthenticationStrategy,
} from "../../../core-ui/models/strategies";
import {
  AgentParam,
  AppConfigService,
  GenericErrorHandler,
  ProtectedTicketService,
} from "../../../core-ui";
import { of } from "rxjs";
import { OAUTH2_CODE_GRAND_REDIRECT_URI } from "../../../core-ui/core-ui.enums";
import { TranslateService } from "@ngx-translate/core";
import { createSentryLogger } from "../../app.utils";

const sentryLog = createSentryLogger("conference.service");
const CLAIM_GENESYS_ORGANIZATION_ID = "organizationId";
export class GenesysCloudApplication implements IApplication {
  private mAuthenticationStrategy: IAuthenticationStrategy;
  private mInteractionStrategy: IInteractionStrategy;
  private mAnalyticsStrategy: IAnalyticsStrategy;
  private mQueueStrategy: IQueueStrategy;
  private mTicketStrategy: IInvitationStrategy;
  private mNotificationStrategy: INotificationStrategy;
  private mIntegrationStrategy: IIntegrationStrategy;
  private mConversationStrategy: IConversationStrategy;

  private pureCloudService: PureCloudService;
  private appConfigService: AppConfigService;
  private userService: UserService;
  private translateService: TranslateService;

  constructor(private dto: IApplicationDTO, injector: Injector) {
    this.pureCloudService = injector.get(PureCloudService);
    this.appConfigService = injector.get(AppConfigService);
    this.userService = injector.get(UserService);
    this.translateService = injector.get(TranslateService);

    this.appConfigService.setApplicationConfig(this.getConfig());

    this.mIntegrationStrategy = new GenesysCloudIntegrationStrategy(
      this.pureCloudService,
      this.appConfigService,
      this.getId()
    );

    this.mAuthenticationStrategy = new OAuth2CodeGrantAuthenticationStrategy(
      injector.get(AuviousRtcService),
      injector.get(HttpClient),
      {
        clientId: this.appConfigService.clientIdAgent,
        redirectUri: window.location.origin + OAUTH2_CODE_GRAND_REDIRECT_URI,
        authProvider: async () => ({
          clientId: encodeURIComponent(
            this.mIntegrationStrategy.getOauthClientId(
              this.pureCloudService.pcEnvironment
            ) || this.mIntegrationStrategy.getOauthClientId()
          ),
          authorizationUrl: `https://login.${this.pureCloudService.pcEnvironment}/oauth/authorize`,
        }),
        beforeAuthenticate: async (
          route: ActivatedRouteSnapshot
        ): Promise<void> => {
          // pcEnvironment will be detected from pcEnvironment or state query param.
          let ready = false;

          if (route.queryParamMap.has(PARAM_GENESYS_ENV)) {
            ready = await this.pureCloudService.init(
              route.queryParamMap.get(PARAM_GENESYS_ENV)
            );
            if (!ready) {
              sentryLog(
                [
                  "application.on.beforeAuthenticate",
                  "genesys.environment.route",
                  route.queryParams,
                ],
                "warning"
              );
            }
          } else if (route.queryParamMap.has("state")) {
            const state = AuthState.fromSerializedState(
              route.queryParamMap.get("state")
            );
            ready = await this.pureCloudService.init(state.pcEnvironment);
            if (!ready) {
              sentryLog(
                [
                  "application.on.beforeAuthenticate",
                  "genesys.environment.auth-state",
                  state,
                ],
                "warning"
              );
            }
          } else {
            ready = await this.pureCloudService.init();
            if (!ready) {
              sentryLog([
                "application.on.beforeAuthenticate",
                "genesys.environment.not-found",
              ]),
                "warning";
            }
          }

          if (!ready) {
            throw new Error("cannot initialize purecloud services");
          }
        },
        afterAuthenticate: async (): Promise<void> => {
          await this.pureCloudService.bootstrapAgent();
          const userDetails = this.pureCloudService.user();
          if (userDetails.images?.length > 2) {
            userDetails.avatarUrl = userDetails.images?.[1]?.imageUri;
          }
          this.userService.setUserDetails(userDetails);
        },
        hydratePasswordParams: (params: URLSearchParams): void => {
          params.set(PARAM_GENESYS_ENV, this.pureCloudService.pcEnvironment);
        },
        onTokenResponse: (tokenResponse: ITokenResponse): void => {
          // set genesys token to purecloud client
          const genesysTokenResponse = tokenResponse as IGenesysTokenResponse;

          this.pureCloudService.setGenesysClientToken(
            genesysTokenResponse.genesys_token
          );
        },
        config: dto.config,
      }
    );

    this.mInteractionStrategy = new GenesysCloudInteractionStrategy(
      this.pureCloudService,
      injector.get(ProtectedTicketService),
      injector.get(GenericErrorHandler),
      this.translateService
    );
    this.mAnalyticsStrategy = new GenesysCloudAnalyticsStrategy(
      this.pureCloudService
    );
    this.mQueueStrategy = new GenesysCloudQueueStrategy(this.pureCloudService);
    this.mTicketStrategy = new GenesysCloudInvitationStrategy(
      this.getId(),
      this.getConfig()?.publicParameters,
      this.pureCloudService.pcEnvironment
    );
    this.mNotificationStrategy = new GenesysCloudNotificationStrategy(
      this.pureCloudService,
      this.translateService
    );
    this.mConversationStrategy = new GenesysCloudConversationStrategy(
      this.pureCloudService,
      this.appConfigService
    );
  }

  supportsInteractions(): boolean {
    return true;
  }

  supportsInteractionMetrics(): boolean {
    return true;
  }

  canCallWithoutActiveInteraction(): boolean {
    return !this.getConfig().agentParameters?.videoCallRequiresInteraction;
  }

  isReady(): boolean {
    return this.pureCloudService.isInitialised();
  }

  getId(): string {
    return this.dto.id;
  }

  getDto(): IApplicationDTO {
    return this.dto;
  }

  getType(): ApplicationTypeEnum {
    return ApplicationTypeEnum.GenesysCloud;
  }

  getOrganizationId(): string {
    return this.dto.organizationId;
  }

  getConfig(): IGenesysApplicationConfig {
    const conf = this.dto.config as IGenesysApplicationConfig;
    const usr = this.userService.getActiveUser();
    return !conf.organizationId && !!usr
      ? { ...conf, organizationId: usr.getClaim(CLAIM_GENESYS_ORGANIZATION_ID) }
      : conf;
  }

  getCreatedAt(): Date {
    return new Date(this.dto.createdAt);
  }

  getVersion(): number {
    return this.dto.version;
  }

  getFilterBackgrounds() {
    const url = "assets/media-filter-backgrounds/genesys";
    return [
      {
        id: "genesys-1",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/genesys-blue.jpg`),
      },
      {
        id: "genesys-2",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/genesys-gray.jpg`),
      },
      {
        id: "genesys-3",
        type: MediaFilterTypeEnum.backgroundImage,
        url: of(`${url}/genesys-light-blue.jpg`),
      },
    ];
  }

  registerLifecycleEventHandlers(handlers: IApplicationLifecycleEventHandlers) {
    this.pureCloudService.registerLifecycleEventHandlers(handlers);
  }

  authenticationStrategy(): IAuthenticationStrategy {
    return this.mAuthenticationStrategy;
  }

  interactionStrategy(): IInteractionStrategy {
    return this.mInteractionStrategy;
  }

  analyticsStrategy(): IAnalyticsStrategy {
    return this.mAnalyticsStrategy;
  }

  queueStrategy(): IQueueStrategy {
    return this.mQueueStrategy;
  }

  ticketStrategy(): IInvitationStrategy {
    return this.mTicketStrategy;
  }

  notificationStrategy(): INotificationStrategy {
    return this.mNotificationStrategy;
  }

  integrationStrategy(): IIntegrationStrategy {
    return this.mIntegrationStrategy;
  }

  conversationStrategy(): IConversationStrategy {
    return this.mConversationStrategy;
  }
}
