import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Signal,
  signal,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Layout } from "@auvious/layout";
import { IEndpoint, IPublishedStream, StreamTypes } from "@auvious/rtc";
import { TranslateService } from "@ngx-translate/core";
import { merge, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { IMediaDevicesEvents, MediaDevices } from "@auvious/media-tools";

import {
  AgentMutedAction,
  AppConfigService,
  ApplicationService,
  ArPointerActivatedEvent,
  ArPointerDeactivatedEvent,
  AssistantService,
  CallEndedAction,
  CallHoldChangedAction,
  CallStartedAction,
  CobrowseViewRequestAction,
  ConferenceService,
  ConferenceStore,
  debug,
  debugError,
  DeviceService,
  FEATURES,
  FeedbackRequestedAction,
  FileTransferService,
  GenericErrorHandler,
  LocalMediaService,
  MediaEffectsService,
  MediaRulesService,
  PointerService,
  RatingService,
  SnapshotService,
  StreamState,
  UserService,
  windowActionType,
  WindowEventService,
} from "../../services";
import { CobrowseService } from "../../services/cobrowse.service";
import { NotificationService } from "../../services/notification.service";
import {
  fadeInOut,
  slideFromRight,
  slideIn,
  slideInOut,
  zoomInOut,
} from "../../core-ui.animations";
import {
  CobrowseContextEnum,
  ConferenceMetadataKeyEnum,
  ConversationDestinationEnum,
  ConversationOriginEnum,
  ConversationTypeEnum,
  EndpointTypeEnum,
  GeolocationError,
  InteractionMetricEnum,
  KEY_COBROWSE_CONTEXT,
  KEY_COBROWSE_SESSION_ID,
  LayoutEnum,
  StreamTrackKindEnum,
  TerminateReasonEnum,
  TileTypeEnum,
  UserRoleEnum,
} from "../../core-ui.enums";
import {
  AgentParam,
  CoBrowseMetadata,
  CustomerParam,
  DEFAULT_CALL_HOLD_STATE,
  GeolocationMetadata,
  IApplication,
  ICallHoldState,
  IEndpointMetadata,
  IEndpointMetadataChangedEvent,
  IInteraction,
  INotificationEvent,
  IntegrationMetadata,
  IRecorderInfo,
  IRecorderOptions,
  IStreamMetadata,
  ITile,
  PublicParam,
  SpeechToTextMetadata,
  ToastNotification,
} from "../../models";
import { ConfirmNotification } from "../../models/notifications/ConfirmNotification";
import { ActivityIndicatorService } from "../../services/activity-indicator.service";
import { TranscriptService } from "../../services/transcript.service";
import { copyTextToClipboard, delay, withTimeout } from "../../services/utils";
import { TileComponent } from "../tile/tile.component";
import { AnalyticsService } from "../../services/analytics.service";
import { CobrowseRequestType, OriginModeEnum } from "@auvious/integrations";
import { sessionStore } from "@auvious/utils";

@Component({
  selector: "app-room-ui",
  templateUrl: "./room.component.html",
  styleUrls: ["./room.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    slideIn,
    slideInOut,
    fadeInOut,
    zoomInOut,
    trigger("toast", [
      transition(":enter", [
        style({ opacity: 0, transform: "translate(-50%, 50px)" }),
        animate(100),
      ]),
      transition(":leave", [
        animate(100, style({ opacity: 0, transform: "translate(-50%, 50px)" })),
      ]),
      state("*", style({ opacity: 1, transform: "translate(-50%, 0)" })),
    ]),
  ],
})
export class RoomComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() recorderOptions: IRecorderOptions;
  @Input() interaction: IInteraction;

  @Output() callStarted = new EventEmitter<any>();
  @Output() callPendingRating = new EventEmitter<boolean>();
  @Output() callEnded = new EventEmitter<{
    reason: TerminateReasonEnum;
    error?: Error;
  }>();
  @Output() exit = new EventEmitter();
  @Output() callEnding = new EventEmitter<{
    terminated: boolean;
    terminatedRemotely: boolean;
  }>();
  @Output() deviceSetupComplete = new EventEmitter();
  @Output() layoutChange = new EventEmitter<LayoutEnum>();
  @Output() recorderStarted = new EventEmitter<IRecorderInfo>();
  @Output() recorderStopped = new EventEmitter<IRecorderInfo>();
  @Output() remotePopupClosed = new EventEmitter();

  @ViewChild("container") tileContainerRef: ElementRef<HTMLDivElement>;
  @ViewChildren("tiles") tilesRef: QueryList<TileComponent>;
  @ViewChild("testSound", { static: true }) audioTest: ElementRef;
  @ViewChild("notificationIn", { static: true })
  notificationSoundJoin: ElementRef;
  @ViewChild("notificationOut", { static: true })
  notificationSoundLeave: ElementRef;
  @ViewChild("mediaErrorCardRef", { static: false, read: ElementRef })
  mediaErrorCardRef: ElementRef<HTMLDivElement>;

  callHoldState: ICallHoldState;
  participantNotificationMap: Map<string, INotificationEvent> = new Map();

  notAllowedError = signal(false);
  streamError = signal(false);
  notFoundError = signal(false);
  isWebViewError = signal(false);
  isMediaRequestTimeoutError = signal(false);
  isRequestingMedia = signal(false);
  agentDisplayName = signal(undefined);
  // callHUDElementColorMode = signal(undefined);

  isAgent = false;
  isSupervisor = false;
  isCustomer = false;
  isGuestAgent = false;
  isDeviceSetup = false;
  isRating = false;
  isCobrowseActive = false;
  isWhisper = false;
  subscriptions: Subscription;
  application: IApplication;
  currentLayout: LayoutEnum = LayoutEnum.grid;
  layout: Layout;
  localTile: ITile;
  spotlightTile: ITile;
  isDeviceSetupOpen = false;
  isCallEnded = false;
  isLeavingByChoice = false;
  isSnapshotActive = false;
  isSnapshotStarted = false;
  isReviewingSnapshots = false;
  isCaptionsOn = false;
  conferenceId: string;
  skipRating = false;
  localAudioVideoStream: IPublishedStream;
  endpoints: IEndpoint[] = [];
  spotlightTileMaximise: boolean;
  interactionID: string;
  isInteractionDataVisible: boolean;
  agentLeftTimeout;
  undeliveredSecondaryStreamError;

  // map from store
  public streams: StreamState[];
  public snapshotStream: StreamState = null;
  public mystream: StreamState = null;

  private screenStream: StreamState;
  private cobrowseOriginator: IEndpoint; // the agent who requested to co-browse

  url: string;

  constructor(
    private zone: NgZone,
    private errorHandler: GenericErrorHandler,
    private deviceService: DeviceService,
    private activityService: ActivityIndicatorService,
    private conferenceService: ConferenceService,
    private translate: TranslateService,
    private notification: NotificationService,
    private cdr: ChangeDetectorRef,
    private config: AppConfigService,
    private windowEventService: WindowEventService,
    private ratingService: RatingService,
    private applicationService: ApplicationService,
    private userService: UserService,
    private cobrowseService: CobrowseService,
    private pointerService: PointerService,
    private mediaEffectsService: MediaEffectsService,
    private snapshotService: SnapshotService,
    private transferService: FileTransferService,
    private mediaRules: MediaRulesService,
    private local: LocalMediaService,
    private asr: TranscriptService,
    public store: ConferenceStore,
    private analytics: AnalyticsService,
    private assistant: AssistantService
  ) {
    this.application = this.applicationService.getActiveApplication();

    this.callHoldState = { ...DEFAULT_CALL_HOLD_STATE };

    this.subscriptions = new Subscription();

    this.url = window.location.href;

    this.zone.runOutsideAngular(() => {
      window.addEventListener("resize", this.onResize.bind(this));
    });
    window.addEventListener("focus", this.onWindowFocus.bind(this));
  }

  async ngAfterViewInit() {
    this.spotlightTileMaximise = this.config.publicParam(
      PublicParam.LAYOUT_SPOTLIGHT_HIDE_OTHER_PARTICIPANTS
    );

    if (!this.tileContainerRef?.nativeElement) {
      this.cdr.detectChanges();
    }

    if (!this.tileContainerRef?.nativeElement) {
      await new Promise(requestAnimationFrame);
    }

    this.layout = new Layout({
      margin: this.isScreenShareFullScreen ? 5 : 10,
      height: this.tileContainerRef.nativeElement.clientHeight,
      width: this.tileContainerRef.nativeElement.clientWidth,
      focus: { maximize: this.spotlightTileMaximise },
    });

    MediaDevices.syncSpeaker(this.audioTest.nativeElement);
    MediaDevices.syncSpeaker(this.notificationSoundJoin.nativeElement);
    MediaDevices.syncSpeaker(this.notificationSoundLeave.nativeElement);
  }

  async ngOnInit() {
    this.mediaEffectsService.init(this.interaction);
    this.subscriptions.add(
      this.store.updated$.subscribe(() => {
        if (
          this.streams !== this.store.streams ||
          this.screenStream !== this.store.screenStream ||
          this.snapshotStream !== this.store.snapshotStream
        ) {
          this.streams = this.store.streams;
          this.screenStream = this.store.screenStream;
          this.snapshotStream = this.store.snapshotStream;

          if (this.snapshotStream) {
            this.isSnapshotActive = true;
          }

          if (this.store.mystream) {
            this.mystream = this.store.mystream;
            this.resetStreamErrors();
            if (
              this.conferenceService.getParticipants()[
                this.conferenceService.myself.endpoint
              ]?.metadata?.mediaDevicesError
            ) {
              this.conferenceService.updateEndpointMetadata({
                mediaDevicesError: false,
                mediaDevices: MediaDevices.getDeviceList(),
              });
            }
            this.isDeviceSetupOpen = !this.deviceService.isDevicesSetup;
          }

          this.cdr.detectChanges();
        }
      })
    );

    this.isAgent = this.userService.isAgent;
    this.isSupervisor = this.userService.isSupervisor;
    this.isGuestAgent = this.userService.isGuestAgent;
    this.isCustomer = this.userService.isCustomer;
    this.conferenceId = this.interaction.getRoom();
    this.isLeavingByChoice = false;
    this.interactionID = this.interaction.getId();
    this.isWhisper = !!this.interaction.getWhisperMode();
    this.isInteractionDataVisible =
      this.config.publicParam(PublicParam.INTERACTION_DATA_VISIBLE) ??
      this.config.customerParamEnabled(CustomerParam.INTERACTION_DATA_VISIBLE);

    this.local.settingUp.then(async () => {
      const permissionState: {
        audio: PermissionStatus;
        video: PermissionStatus;
      } = {
        audio: undefined,
        video: undefined,
      };
      if (navigator.permissions) {
        try {
          // https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
          const p = await Promise.all([
            //@ts-expect-error
            navigator.permissions.query({ name: "microphone" }),
            // @ts-expect-error
            navigator.permissions.query({ name: "camera" }),
          ]);
          permissionState.audio = p[0];
          permissionState.video = p[1];
        } catch (ex) {
          debugError("Permission query failed", ex);
        }
      }

      const requestAudioPermission =
        !this.isWhisper &&
        // audio on by default
        this.mediaRules.joinWithAudioOn &&
        // audio not disabled
        this.mediaRules.isAudioAvailable &&
        // permissions not granted
        !MediaDevices.permissions.audioinput &&
        // we have not accepted or denied
        (!permissionState.audio || permissionState.audio.state === "prompt");

      const requestVideoPermisson =
        !this.isWhisper &&
        // cam on by default
        this.mediaRules.joinWithVideoOn &&
        // cam not disabled
        this.mediaRules.isVideoAvailable &&
        // permissions not granted
        !MediaDevices.permissions.videoinput &&
        // we have not accepted or denied
        (!permissionState.video || permissionState.video.state === "prompt");

      this.isRequestingMedia.set(
        requestAudioPermission || requestVideoPermisson
      );

      this.cdr.detectChanges();

      this.activityService.loading(false);
    });

    this.initRoom();

    if (this.isAgent) {
      this.snapshotService.load(this.interaction);

      this.subscriptions.add(
        this.conferenceService.endpointMetadataChanged$
          .pipe(filter((e) => !!e))
          .subscribe((m) => {
            this.participantMediaErrorChange(
              m,
              (m.newMetadata as IEndpointMetadata).mediaDevicesError
            );
          })
      );
    }
    if (this.isCustomer) {
      this.subscriptions.add(
        this.conferenceService.conferenceMetadataSet$
          .pipe(filter((m) => m instanceof IntegrationMetadata))
          .subscribe((m: IntegrationMetadata) => {
            // update interaction id with the integration one
            this.interactionID = m.interactionId;
          })
      );
    }

    this.subscriptions.add(
      this.conferenceService.terminateConference$.subscribe(
        (reason: TerminateReasonEnum) => {
          this.leaveConference(reason);
        }
      )
    );
    this.subscriptions.add(
      this.conferenceService.confirmTerminateConference$.subscribe((_) => {
        const cnotif = new ConfirmNotification(
          "Conversation ended",
          "Would you like to end the call?"
        );
        cnotif.onConfirmed(() => {
          if (!this.isReviewingSnapshots) {
            this.leaveConference(TerminateReasonEnum.conversationEnded);
          }
        });
        cnotif.onCanceled(() => {
          // nothing
        });
        this.notification.notify(cnotif);
      })
    );

    this.subscriptions.add(
      this.conferenceService.localStreamPublished$
        .pipe(filter((s) => !!s && s.type !== StreamTypes.SCREEN))
        .subscribe((stream) => {
          this.localAudioVideoStream = stream;
          // swisscom
          if (
            this.isCustomer &&
            this.config.customerParamEnabled(
              CustomerParam.AUTO_START_SECONDARY_CAMERA
            ) &&
            this.config.customerParamEnabled(CustomerParam.CAMERA_ENABLED) &&
            this.config.customerParamEnabled(CustomerParam.CAMERA_DEFAULT_ON) &&
            (stream.getMetadata() as IStreamMetadata).primary &&
            !this.store.mySecondaryStream
          ) {
            this.tryOpenSecondaryCamera();
          }
          this.cdr.detectChanges();
        })
    );

    this.subscriptions.add(
      this.conferenceService.endpointLeft$.subscribe((participant) => {
        this.participantMediaErrorChange(
          {
            newMetadata: participant.metadata,
            userId: participant.username,
            userEndpointId: participant.endpoint,
          },
          false
        );
        this.endpoints = this.endpoints.filter(
          (e) => e.endpoint !== participant.endpoint
        );

        // notify guest agents / supervisors that co-browse ended
        if (
          this.isAgent &&
          (participant?.metadata as IEndpointMetadata)?.type ===
            EndpointTypeEnum.coBrowse
        ) {
          const cobrowseOriginator = (participant.metadata as IEndpointMetadata)
            ?.cobrowseOriginator;

          if (
            (!!cobrowseOriginator &&
              cobrowseOriginator.username !==
                this.conferenceService.myself.username) ||
            // this is the old check, in case a previous version of widget is used
            (!cobrowseOriginator && this.isGuestAgent)
          ) {
            this.notification.info("Co-browse", {
              body: "The co-browse session has ended.",
            });
          }
        }
        this.cdr.detectChanges();
      })
    );
    this.subscriptions.add(
      this.conferenceService.endpointJoined$.subscribe((participant) => {
        this.endpoints = [...this.endpoints, participant];

        this.setAgentName(participant);

        this.clearAgentLeftTimeout(participant);

        // do not show message if customer
        if (!this.isCustomer && participant.metadata?.mediaDevicesError) {
          this.participantMediaErrorChange(
            {
              newMetadata: participant.metadata,
              userEndpointId: participant.endpoint,
              userId: participant.username,
            },
            true
          );
        }
        if (
          this.isAgent &&
          (participant?.metadata as IEndpointMetadata)?.type ===
            EndpointTypeEnum.coBrowse
        ) {
          this.cobrowseOriginator = (
            participant.metadata as IEndpointMetadata
          )?.cobrowseOriginator;
          // This check is to proof it for mutliple agents. only the one who requested the session
          // should continue to request permissions
          if (
            (!!this.cobrowseOriginator &&
              this.cobrowseOriginator.username !==
                this.conferenceService.myself.username) ||
            // this is the old check, in case a previous version of widget is used
            (!this.cobrowseOriginator && this.isGuestAgent)
          ) {
            this.notification.info("Co-browse", {
              body: "The agent has requested to co-browse with the customer.",
            });
          } else {
            if (this.cobrowseService.isRemoteParticipantConnected) {
              this.cobrowseService.participantRejoined(participant);
            }
            // we have a new cobrowse request, ask to view
            this.cobrowseService.setRemoteCobrowseParticipant(participant);

            const existingSession = sessionStore.getItem(
              KEY_COBROWSE_SESSION_ID
            );
            const existingSessionContext =
              sessionStore.getItem(KEY_COBROWSE_CONTEXT);
            if (
              existingSession &&
              existingSessionContext === CobrowseContextEnum.CONFERENCE
            ) {
              this.isCobrowseActive = true;
            } else {
              this.analytics.trackCobrowseRequested(
                this.interaction,
                participant
              );
            }
          }
        }
        this.cdr.markForCheck();
      })
    );

    this.subscriptions.add(
      this.cobrowseService.ended$
        // I am the one who started the co-browse, I should clear it
        .pipe(
          filter(
            (e) =>
              this.cobrowseOriginator?.endpoint ===
              this.conferenceService.myself.endpoint
          )
        )
        .subscribe((_) => {
          this.conferenceService.removeConferenceMetadata(
            ConferenceMetadataKeyEnum.coBrowse
          );
          this.cobrowseOriginator = null;
          this.cobrowseService.setStreamTarget(null);
        })
    );

    this.subscriptions.add(
      this.cobrowseService.participantInvited$
        .pipe(filter((p) => !!p))
        .subscribe((p) => {
          this.isCobrowseActive = true;
          this.cdr.markForCheck();
        })
    );

    // this.subscriptions.add(
    //   this.conferenceService.screenShareStream$.subscribe((stream) => {
    //     this.screenStream = stream;
    //     if (!this.isScreenShareLocal && this.isChildWindow) {
    //       // in case of remote screen share, disable local on widget and show card on audio call
    //       this.windowEventService.sendMessage(
    //         new ScreenShareAvailabilityChangedAction(!!this.screenStream)
    //       );
    //     }
    //     this.cdr.markForCheck();
    //   })
    // );

    this.subscriptions.add(
      this.conferenceService.callHoldStateChange$.subscribe((st) => {
        this.callHoldState = st;
        if (this.isChildWindow) {
          this.windowEventService.sendMessage(
            new CallHoldChangedAction(this.callHoldState.isOnHold)
          );
        }
        this.cdr.detectChanges();
        if (!this.isOnHold) {
          this.onResize(false);
        }
      })
    );

    this.subscriptions.add(
      this.conferenceService.participantLeft$.subscribe((participant) => {
        try {
          if (this.isCustomer) this.informCustomerAgentLeft(participant);

          if (participant?.metadata?.origin === ConversationOriginEnum.POPUP) {
            // we assume that since an endpoint of type popup left, then the popup was closed
            this.remotePopupClosed.emit();
          }
        } catch (ex) {
          debugError(ex);
        }
      })
    );

    this.subscriptions.add(
      this.pointerService.pointAreaChange$.subscribe(async (data) => {
        debug("point-area:point area change:", data);
        switch (data.type) {
          case ArPointerActivatedEvent.type:
            this.spotlightTile = this.tilesRef.find(
              (t) =>
                t.endpoint === data.targetUserEndpointId &&
                t.stream?.type === data.targetStreamType
            );
            this.decideLayout();
            break;
          case ArPointerDeactivatedEvent.type:
            if (!this.spotlightTile || data.targetStreamType === "screen") {
              return;
            }
            this.layout.tile(
              this.spotlightTile.container,
              this.spotlightTile.ratio
            );
            this.spotlightTile = null;
            this.decideLayout();
            break;
        }
        this.cdr.markForCheck();
      })
    );

    this.subscriptions.add(
      this.snapshotService.started$.subscribe((data) => {
        this.isSnapshotStarted = true;
        this.spotlightTile = this.tilesRef.find(
          (t) => t.endpoint === data.targetUserEndpointId
        );
        this.captureGeolocation();
        this.decideLayout();
      })
    );

    this.subscriptions.add(
      this.snapshotService.ended$.subscribe((e) => {
        this.deviceService.clearGeolocationTimer();
        if (this.isReviewingSnapshots) {
          return;
        }
        this.isSnapshotActive = false;
        this.isSnapshotStarted = false;
        if (this.spotlightTile) {
          this.layout.tile(
            this.spotlightTile.container,
            this.spotlightTile.ratio
          );
          this.spotlightTile = null;
          this.decideLayout();
        }
      })
    );

    this.subscriptions.add(
      this.snapshotService.reviewing$.subscribe((e) => {
        this.isReviewingSnapshots = true;
        this.isSnapshotActive = true;
        this.cdr.markForCheck();
      })
    );

    this.subscriptions.add(
      merge(
        this.conferenceService.conferenceMetadataSet$.pipe(
          filter((m) => m instanceof SpeechToTextMetadata)
        ),
        this.asr.enabled$
      ).subscribe((m: SpeechToTextMetadata) => {
        this.isCaptionsOn = true;
        this.cdr.detectChanges();
        this.onResize(true);
      })
    );
    this.subscriptions.add(
      merge(
        this.conferenceService.conferenceMetadataRemoved$.pipe(
          filter((m) => m instanceof SpeechToTextMetadata)
        ),
        this.asr.disabled$
      ).subscribe((m) => {
        this.isCaptionsOn = false;
        this.cdr.detectChanges();
        this.onResize(true);
      })
    );

    this.subscriptions.add(
      this.conferenceService.conferenceMetadataSet$
        .pipe(filter((m) => m instanceof CoBrowseMetadata))
        .subscribe((m: CoBrowseMetadata) => {
          this.cobrowseService.setStreamTarget(m.target);
        })
    );

    this.subscriptions.add(
      this.conferenceService.conferenceMetadataRemoved$
        .pipe(filter((m) => m instanceof CoBrowseMetadata))
        .subscribe((m: CoBrowseMetadata) => {
          this.cobrowseService.setStreamTarget(null);
        })
    );

    this.subscriptions.add(
      this.local.permissions$
        .pipe(
          filter(
            (_) =>
              this.conferenceService.sessionExists &&
              MediaDevices.getDeviceList().length > 0 &&
              this.conferenceService.getMyParticipantMetadata()?.mediaDevices
                .length !== MediaDevices.getDeviceList().length
          )
        )
        .subscribe((p) => {
          this.conferenceService.updateEndpointMetadata({
            mediaDevices: MediaDevices.getDeviceList(),
          });
        })
    );

    if (this.isAgent) {
      this.subscriptions.add(
        this.conferenceService.conferenceMetadataSet$
          .pipe(filter((m) => m instanceof GeolocationMetadata))
          .subscribe((m: GeolocationMetadata) => {
            if (!m.error) {
              this.analytics.updateInteractionMetrics(this.interaction, {
                [InteractionMetricEnum.snapshotGeolocation]: m.coordinates,
              });
              this.notification.success("Customer location shared", {
                body: "The location has been saved",
              });
            } else {
              let body;
              switch (m.error) {
                case GeolocationError.PERMISSION_DENIED:
                  body = "Customer denied to share the location";
                  break;
                case GeolocationError.POSITION_UNAVAILABLE:
                  body =
                    "The location is not available in the customer's device";
                  break;
                case GeolocationError.TIMEOUT:
                  body = "The device timed out trying to capture the location";
                  break;
              }
              this.notification.info("Customer location unavailable", { body });
            }
          })
      );
    }

    if (this.isChildWindow) {
      this.subscriptions.add(
        this.windowEventService
          .init(this.interaction.getParentFrameUrl())
          .subscribe((action) => {
            if (!action) {
              return;
            }
            switch (action.type) {
              case windowActionType.LEAVE_CALL:
                // the payload carries if it's a transfer or not.
                // we should skip rating if it's a transfer.
                this.skipRating =
                  action.payload === TerminateReasonEnum.transfer;
                this.leaveConference(action.payload);
                break;
            }
          })
      );
      if (this.isAudioCall) {
        this.subscriptions.add(
          this.conferenceService.streamMutedChange$.subscribe((data) => {
            if (
              data.stream.originator.endpoint !== this.endpoint &&
              this.isCustomer &&
              data.trackKind === StreamTrackKindEnum.audio
            ) {
              this.windowEventService.sendMessage(
                new AgentMutedAction(data.muted)
              );
            }
          })
        );
        this.subscriptions.add(
          this.conferenceService.streamAdded$
            .pipe(
              filter(
                (s) =>
                  s.originator.endpoint !== this.endpoint &&
                  this.isCustomer &&
                  s.type !== "screen"
              )
            )
            .subscribe((stream) => {
              this.windowEventService.sendMessage(
                new AgentMutedAction(stream.isMuted("audio"))
              );
            })
        );
      }
    }
  }

  ngOnDestroy() {
    debug("destroying conference page");
    // leaving room by clicking the back button or some other type of browser navigation
    if (!this.isLeavingByChoice) {
      this.leaveConference();
    }
    if (this.agentLeftTimeout) {
      clearTimeout(this.agentLeftTimeout);
    }
    this.deviceService.isDevicesSetup = false;
    this.subscriptions.unsubscribe();
    this.windowEventService.destroy();
    this.mediaEffectsService.disable();
    this.snapshotService.destroy();
    this.pointerService.cleanup();
    this.conferenceService.destroy();
    this.local.reset();

    MediaDevices.desyncSpeaker(this.audioTest.nativeElement);
    MediaDevices.desyncSpeaker(this.notificationSoundJoin.nativeElement);
    MediaDevices.desyncSpeaker(this.notificationSoundLeave.nativeElement);

    this.zone.runOutsideAngular(() => {
      window.removeEventListener("resize", this.onResize.bind(this));
      window.removeEventListener("focus", this.onWindowFocus.bind(this));
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunload($event) {
    if (
      this.interaction.getDestination() === ConversationDestinationEnum.PREMISE
    ) {
      return;
    }
    return ($event.returnValue = true);
  }

  @HostListener("window:unload", ["$event"])
  unload($event) {
    if (!this.isWidget) {
      // if (this.isAgent && this.canTerminate) {
      //   this.terminateConference();
      // } else {
      this.leaveConference(TerminateReasonEnum.refresh);
      // }
    }
  }

  private resize(animateMe: boolean) {
    if (this.layout && this.tileContainerRef?.nativeElement) {
      this.layout.update({
        width: this.tileContainerRef.nativeElement.clientWidth,
        height: this.tileContainerRef.nativeElement.clientHeight,
      });

      this.layout.render(animateMe === true);
    }
  }

  onResize(animateMe = false) {
    if (this.isMobile) {
      setTimeout(() => this.resize(animateMe), 300);
    } else {
      this.resize(animateMe);
    }
  }

  onWindowFocus() {
    setTimeout(() => {
      this.conferenceService.playPausedStreams();
    }, 200);
  }

  async tileReady(tile: ITile) {
    if (!this.tileContainerRef?.nativeElement || !this.layout) {
      this.cdr.detectChanges();
    }

    if (!this.tileContainerRef?.nativeElement || !this.layout) {
      await new Promise(requestAnimationFrame);
    }

    switch (tile.type) {
      case TileTypeEnum.video:
        if (!this.isScreenShareFullScreen) {
          debug("layout:tile", tile?.uuid);
          if ((tile as TileComponent).isLocal) {
            debug("layout:tile-ready-local", tile.uuid);
            this.localTile = tile;
          }
          this.layout?.tile(tile.container, tile.ratio || 1);
        }
        break;
      case TileTypeEnum.screenShare:
        if (this.isScreenShareFullScreen) {
          debug("layout:tile", tile.uuid);
          this.layout?.tile(tile.container, tile.ratio);
        } else {
          debug("layout:tile-ready-spotlight", tile.uuid);
          this.spotlightTile = tile;
        }
        break;
      case TileTypeEnum.coBrowse:
      case TileTypeEnum.arPointer:
      case TileTypeEnum.snapshot:
        debug("layout:tile-ready-spotlight", tile.uuid);
        if (this.isReviewingSnapshots) {
          this.localTile = tile;
          this.layout?.tile(tile.container, tile.ratio);
        } else {
          this.spotlightTile = tile;
        }
        break;
    }
    this.decideLayout();
    this.conferenceService.checkQueueForPendingMetadata();
  }

  async tileDestroyed(tile: ITile) {
    // this.zone.runOutsideAngular(async _ => {
    debug("layout:untile", tile?.uuid);
    this.layout?.untile(tile.container);
    switch (tile.type) {
      case TileTypeEnum.video:
        if ((tile as TileComponent).isLocal) {
          this.localTile = null;
        }
        break;
      case TileTypeEnum.screenShare:
      case TileTypeEnum.arPointer:
        this.spotlightTile = null;
        break;
      case TileTypeEnum.coBrowse:
        this.spotlightTile = null;
        this.isCobrowseActive = false;
        break;
    }
    this.decideLayout();
    // });
  }

  private trySpotlight(): boolean {
    // in case we are in spotlight and everybody leaves, center the tile
    if (
      this.spotlightTile?.type === TileTypeEnum.snapshot &&
      this.tilesRef.length === 0 // used to be 1 (why?)
    ) {
      this.localTile = this.spotlightTile;
      this.layout.tile(this.localTile.container, this.localTile.ratio);
      this.spotlightTile = null;
      return false;
    }

    // if there is no need to go into spotlight, leave
    if (!this.spotlightTile || this.isScreenShareFullScreen) {
      return false;
    }

    switch (this.currentLayout) {
      case LayoutEnum.floating:
        debug("layout:unfloat");
        this.layout.unfloat();
        // i am the receiver of spotlight, unfloat me
        if (this.spotlightTile?.uuid === this.localTile?.uuid) {
        } else {
          // someone else is the receiver, add me back to grid
          debug("layout:tile", this.localTile.uuid);
          this.layout.tile(this.localTile.container, this.localTile.ratio);
          this.localTile.setLayoutType(LayoutEnum.grid);
          // if ([TileTypeEnum.arPointer, TileTypeEnum.video].includes(this.spotlightTile.type)) {
          //     debug('layout:untile', this.spotlightTile.uuid);
          //     this.layout.untile(this.spotlightTile.container);
          // }
        }
        break;
      case LayoutEnum.spotlight:
        // we are already in layout
        // maybe we need to resize, so re-set
        this.layout.focus(
          this.spotlightTile.container,
          this.spotlightTile.ratio
        );
        this.spotlightTile.isSpotlight = true;
        this.spotlightTile.setLayoutType(LayoutEnum.spotlight);
        return true;
      case LayoutEnum.grid:
        // if ([TileTypeEnum.arPointer, TileTypeEnum.video].includes(this.spotlightTile.type)) {
        //     debug('layout:untile', this.spotlightTile.uuid);
        //     this.layout.untile(this.spotlightTile.container);
        // }
        break;
    }
    debug("layout:focus", this.spotlightTile.uuid);
    this.layout.focus(this.spotlightTile.container, this.spotlightTile.ratio);
    this.currentLayout = LayoutEnum.spotlight;
    this.spotlightTile.isSpotlight = true;
    this.spotlightTile.setLayoutType(LayoutEnum.spotlight);
    return true;
  }

  private tryFloat(): boolean {
    // if there is no need to go into float, leave
    // (this.streamsArray.length > 3 || this.streamsArray.length == 1)
    if (this.streams.length !== 2 || !this.localTile || !!this.spotlightTile) {
      return false;
    }
    try {
      switch (this.currentLayout) {
        // case LayoutEnum.floating:
        // we are already in layout
        // return true;
        case LayoutEnum.spotlight:
          // go from spotlight to floating
          // if ([TileTypeEnum.screenShare, TileTypeEnum.coBrowse].includes(this.spotlightTile.type)) {
          try {
            debug("layout:unfocus");
            this.layout.unfocus();
          } catch (ex) {
            debugError(ex);
          }
          // }
          break;
      }
      // debug('layout:untile', this.localTile.uuid);
      // this.layout.untile(this.localTile.container);
      debug("layout:float", this.localTile.uuid);
      const sHeight = this.tileContainerRef.nativeElement.clientHeight / 5;

      this.layout.float(this.localTile.container, {
        ratio: this.localTile.ratio,
        height:
          sHeight < 130
            ? this.localTile.isOrientationPortrait
              ? 180
              : 130
            : sHeight,
        top: this.isTopBarVisible ? -30 : 10,
        right: 10,
      });
      this.currentLayout = LayoutEnum.floating;
      this.localTile.setLayoutType(LayoutEnum.floating);
      return true;
    } catch (ex) {
      debugError(ex);
      return false;
    }
  }

  private tryGrid(): boolean {
    switch (this.currentLayout) {
      case LayoutEnum.floating:
        debug("layout:unfloat");
        this.layout.unfloat();
        if (this.localTile) {
          this.layout.tile(this.localTile.container, this.localTile.ratio);
          this.localTile.setLayoutType(LayoutEnum.grid);
        }
        break;
      case LayoutEnum.grid:
        return true;
      case LayoutEnum.spotlight:
        try {
          debug("layout:unfocus");
          this.layout.unfocus();
        } catch (ex) {
          debugError(ex);
        }
        break;
    }
    this.currentLayout = LayoutEnum.grid;
    return true;
  }

  // private syncCallHUD() {
  //   setTimeout(() => {
  //   if (this.callHUDRef && this.isWhisper) {
  //     const style = getComputedStyle(this.callHUDRef.nativeElement);
  //     const bkgColor = new TinyColor(style.backgroundColor);
  //     // if we have a light callHUD, set the elements icon color to dark;
  //     this.callHUDElementColorMode.set(bkgColor.isLight() ? "dark" : "light");
  //   } else {
  //     this.callHUDElementColorMode.set("light");
  //   }
  //   }, 100);
  // }

  private async decideLayout() {
    try {
      this.tilesRef.forEach((t) => (t.isSpotlight = false));

      this.tryFloat() || this.trySpotlight() || this.tryGrid();

      this.store.layoutChanged(this.currentLayout);

      if (!this.tileContainerRef?.nativeElement) {
        this.cdr.detectChanges();
      }

      this.layout?.update({
        width: this.tileContainerRef.nativeElement.clientWidth,
        height: this.tileContainerRef.nativeElement.clientHeight,
      });

      await this.layout?.render(true);

      debug("layout:----------------------");
      this.layoutChange.emit(this.currentLayout);
    } catch (ex) {
      debugError(ex);
    } finally {
      this.cdr.markForCheck();
    }
  }

  private setAgentName(endpoint: IEndpoint) {
    if (
      !endpoint?.metadata?.roles?.includes(UserRoleEnum.agent) ||
      // do not set the name if a monitoring supervisor joins
      ((endpoint?.metadata as IEndpointMetadata)?.whisperMode &&
        endpoint?.metadata?.roles.includes(UserRoleEnum.supervisor))
    ) {
      return;
    }
    if (
      !!(
        this.config.publicParam(PublicParam.AGENT_NAME_MASK) as string
      )?.trim() &&
      this.interaction.getType() !== ConversationTypeEnum.voiceCall
    ) {
      this.agentDisplayName.set(
        this.config.publicParam(PublicParam.AGENT_NAME_MASK).trim()
      );
    } else {
      this.agentDisplayName.set(endpoint?.metadata?.name?.trim());
    }
  }

  private informCustomerAgentLeft(participant: IEndpoint<IEndpointMetadata>) {
    // check if we should inform customer that an agent left
    const getActiveAgents = () => {
      const participants = this.conferenceService.getParticipants();
      return Object.keys(participants)
        .filter(
          (key) =>
            participants[key].metadata?.roles.includes(UserRoleEnum.agent) &&
            // in case the stream was removed but we have not received 'endpointLeft' yet
            key !== participant.endpoint
        )
        .map((key) => participants[key]);
    };

    const sec = Number(
      this.config.customerParam(CustomerParam.AGENT_LEFT_TIMEOUT)
    );
    if (
      participant.metadata?.roles.includes(UserRoleEnum.agent) &&
      sec &&
      getActiveAgents().length === 0 &&
      !this.isOnHold // during transfer we are on hold
    ) {
      this.agentLeftTimeout = setTimeout(() => {
        // check again if we have agents in the call
        if (getActiveAgents().length === 0) {
          this.notification.info("Agent appears to be unavailable", {
            body: "Please try contacting us again.",
            ttl: -1,
          });
        }
      }, sec * 1000);
    }
  }

  private clearAgentLeftTimeout(participant: IEndpoint<IEndpointMetadata>) {
    if (
      this.agentLeftTimeout &&
      this.isCustomer &&
      participant.metadata.roles.includes(UserRoleEnum.agent)
    ) {
      clearTimeout(this.agentLeftTimeout);
      this.agentLeftTimeout = undefined;
    }
  }

  private participantMediaErrorChange(
    event: IEndpointMetadataChangedEvent,
    mediaDevicesError: boolean
  ) {
    if (mediaDevicesError) {
      // do not show a second notification for the same endpoint
      if (!this.participantNotificationMap.has(event.userEndpointId)) {
        // prepare a notification for the specific endpoint
        const notif = new ToastNotification(
          !!(event.newMetadata as IEndpointMetadata).name
            ? (event.newMetadata as IEndpointMetadata).name +
              " " +
              this.translate.instant("is facing connectivity issues")
            : "Participant is facing connectivity issues",
          {
            body: "Participant has joined but cannot share microphone and/or camera",
            ttl: -1,
          }
        );
        // either from dismiss action below or user action
        notif.onDismissed(() => {
          this.participantNotificationMap.delete(event.userEndpointId);
        });
        // keep it to be removed
        this.participantNotificationMap.set(event.userEndpointId, notif);
        // notify
        this.notification.notify(notif);
      }
    } else {
      if (this.participantNotificationMap.has(event.userEndpointId)) {
        this.notification.dismiss(
          this.participantNotificationMap.get(event.userEndpointId)
        );
      }
    }
  }

  private async initRoom() {
    try {
      // this.activityService.loading(true);

      let stream: MediaStream;

      if (this.isWhisper) {
        this.acceptConstraints();
      } else if (this.isAgent) {
        this.activityService.loading(!this.isDeviceHelpVisible());
        // this.activityService.loading(true, 'entering');
        try {
          await this.conferenceService.createConference(this.conferenceId);

          this.cdr.markForCheck();
          stream = await this.openStream();

          if (!stream || this.skipDeviceSetup) {
            this.acceptConstraints();
          }
        } catch (error) {
          if (error.response?.status === 404) {
            // conference doesn't exist
            this.handleCallEnded(TerminateReasonEnum.error, error.response);
          } else {
            this.notification.error(error.message || error, { ttl: 3000 });
            this.activityService.loading(false);
            this.leaveConference();
          }
        }
        // finally {
        //   this.activityService.loading(false);
        // }
      } else {
        // show local stream first and then join conference
        stream = await this.openStream();

        if (this.isChildWindow) {
          // listen to cobrowse view requests and propagate to widget
          this.cobrowseService.listen(
            (
              ticket: string,
              originator: IEndpoint,
              requestType: CobrowseRequestType
            ) => {
              this.windowEventService.sendMessage(
                new CobrowseViewRequestAction({
                  ticket,
                  originator,
                  requestType,
                })
              );
            }
          );
        }

        if (!stream || this.skipDeviceSetup) {
          this.acceptConstraints();
        }
      }
    } catch (error) {
      this.activityService.hide();
      this.notification.error(
        "Could not connect. Please refresh your page and try again."
      );
      this.errorHandler.handleError(error);
      this.leaveConference();
    }
  }

  recStarted(info: IRecorderInfo) {
    this.recorderStarted.emit(info);
  }

  recStopped(info: IRecorderInfo) {
    this.recorderStopped.emit(info);
  }

  captureGeolocation() {
    if (
      this.isCustomer &&
      this.config.publicParam(PublicParam.SNAPSHOT_GEOLOCATION_ENABLED)
    ) {
      const exists = this.conferenceService.getConferenceMetadata(
        ConferenceMetadataKeyEnum.geolocation
      ) as GeolocationMetadata;
      if (
        exists &&
        (exists.coordinates ||
          exists.error === GeolocationError.POSITION_UNAVAILABLE)
      ) {
        return;
      }

      const cnotif = new ConfirmNotification(
        "Location requested",
        "Would you like to share your location?"
      );
      cnotif.onConfirmed(async () => {
        try {
          const coordinates = await this.deviceService.captureGeolocation();
          this.conferenceService.setConferenceMetadata(
            new GeolocationMetadata(this.conferenceService.myself, coordinates)
          );
        } catch (ex) {
          this.conferenceService.setConferenceMetadata(
            new GeolocationMetadata(
              this.conferenceService.myself,
              undefined,
              ex.message
            )
          );
        }
      });
      cnotif.onCanceled(() => {
        this.conferenceService.setConferenceMetadata(
          new GeolocationMetadata(
            this.conferenceService.myself,
            undefined,
            GeolocationError.PERMISSION_DENIED
          )
        );
      });
      this.notification.notify(cnotif);
    }
  }

  filesDropped(files: File[], participant: IEndpoint) {
    this.transferService.sendFileToParticipant(
      files[0],
      participant,
      this.interaction
    );
  }

  // ------ view accessors ------------------

  isTileActionOnTop(stream: StreamState) {
    return (
      (this.pointerService.isStarted &&
        this.pointerService.isTarget(
          stream?.originator.endpoint,
          stream?.type
        )) ||
      stream.video.state === "muted"
    );
  }

  isFileTransferAvailable(stream: StreamState) {
    return (
      stream.originator.endpoint !== this.conferenceService.myself.endpoint &&
      stream.type !== StreamTypes.SCREEN &&
      this.interactionType !== ConversationTypeEnum.voiceCall &&
      ((this.isAgent &&
        this.config.agentParamEnabled(AgentParam.FILE_TRANSFER_ENABLED)) ||
        (this.isCustomer &&
          this.config.customerParamEnabled(
            CustomerParam.FILE_TRANSFER_ENABLED
          )))
    );
  }

  get isDeviceSetupAvailable() {
    return computed(() => {
      return (
        !this.isWhisper && this.isDeviceSetupOpen && !this.isDeviceHelpVisible()
      );
    });
  }

  get skipDeviceSetup() {
    const devicesChecked = this.deviceService.isDeviceSetupSkipped(
      this.userService.getActiveUser(),
      this.config
    );
    return (
      devicesChecked ||
      this.isAudioCall ||
      this.isKioskMode ||
      this.isWhisper ||
      this.isPopup
    );
  }

  get containerCssClass() {
    return {
      "tile-container-screen-only": this.isScreenShareFullScreen,
      "tile-container-mobile-android": this.isMobileAndroid,
      "tile-container-mobile-ios": this.isMobileIOS,
      "tile-container-ready": !this.isDeviceSetupOpen,
      "tile-container-no-top-bar": !this.isTopBarVisible,
      "tile-container-no-media-controls": !this.isControlsVisible,
      "tile-container-captions": this.isCaptionsOn,
      "tile-container-mobile": this.isMobile,
      "tile-container-talkdesk": this.isTalkdeskApp,
      "tile-container-layout-spotlight":
        this.currentLayout === LayoutEnum.spotlight,
      "tile-container-layout-spotlight-maximise": this.spotlightTileMaximise,
      hidden: this.isOnHold,
    };
  }

  get isTalkdeskApp() {
    return this.applicationService.isTalkdeskApp;
  }

  get isRecorderVisible() {
    return this.config.featureEnabled(FEATURES.RECORDER);
  }

  get isPopup() {
    return this.interaction.getOrigin() === ConversationOriginEnum.POPUP;
  }

  get interactionType() {
    return this.interaction.getType();
  }

  get isKioskMode(): boolean {
    return this.interaction.getOriginMode() === OriginModeEnum.kiosk;
  }

  get canTerminate(): boolean {
    // better to never terminate the call. We had scenarios where customer was on device setup, agent refreshed the page and both joined different rooms
    return false;
    // callbacks remain open because if we close accidentally, we cannot send a new link
    // return this.interaction.getOrigin() !== ConversationOriginEnum.CALLBACK &&
    // if it is NOT a widget, we can terminate
    // return (
    //   this.interaction.getOrigin() !== ConversationOriginEnum.WIDGET ||
    //   // if it IS a widget, then we can terminate if the conversation type is audio or video.
    //   // if it is a chat then we keep it open
    //   (this.interaction.getOrigin() === ConversationOriginEnum.WIDGET &&
    //     (this.interaction.getType() === ConversationTypeEnum.videoCall ||
    //       this.interaction.getType() === ConversationTypeEnum.voiceCall))
    // );
  }

  get isWidget() {
    return (
      DeviceService.isIFrame &&
      !this.isAgent &&
      this.interaction.getOrigin() === ConversationOriginEnum.WIDGET
    );
  }

  get isMobile() {
    return DeviceService.isMobile;
  }

  get isMobileIOS() {
    return DeviceService.isMobile && DeviceService.isiOS;
  }

  get isMobileAndroid() {
    return DeviceService.isMobile && !DeviceService.isiOS;
  }

  get isScreenShareActive() {
    return !!this.screenStream;
  }

  get isScreenShareLocal() {
    return this.conferenceService.isScreenShareStreamLocal;
  }

  get isLeaveAvailable() {
    return this.mediaRules.isLeaveAvailable(this.interaction);
    /*&& !this.isPopup && !(this.isWidget && this.isCustomer) || this.isKioskMode*/
  }

  get isLaserVisible() {
    return this.config.featureEnabled(FEATURES.LASER_TOOL);
  }

  isLaserActive(stream) {
    return (
      this.pointerService.isTarget(stream.originator.endpoint, stream.type) &&
      this.pointerService.isRequester(this.conferenceService.myself.endpoint)
    );
  }

  get deviceErrorAriaLabel() {
    return this.notAllowedError()
      ? "access blocked"
      : this.notFoundError()
      ? "Devices not available"
      : this.streamError()
      ? "Your devices are not responding"
      : this.isWebViewError()
      ? "Limited access to media devices"
      : "Accessing your camera and microphone is taking longer than usual";
  }

  get isOnHold() {
    return this.callHoldState.isOnHold;
  }

  get isRatingRequiredCustomer() {
    return this.config.customerParamEnabled(CustomerParam.RATING_REQUIRED);
  }

  get isRatingRequiredAgent() {
    return this.config.agentParamEnabled(AgentParam.RATING_REQUIRED);
  }

  get isAudioCall() {
    return this.interaction.getType() === ConversationTypeEnum.voiceCall;
  }

  get isControlsEnabled() {
    return (
      !this.isRating &&
      this.isDeviceSetup &&
      // !this.isDeviceHelpVisible &&
      !this.isReviewingSnapshots &&
      !this.isCallEnded
    );
  }

  get isStreamControlsEnabled() {
    return (
      !this.isRating &&
      this.isDeviceSetup &&
      !this.isReviewingSnapshots &&
      !this.isCallEnded
    );
  }

  get isControlsVisible() {
    return (
      !this.isScreenShareFullScreen &&
      (this.mediaRules.isAudioAvailable ||
        this.mediaRules.isVideoAvailable ||
        this.mediaRules.isDisplayCaptureAvailable ||
        this.mediaRules.isLeaveAvailable(this.interaction) ||
        this.mediaRules.isHoldAvailable)
    );
  }

  get isTopBarVisible() {
    return (
      !this.isScreenShareFullScreen &&
      ((this.isCustomer &&
        this.config.customerParamEnabled(CustomerParam.TOP_BAR_ENABLED)) ||
        !this.isCustomer)
    );
  }

  // when in an audio call and the agent screen shares,
  // we only need to show the screen to the customer, not the participants
  // tried with layout.maximise but for a second or so the two participants are shown, before the screen stream arrives
  get isScreenShareFullScreen() {
    return (
      (this.isAudioCall && this.isWidget) ||
      (this.streams?.length === 1 && this.streams[0].type === "screen")
    );
  }

  get isSpotLightLayout() {
    return this.currentLayout === LayoutEnum.spotlight;
  }

  get isChildWindow() {
    return this.isWidget || this.isPopup;
  }

  get hasNoParticipants() {
    return (
      this.streams.length === 0 &&
      // !this.isDeviceHelpVisible &&
      !this.isDeviceSetupOpen &&
      this.isDeviceSetup &&
      !this.isLeavingByChoice &&
      !this.isRating &&
      !this.isSnapshotActive
    );
  }

  get isCaptionsEnabled() {
    return this.isCaptionsOn;
  }

  get isCaptionsAvailable() {
    return this.isCustomer && this.mediaRules.isCaptionsAvailable;
  }

  get isDeviceHelpVisible(): Signal<boolean> {
    return computed(() => {
      return (
        !this.isWhisper &&
        !this.isRating &&
        (this.notAllowedError() ||
          this.streamError() ||
          this.notFoundError() ||
          this.isWebViewError() ||
          this.isMediaRequestTimeoutError())
      );
    });
  }

  dismissHelp() {
    this.resetStreamErrors();
  }

  private resetStreamErrors() {
    this.notAllowedError.set(false);
    this.streamError.set(false);
    this.notFoundError.set(false);
    this.isWebViewError.set(false);
    this.isMediaRequestTimeoutError.set(false);
  }

  // ------ View actions ------------------

  acceptConstraints() {
    this.isDeviceSetupOpen = false;
    this.deviceService.isDevicesSetup = true;
    this.activityService.loading(true, "calling");
    this.transferService.getFilesOfInteractionFiltered(this.interactionID);

    // we need to set if the participant is in a popup, so that when he leaves, we can enable the 'send to chat'
    // on the agent sige
    this.conferenceService
      .joinConference(
        this.conferenceId,
        this.interaction.getOrigin(),
        this.interaction.getWhisperMode()
      )
      .then((terminatedObservable) => {
        this.activityService.hide();
        this.isDeviceSetup = true;
        this.deviceSetupComplete.emit();

        this.callStarted.emit();

        if (this.isDeviceHelpVisible()) {
          this.conferenceService.updateEndpointMetadata({
            mediaDevicesError: true,
          });
        }

        if (this.isChildWindow) {
          this.windowEventService.sendMessage(
            new CallStartedAction({
              audio: this.mediaRules.isAudioAvailable,
              video: this.mediaRules.isVideoAvailable,
              displayCapture: this.mediaRules.isDisplayCaptureAvailable,
            })
          );
        }

        this.cdr.markForCheck();

        terminatedObservable.subscribe({
          next: (reason: TerminateReasonEnum) => {
            this.callEnding.emit({
              terminated: true,
              terminatedRemotely: true,
            });
            this.handleCallEnded(reason);
            this.cdr.markForCheck();
          },
          error: (error) => {
            this.handleCallEnded(TerminateReasonEnum.error, error);
            this.cdr.markForCheck();
          },
        });
      })
      .catch((error) => {
        if (
          error.response?.status === 404 ||
          (error.response?.status === 403 &&
            error.response?.data.error === "participant limit reached")
        ) {
          // conference doesn't exist
          // or conference reached maximum participants
          this.handleCallEnded(
            TerminateReasonEnum.error,
            error.response?.data || error.data
          );
        } else {
          this.errorHandler.handleError(error);
          this.handleCallEnded(
            TerminateReasonEnum.error,
            error.response || error
          );
        }
        this.cdr.markForCheck();
      });
  }

  async handleCallEnded(reason: TerminateReasonEnum, error?: Error) {
    this.isCallEnded = true;
    this.conferenceService.closeLocalStreams();
    this.tryTerminateCobrowse();
    if (this.mediaEffectsService.isMediaFilterOn) {
      this.mediaEffectsService.disable();
    }
    this.activityService.hide();
    try {
      if (!error) {
        // wait until we approve the snapshots
        if (this.isAgent && this.snapshotService.snapshotsTakenByMe) {
          await this.snapshotService.review();
          this.isReviewingSnapshots = false;
          this.isSnapshotActive = false;
          this.isSnapshotStarted = false;
        }
        // wait until we rate
        if (
          !this.skipRating &&
          ((this.isAgent && this.isRatingRequiredAgent) ||
            (!this.isAgent && this.isRatingRequiredCustomer))
        ) {
          this.isRating = true;
          this.callPendingRating.emit(true);
          if (this.isChildWindow) {
            this.windowEventService.sendMessage(new FeedbackRequestedAction());
          }
          await this.ratingService.showPopup(this.conferenceId);
        }
        this.skipRating = false;
      }
    } catch (ex) {
      // don't do anything, proceed as usual
      debugError(ex);
    }
    this.callPendingRating.emit(false);
    this.isRating = false;

    this.notification.dismissAll();

    // if is running inside widget
    if (this.isChildWindow) {
      this.windowEventService.sendMessage(
        new CallEndedAction({ reason, error })
      );
      // if the user opens a new call, show the device setup page again (Haris had this issue in Demos)
      this.deviceService.removeSkippedDeviceSetup();
    }
    this.isLeavingByChoice = true;

    // for some reason, ngOnDestroy is not called on the normal flow. Adding the delay fixes the issue
    // if however we wait for a rating, it is called :/
    await delay(1);
    this.callEnded.emit({ reason, error });
  }

  openStreamRetrySubscription;

  private _openStreamWithFallbacks(
    permissions?: IMediaDevicesEvents["permissions"]
  ): Promise<MediaStream> {
    const handleEx = (ex) => {
      if (this.isAudioCall) {
        throw ex;
      } else {
        if (this.mediaRules.joinWithVideoOn) {
          return this.openVideoOnlyStream();
        } else {
          throw ex;
        }
      }
    };

    if (this.isAudioCall) {
      return this.openAudioOnlyStream();
    } else {
      if (permissions) {
        if (permissions.audioinput)
          return this.openAudioOnlyStream().catch(handleEx);
        if (permissions.videoinput)
          return this.openVideoOnlyStream().catch(handleEx);
      } else {
        return this.openDefaultStream()
          .catch((error) =>
            error.name === "TypeError" ? null : this.openAnyVideoStream()
          )
          .catch(() => this.openAudioOnlyStream())
          .catch(handleEx);
      }
    }

    // return this.isAudioCall
    //   ? this.openAudioOnlyStream()
    //   : this.openDefaultStream()
    //       .catch((error) =>
    //         error.name === "TypeError" ? null : this.openAnyVideoStream()
    //       )
    //       .catch(() => this.openAudioOnlyStream())
    //       .catch((ex) => {
    //         if (this.isAudioCall) {
    //           throw ex;
    //         } else {
    //           if (this.mediaRules.joinWithVideoOn) {
    //             return this.openVideoOnlyStream();
    //           } else {
    //             throw ex;
    //           }
    //         }
    //       });
  }

  async tryOpenSecondaryCamera() {
    const secondaryCameras = MediaDevices.getDeviceList().filter(
      (d) =>
        d.kind === "videoinput" &&
        d.deviceId !== this.store.mystream.video?.deviceId
    );
    if (secondaryCameras.length > 0) {
      try {
        await this.local.openSecondaryCameraStream(
          secondaryCameras[0].deviceId
        );
      } catch (ex) {
        // race condition. agent may not have joined to get the event.
        // do we really need to notify? commented out for now.
        // this.conferenceService.getParticipantAgents().forEach((p) => {
        //   this.mediaRules.rejectRemoteStreamStart(
        //     { username: p.username, endpoint: p.endpoint },
        //     ex
        //   );
        // });
      }
    }
  }

  /**
   * will try to open a local stream. Once ready a 'localStreamReady$' observable will fire.
   */
  async openStream(permissions?: IMediaDevicesEvents["permissions"]) {
    this.resetStreamErrors();

    let stream: MediaStream | null = this.local.pipe.input.isActive
      ? this.local.pipe.streamOut
      : await (!DeviceService.isWebView
          ? this._openStreamWithFallbacks(permissions)
          : withTimeout(
              new Promise<MediaStream>((resolve) => {
                // the timeout may run first but the user might accept after the 30 secs
                // in safari, this returns no stream so we need to handle it inside here
                this._openStreamWithFallbacks(permissions).then((s) => {
                  if (s) {
                    this.local.setMainStream(s);
                  }
                  return resolve(s);
                });
              }),
              30000 // wait for 30 seconds
            )
        ).catch((error) => {
          this.isRequestingMedia.set(false);
          this.activityService.loading(false);

          this.mediaError(error);

          if (this.openStreamRetrySubscription) {
            this.openStreamRetrySubscription.unsubscribe();
            this.openStreamRetrySubscription = null;
          }

          this.openStreamRetrySubscription = this.local.permissions$
            .pipe(take(1))
            .subscribe((p) => {
              if (!this.mystream) {
                this.conferenceService.updateEndpointMetadata({
                  mediaDevicesError: false,
                });
                // firefox specific, we get the permissions change when the user has accepted, not when the permissions changed
                if (!this.local.isUnmuting) this.openStream(p);
              }
            });

          return null;
        });

    this.activityService.loading(false);
    this.isRequestingMedia.set(false);

    if (stream) {
      this.local.setMainStream(stream);
    }

    return stream;
  }

  copyToClipboard(text: string) {
    copyTextToClipboard(text);
    this.notification.success("Copied to clipboard");
  }

  mediaError(error: Error) {
    if (error.message === "timeout") {
      this.isMediaRequestTimeoutError.set(true);
    } else {
      if (DeviceService.isWebView) {
        this.isWebViewError.set(true);
      } else {
        switch (error.name) {
          case "NotAllowedError":
            this.notAllowedError.set(true);
            break;
          case "NotFoundError":
            this.notFoundError.set(true);
            break;
          default:
            this.streamError.set(true);
            break;
        }
      }
    }
    setTimeout(() => {
      this.mediaErrorCardRef?.nativeElement?.focus();
    }, 100);
  }

  private openDefaultStream(): Promise<MediaStream> {
    try {
      return this.local.openStream(
        false,
        {
          audio:
            this.mediaRules.isAudioAvailable && this.mediaRules.joinWithAudioOn,
          video:
            this.mediaRules.isVideoAvailable &&
            this.mediaRules.joinWithVideoOn &&
            !this.isAudioCall,
        },
        MediaDevices.preferredConstraints,
        this.local.getDeviceConstraints(
          this.local.audioDevice,
          this.local.videoDevice
        ),
        DeviceService.isMobile
          ? {
              video: {
                facingMode: this.mediaRules.defaultFacingMode,
              },
            }
          : {},
        ...Object.values(MediaDevices.constraints)
      );
    } catch (error) {
      if (error.name !== "TypeError") {
        debug(
          `failed to access video device with constraints ${this.local.pipe.input.constraints.video} and error`,
          error
        );
        this.errorHandler.handleError(error);
      }

      throw error;
    }
  }

  private openAnyVideoStream(): Promise<MediaStream> {
    try {
      return this.local.openStream(
        false,
        {
          audio: true,
          video: !this.isAudioCall,
        },
        this.local.getDeviceConstraints(
          this.local.audioDevice,
          this.local.videoDevice
        )
      );
    } catch (error) {
      this.notification.error(this.translate.instant(error.message || error), {
        ttl: 3000,
      });
      throw error;
    }
  }

  private openAudioOnlyStream(): Promise<MediaStream> {
    try {
      return this.local.openStream(false, {
        audio: true,
        video: false,
      });
    } catch (error) {
      this.notification.error(error.message || error, { ttl: 3000 });
      throw error;
    }
  }

  private openVideoOnlyStream(): Promise<MediaStream> {
    try {
      return this.local.openStream(false, {
        audio: false,
        video: true,
      });
    } catch (error) {
      this.notification.error(error.message || error, { ttl: 3000 });
      throw error;
    }
  }

  public exitConference(): void {
    this.tryTerminateCobrowse();
    this.callEnding.emit({ terminated: false, terminatedRemotely: false });
    this.conferenceService.leaveSession(TerminateReasonEnum.leave);
  }

  public terminateConference(): void {
    this.tryTerminateCobrowse();
    this.callEnding.emit({ terminated: true, terminatedRemotely: false });
    this.conferenceService.terminateSession();
  }

  public leaveConference(reason?: TerminateReasonEnum): void {
    this.isLeavingByChoice = true;
    // no session yet, just exit
    if (!this.conferenceService.sessionExists) {
      this.conferenceService.closeLocalStreams();
      return this.exit.emit();
    }
    let terminated = false;
    this.tryTerminateCobrowse();
    try {
      switch (reason) {
        // unpublish is performed by server
        case TerminateReasonEnum.terminate:
        case TerminateReasonEnum.conversationEnded:
          this.conferenceService.terminateSession(reason);
          terminated = true;
          break;
        default:
          this.conferenceService.leaveSession(
            reason || TerminateReasonEnum.leave
          );
          break;
      }
    } catch (ex) {
      debug(ex);
    }
    this.callEnding.emit({ terminated, terminatedRemotely: false });
  }

  cancel(): void {
    this.isLeavingByChoice = true;
    this.conferenceService.closeLocalStreams();
    if (this.mediaEffectsService.isMediaFilterOn) {
      this.mediaEffectsService.disable();
    }
    this.exit.emit();
  }

  reload() {
    window.location.reload();
  }

  private tryTerminateCobrowse() {
    if (
      this.isAgent &&
      (this.cobrowseService.isStarted || this.cobrowseService.isPendingApproval)
    ) {
      this.cobrowseService.terminate();
    }
  }

  // ------- track *ngFor functions -----------

  // if we track by mediaStream.id then we remove/add new tiles on mute/unmute :/
  trackByMediaIdFn(index: number, stream: StreamState) {
    return `${stream.type}_${stream.primary}_${stream.originator.endpoint}`;
  }

  // ------ view helpers ----------------------

  get isParticipantPresent() {
    return this.endpoints.length > 0;
  }

  get isAgentPresent() {
    return (
      this.endpoints.filter((e) =>
        (e.metadata as IEndpointMetadata)?.roles?.includes(UserRoleEnum.agent)
      ).length > 0
    );
  }

  get isCustomerPresent() {
    return (
      this.endpoints.filter((e) =>
        (e.metadata as IEndpointMetadata)?.roles?.includes(
          UserRoleEnum.customer
        )
      ).length > 0
    );
  }

  get endpoint() {
    return this.store.endpoint;
  }
}
