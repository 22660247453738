import { Injectable, NgZone } from "@angular/core";
import {
  ApiCommandResource,
  ApiQueryResource,
  Asr,
  IOrganizationLanguage,
  IProviderLanguages,
  ReconnectionReason,
} from "@auvious/asr";
import { AuviousCommon, PagedCollection } from "@auvious/common";
import { AuviousRtcService } from "./rtc.service";
import {
  ISpeechToTextTranscriptChunk,
  ITranscriptStatus,
  ITranscriptTransformStatus,
} from "../models/interfaces";
import { filter, Observable, Subject } from "rxjs";
import { ConferenceService } from "./conference.service";
import { LocalMediaService } from "./local.media.service";
import {
  ApplicationProviderTypeEnum,
  StreamTrackKindEnum,
  ASROfflineTranscriptEvent,
  TranscriptTransformType,
} from "../core-ui.enums";
import { NotificationService } from "./notification.service";
import { GenericErrorHandler } from "./error-handlers.service";
import {
  AgentParam,
  IProviderLanguage,
  ProviderOrganizationLanguage,
} from "../models";
import { AppConfigService } from "./app.config.service";
import {
  IProviderModel,
  IProvisionedModel,
  IProvisionLanguageOptions,
  ITranscriptStrategy,
  ITranscriptTransformOptions,
  ITranscriptTransformStrategy,
} from "../models/strategies/ITranscriptStrategy";
import {
  OpenAITranscriptStrategy,
  GoogleTranscriptStrategy,
} from "../models/strategies";
import { UserService } from "./user.service";
import { ApplicationService } from "./application.service";

export interface ITranscriptEventHandlers {
  ready?: () => void;
  reconnecting?: (payload: {
    delay: number;
    times: number;
    reason: ReconnectionReason;
  }) => void;
  reconnected?: () => void;
  transcript?: (transcript: {
    userId: string;
    transcript: string;
    isFinal: boolean;
  }) => void;
  transcriptFailed?: () => void;
  translationFailed?: () => void;
}

interface ITranscriptService
  extends ITranscriptStrategy,
    ITranscriptTransformStrategy {}

@Injectable()
export class TranscriptService
  implements ITranscriptStrategy, ITranscriptTransformStrategy
{
  private apiCommand: ApiCommandResource;
  private apiQuery: ApiQueryResource;
  private instance: Asr;
  private common: AuviousCommon;
  private impl: ITranscriptService;

  private asrOfflineEventSubject: Subject<{
    id: string;
    type: ASROfflineTranscriptEvent;
  }> = new Subject();

  private _chunk = new Subject<ISpeechToTextTranscriptChunk>();
  public chunk$ = this._chunk.asObservable();

  private _reset = new Subject<void>();
  public reset$ = this._reset.asObservable();

  private _enabled = new Subject<void>();
  public enabled$ = this._enabled.asObservable();

  private _disabled = new Subject<void>();
  public disabled$ = this._disabled.asObservable();

  constructor(
    private rtc: AuviousRtcService,
    private local: LocalMediaService,
    private conference: ConferenceService,
    private notification: NotificationService,
    private logger: GenericErrorHandler,
    private zone: NgZone,
    private user: UserService,
    private application: ApplicationService,
    config: AppConfigService
  ) {
    rtc.common$.subscribe((c) => {
      this.apiCommand = new ApiCommandResource(c);
      this.apiQuery = new ApiQueryResource(c);
      this.common = c;
    });

    config.configChanged$.subscribe((c) => {
      let provider;
      if (this.user.isAdmin) {
        provider =
          c.serviceParameters?.transcriptProvider ||
          // backwards compatibility to when we had only google
          c.serviceParameters?.speechToTextStorageProvider;
      } else if (this.user.isAgent) {
        if (c.agentParameters?.[AgentParam.TRANSCRIPT_PROVIDER]) {
          provider = c.agentParameters[AgentParam.TRANSCRIPT_PROVIDER];
        } else {
          // backwards compatibility to when we had only google
          if (c.agentParameters?.[AgentParam.TRANSCRIPT_PROVIDER_CONFIGURED]) {
            provider = ApplicationProviderTypeEnum.GOOGLE_CLOUD;
          }
        }
      }
      if (provider) this.providerChanged(provider);
    });

    this.local.streamMutedChange$
      .pipe(
        filter(
          (s) => s.trackKind === StreamTrackKindEnum.audio && this.isEnabled
        )
      )
      .subscribe(async (s) => {
        try {
          await this.setStream(
            s.muted || this.conference.isConferenceOnHold
              ? null
              : s.stream.mediaStream
          );
        } catch (ex) {
          this.logger.handleError(ex);
          this.notification.error("Closed Captions", {
            body: "Could not start captions service",
          });
        }
      });
  }

  public providerChanged(provider: ApplicationProviderTypeEnum) {
    switch (provider) {
      case ApplicationProviderTypeEnum.OPEN_AI:
        this.impl = new OpenAITranscriptStrategy(
          this.logger,
          this.rtc,
          this.application
        );
        break;
      case ApplicationProviderTypeEnum.GOOGLE_CLOUD:
      default:
        this.impl = new GoogleTranscriptStrategy(this.logger, this.rtc);
        break;
    }
  }

  public propagateEvent(event: {
    id: string;
    type: ASROfflineTranscriptEvent;
  }) {
    this.asrOfflineEventSubject.next(event);
  }

  public get eventReceived$(): Observable<{
    id: string;
    type: ASROfflineTranscriptEvent;
  }> {
    return this.asrOfflineEventSubject.asObservable();
  }

  public get query(): ApiQueryResource {
    return this.apiQuery;
  }

  public get cmd(): ApiCommandResource {
    return this.apiCommand;
  }

  public isFeatureSupported(feature: TranscriptTransformType): boolean {
    return this.impl.isFeatureSupported(feature);
  }

  public init(
    applicationId: string,
    conferenceId: string,
    handlers: ITranscriptEventHandlers
  ): void {
    this.instance = new Asr(this.common, {
      assetsPath: "/assets/asr/",
      applicationId,
      conferenceId,
    });
    this.instance.events.on(
      "ready",
      this.zone.run(() => handlers.ready)
    );
    this.instance.events.on(
      "reconnecting",
      this.zone.run(() => handlers.reconnecting)
    );
    this.instance.events.on(
      "reconnected",
      this.zone.run(() => handlers.reconnected)
    );
    this.instance.events.on("transcript", (e: ISpeechToTextTranscriptChunk) => {
      this.zone.run(() => {
        this._chunk.next(e);
        handlers.transcript?.(e);
      });
    });
    this.instance.events.on(
      "transcriptFailed",
      this.zone.run(() => handlers.transcriptFailed)
    );
    this.instance.events.on(
      "translationFailed",
      this.zone.run(() => handlers.translationFailed)
    );
  }

  /**
   * wait for 'ready' event, possible 'reconnecting' too
   *
   * @param organizationLanguageId  string
   * @param record boolean | optional. Record transcript or not. Storage provider must exist
   * @param translate boolean | optional. Translate transcript or not
   */
  public async enable(
    organizationLanguageId: string,
    record?: boolean,
    translate?: boolean
  ): Promise<void> {
    try {
      await this.instance.enable(organizationLanguageId, translate, record);
      this._enabled.next();
    } catch (ex) {
      throw ex;
    }
  }

  public disable(notify = true): Promise<void> {
    if (notify) {
      this._reset.next();
      this._disabled.next();
    }
    return this.instance?.disable();
  }

  public get isEnabled() {
    return this.instance?.isEnabled();
  }

  public get isInitialised() {
    return !!this.instance;
  }

  /**
   * Changes stream set for transcription.
   * If null or no audio tracks included, transcription is paused and should be called again with valid stream to resume.
   * if mediastream changes, set it again, with small interruptions.
   *
   * @param stream MediaStream
   * @returns boolean
   */
  public async setStream(stream: MediaStream): Promise<boolean> {
    return this.instance.setStream(stream);
  }

  public async destroy(conferenceId?: string) {
    await this.disable();
    if (!this.instance) {
      return;
    }
    this.instance.events.clear();
    this.instance = undefined;
    if (conferenceId) {
      // destroys transcriptId
      this.cmd.cleanUserConfiguration(conferenceId);
    }
  }

  /** strategy implementations */

  public removeOrganizationLanguage(
    provider: ApplicationProviderTypeEnum,
    language: ProviderOrganizationLanguage
  ) {
    return this.impl.removeProvisionedLanguage(provider, language);
  }

  public provisionLanguage(
    language: IProviderLanguage,
    options: IProvisionLanguageOptions
  ): Promise<{ organizationLanguageId: string }> {
    return this.impl.provisionLanguage(language, options);
  }

  public createTranscriptRequest(
    conversationId: string,
    organizationLanguageId: string
  ): Promise<{ conversationId: string; id: string }> {
    return this.impl.createTranscriptRequest(
      conversationId,
      organizationLanguageId
    );
  }

  public removeTranscript(conversationId: string, transcriptId: string) {
    return this.impl.removeTranscript(conversationId, transcriptId);
  }

  public transformTranscriptForConversation(
    conversationId: string,
    transcriptId: string,
    transform: TranscriptTransformType,
    options?: ITranscriptTransformOptions
  ): Promise<void> {
    return this.impl.transformTranscriptForConversation(
      conversationId,
      transcriptId,
      transform,
      options
    );
  }

  public getTransformsForConversation(
    conversationId: string,
    transcriptId: string,
    transform: TranscriptTransformType
  ): Promise<ITranscriptTransformStatus[]> {
    return this.impl.getTransformsForConversation(
      conversationId,
      transcriptId,
      transform
    );
  }

  public getTransformStatusForConversation(
    conversationId: string,
    transcriptId: string,
    transformId: string,
    transform: TranscriptTransformType
  ): Promise<ITranscriptTransformStatus> {
    return this.impl.getTransformStatusForConversation(
      conversationId,
      transcriptId,
      transformId,
      transform
    );
  }

  getTransformURL(
    conversationId: string,
    transcriptId: string,
    transfromId: string,
    transform: TranscriptTransformType
  ): Promise<{ url: string; validUntil: string }> {
    return this.impl.getTransformURL(
      conversationId,
      transcriptId,
      transfromId,
      transform
    );
  }

  removeTransform(
    conversationId: string,
    transcriptId: string,
    transformId: string,
    transformType: TranscriptTransformType
  ): Promise<void> {
    return this.impl.removeTransform(
      conversationId,
      transcriptId,
      transformId,
      transformType
    );
  }

  public async getProviderLanguages(
    usage: "translations" | "transcriptions",
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IProviderLanguages>> {
    return this.impl.getProviderLanguages(usage, page, pageSize);
  }

  public getTranscriptsForConversation(
    conversationId: string
  ): Promise<ITranscriptStatus[]> {
    return this.impl.getTranscriptsForConversation(conversationId);
  }

  getTranscriptStatusForConversation(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscriptStatus> {
    return this.impl.getTranscriptStatusForConversation(
      conversationId,
      transcriptId
    );
  }

  public async getOrganizationLanguages(
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IOrganizationLanguage>> {
    return this.impl.getProvisionedLanguages(page, pageSize);
  }

  public getTranscriptURL(
    conversationId: string,
    transcriptId: string,
    type: "inline" | "attachment"
  ): Promise<{ url: string; validUntil: string }> {
    return this.impl.getTranscriptURL(conversationId, transcriptId, type);
  }

  getProvisionedLanguages(
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IOrganizationLanguage>> {
    return this.impl.getProvisionedLanguages(page, pageSize);
  }
  removeProvisionedLanguage(
    provider: ApplicationProviderTypeEnum,
    language: ProviderOrganizationLanguage
  ) {
    return this.impl.removeProvisionedLanguage(provider, language);
  }
  getProviderModels(
    providerType: ApplicationProviderTypeEnum
  ): Promise<IProviderModel[]> {
    return this.impl.getProviderModels(providerType);
  }
  getProvisionedModel(): Promise<IProvisionedModel[]> {
    return this.impl.getProvisionedModel();
  }
  provisionModel(
    provider: ApplicationProviderTypeEnum,
    model: IProvisionedModel
  ): Promise<{ organizationModelId: string }> {
    return this.impl.provisionModel(provider, model);
  }
  removeProvisionedModel(
    provider: ApplicationProviderTypeEnum,
    model: IProvisionedModel
  ): Promise<void> {
    return this.impl.removeProvisionedModel(provider, model);
  }
}
