import {
  ChangeDetectorRef,
  Component,
  computed,
  Input,
  OnInit,
  signal,
} from "@angular/core";
import { TranscriptService } from "../../../../core-ui/services/transcript.service";
import {
  AgentParam,
  AppConfigService,
  ApplicationProviderTypeEnum,
  extractErrorMessage,
  ITranscriptStatus,
  NotificationService,
  ProviderLanguage,
  ProviderOrganizationLanguage,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../../../core-ui";
import { Subscription } from "rxjs";

@Component({
  selector: "app-composition-transcript",
  templateUrl: "./composition-transcript.component.html",
  styleUrls: ["./composition-transcript.component.scss"],
})
export class CompositionTranscriptComponent implements OnInit {
  @Input() conversationId: string;

  subscriptions: Subscription = new Subscription();
  languageMap: { [code: string]: string } = {};
  languages = signal<ProviderOrganizationLanguage[]>([]);
  providerLanguages = signal<ProviderLanguage[]>([]);
  translationLanguages = signal<ProviderLanguage[]>([]);
  activeLanguage: ProviderOrganizationLanguage = null;
  translateLanguage: ProviderOrganizationLanguage = null;
  transcriptStatus = signal<ITranscriptStatus>(null);
  isTranscriptLoading = signal(false);
  isTranslateLoading = signal(false);
  isSentimentLoading = signal(false);
  isSummaryLoading = signal(false);
  isLoadingURL = signal(false);
  isTranslatePopupOpen = signal(false);
  isSummarizeAvailable = false;
  isTranslateAvailable = false;
  isSentimentAvailable = false;
  confirmRemove = signal(false);
  transcriptUrl = signal(undefined);
  transformSummary = signal(undefined);
  transformTranslation = signal(undefined);
  transformSentiment = signal(undefined);
  lang: string;

  constructor(
    private transcript: TranscriptService,
    private notification: NotificationService,
    private config: AppConfigService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.lang = this.config.language;
    this.getLanguages();
    this.getTranscriptStatus();

    this.isSummarizeAvailable = this.transcript.isFeatureSupported("summary");
    this.isTranslateAvailable =
      this.transcript.isFeatureSupported("translation");
    this.isSentimentAvailable = this.transcript.isFeatureSupported("sentiment");

    this.subscriptions.add(
      this.transcript.eventReceived$.subscribe(async (e) => {
        switch (e.type) {
          case "ASROfflineTranscriptCreatedEvent":
            this.getTranscriptStatus();
            break;
          case "ASROfflineTranscriptFailedEvent":
            this.notification.error(
              "Could not create transcript. Please try again"
            );
            this.transcriptStatus.set(undefined);
            break;
          case "ASROfflineTranslationCreatedEvent":
          case "ASROfflineTranslationFailedEvent":
            const trans = await this.getTransform("translation");
            this.transformTranslation.set(trans);
            break;
          case "ASROfflineSummarizationCreatedEvent":
          case "ASROfflineSummarizationFailedEvent":
            const summ = await this.getTransform("summary");
            this.transformSummary.set(summ);
            break;
          case "ASROfflineSentimentCreatedEvent":
          case "ASROfflineSentimentFailedEvent":
            const sent = await this.getTransform("sentiment");
            this.transformSentiment.set(sent);
            break;
        }
      })
    );
  }

  private async getLanguages() {
    try {
      const [providerLanguages, configuredLanguages, translationLanguages] =
        await Promise.all([
          this.transcript.getProviderLanguages("transcriptions", 0, 20),
          this.transcript.getOrganizationLanguages(0, 20),
          this.transcript.getProviderLanguages("translations", 0, 20),
        ]);

      this.providerLanguages.set(
        providerLanguages.content.map((l) => new ProviderLanguage(l))
      );
      this.translationLanguages.set(
        translationLanguages.content.map((l) => new ProviderLanguage(l))
      );

      this.providerLanguages().forEach(
        (l) => (this.languageMap[l.code] = l.name)
      );

      // provisioned
      this.languages.set(
        configuredLanguages.content.map(
          (l) =>
            new ProviderOrganizationLanguage({
              ...l,
              languageName: this.languageMap[l.languageCode],
            })
        )
      );

      this.cd.detectChanges();
    } catch (ex) {
      // this.notification.error(extractErrorMessage(ex));
    }
  }

  async removeTranscript() {
    try {
      this.confirmRemove.set(false);
      const id = this.transcriptStatus().id;
      this.transcriptStatus.set(undefined);
      await this.transcript.removeTranscript(this.conversationId, id);
    } catch (ex) {
      this.notification.error("Could not remove existing transcript", {
        body: extractErrorMessage(ex),
      });
      this.requestTranscript();
    }
  }

  async requestTranscript() {
    try {
      this.isTranscriptLoading.set(true);
      const response = await this.transcript.createTranscriptRequest(
        this.conversationId,
        this.activeLanguage.id
      );
      this.transcriptStatus.set({
        id: response.id,
        state: TranscriptStateEnum.submitted,
        createdAt: new Date(),
        language: this.activeLanguage.id,
      });
      this.activeLanguage = null;
      this.transcriptUrl.set(null);
      this.getTranscriptStatus();
    } catch (ex) {
      this.notification.error("Could not request a transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isTranscriptLoading.set(false);
    }
  }

  private async getTransforms() {
    try {
      const [summary, translate, sentiment] = await Promise.all([
        this.getTransform("summary"),
        this.getTransform("translation"),
        this.getTransform("sentiment"),
      ]);

      this.transformSummary.set(summary);
      this.transformTranslation.set(translate);
      this.transformSentiment.set(sentiment);
    } catch (ex) {
      // nothing
    }
  }

  private async getTransform(type: TranscriptTransformType) {
    try {
      const response = await this.transcript.getTransformsForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        type
      );
      return response?.[0];
    } catch (ex) {
      return undefined;
    }
  }

  private async getTranscriptStatus() {
    try {
      this.isTranscriptLoading.set(true);
      const transcripts = await this.transcript.getTranscriptsForConversation(
        this.conversationId
      );
      this.transcriptStatus.set(transcripts?.[0]);

      if (
        !this.transcriptStatus() ||
        this.transcriptStatus()?.state.toLowerCase() ===
          TranscriptStateEnum.failed.toLowerCase()
      ) {
        return this.transcriptStatus.set(undefined);
      }
      if (
        this.transcriptStatus().state.toLowerCase() ===
        TranscriptStateEnum.completed.toLowerCase()
      ) {
        this.getTransforms();
      }
    } catch (ex) {
      this.transcriptStatus.set(undefined);
    } finally {
      this.isTranscriptLoading.set(false);
    }
  }

  async getDownloadLink() {
    try {
      this.isLoadingURL.set(true);
      const response = await this.transcript.getTranscriptURL(
        this.conversationId,
        this.transcriptStatus().id,
        "inline"
      );
      this.transcriptUrl.set(response.url);
    } catch (ex) {
      this.notification.error("Could not generate download URL", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isLoadingURL.set(false);
    }
  }

  async summarize() {
    try {
      this.isSummaryLoading.set(true);
      await this.transcript.transformTranscriptForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        "summary"
      );
      const response = await this.getTransform("summary");
      this.transformSummary.set(response);
    } catch (ex) {
      this.notification.error("Could not summarize transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isSummaryLoading.set(false);
    }
  }

  async translate() {
    try {
      this.isTranslatePopupOpen.set(false);
      this.isTranslateLoading.set(true);
      await this.transcript.transformTranscriptForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        "translation",
        { language: this.translateLanguage.code }
      );
      const response = await this.getTransform("translation");
      this.transformTranslation.set(response);
    } catch (ex) {
      this.notification.error("Could not translate transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isTranslateLoading.set(false);
    }
  }

  async analyze() {
    try {
      this.isSentimentLoading.set(true);
      await this.transcript.transformTranscriptForConversation(
        this.conversationId,
        this.transcriptStatus().id,
        "sentiment"
      );
      const response = await this.getTransform("sentiment");
      this.transformSentiment.set(response);
    } catch (ex) {
      this.notification.error("Could not analyze transcript", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isSentimentLoading.set(false);
    }
  }

  /** getters */

  get transcriptTitle() {
    return computed(
      () =>
        this.providerLanguages().find(
          (l) => l.code === this.transcriptStatus().language
        )?.name
    );
  }

  get isTranscriptAvailable() {
    return computed(() => !!this.transcriptStatus());
  }

  get isTranscriptProcessing() {
    return computed(
      () =>
        this.transcriptStatus()?.state.toLowerCase() ===
          TranscriptStateEnum.processing.toLowerCase() ||
        this.transcriptStatus()?.state.toLowerCase() ===
          TranscriptStateEnum.resubmit.toLowerCase()
    );
  }

  get isTranscriptCompleted() {
    return computed(
      () =>
        this.transcriptStatus()?.state.toLowerCase() ===
        TranscriptStateEnum.completed.toLowerCase()
    );
  }

  get isTranscriptSubmitted() {
    return computed(
      () =>
        this.transcriptStatus()?.state.toLowerCase() ===
        TranscriptStateEnum.submitted.toLowerCase()
    );
  }

  get isGenerateTranscriptDisabled() {
    return computed(
      () =>
        !this.activeLanguage ||
        this.isTranscriptLoading() ||
        this.isTranscriptProcessing()
    );
  }

  get isDeleteTranscriptDisabled() {
    return computed(
      () =>
        this.isTranscriptLoading() ||
        this.isTranscriptSubmitted() ||
        this.isTranscriptProcessing()
    );
  }
}
