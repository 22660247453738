import {
  Component,
  signal,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  extractErrorMessage,
  ITranscriptTransformStatus,
  NotificationService,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../../../core-ui";
import { TranscriptService } from "../../../../core-ui/services/transcript.service";

@Component({
  selector: "app-composition-transcript-transform",
  templateUrl: "./composition-transcript-transform.component.html",
  styleUrls: ["./composition-transcript-transform.component.scss"],
})
export class CompositionTranscriptTransformComponent implements OnInit {
  @Input() conversationId: string;
  @Input() transcriptId: string;
  @Input() transform: ITranscriptTransformStatus;
  @Input() transformType: TranscriptTransformType;

  @Output() removed = new EventEmitter<void>();

  url = signal<string>(null);
  confirmRemove = signal(false);
  title: string;
  subtitle: string;
  icon: string;
  isLoadingURL = signal(false);

  constructor(
    private transcript: TranscriptService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    switch (this.transformType) {
      case "sentiment":
        this.icon = "file-sentiment";
        this.title = "Sentiment Analysis";
        this.subtitle = "Positive, negative or neutral";
        break;
      case "summary":
        this.icon = "file-summary";
        this.title = "Summary";
        this.subtitle = "Basic bullet points of the conversation";
        break;
      case "translation":
        this.icon = "file-translation";
        this.title = "Translation";
        this.subtitle = "English";
        break;
    }
  }

  async getURL() {
    try {
      this.isLoadingURL.set(true);
      const data = await this.transcript.getTransformURL(
        this.conversationId,
        this.transcriptId,
        this.transform.id,
        this.transformType
      );
      this.url.set(data.url);
    } catch (ex) {
      this.notification.error("Could not retrieve URL", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isLoadingURL.set(false);
    }
  }

  async removeTransform() {
    try {
      await this.transcript.removeTransform(
        this.conversationId,
        this.transcriptId,
        this.transform.id,
        this.transformType
      );
      this.removed.emit();
    } catch (ex) {
      this.notification.error("Could not remove file", {
        body: extractErrorMessage(ex),
      });
    }
  }

  get isCompleted() {
    return (
      this.transform.state.toLowerCase() ===
      TranscriptStateEnum.completed.toLowerCase()
    );
  }

  get isProcessing() {
    return (
      this.transform.state.toLowerCase() ===
      TranscriptStateEnum.processing.toLowerCase()
    );
  }
}
