/* eslint-disable no-shadow */
import { IAppointmentFormat } from "../core-ui";

export const API_V2 = "PureCloud Webchat v2";
export const TAG_CUSTOMER_TRANSFER_ROOM = "auvious.conversation.transfer.room";
export const TAG_CUSTOMER_REQUESTED_AT = "auvious.conversation.requestedAt";
export const TAG_CUSTOMER_TICKET_SENT_AT = "auvious.conversation.ticket.sentAt";
export const TAG_CUSTOMER_INVITED = "auvious.conversation.customer.invited";
export const TAG_CUSTOMER_TICKET_URL = "auvious.conversation.ticket.url";
export const TAG_CUSTOMER_MONITOR_URL = "auvious.conversation.monitor.url";
export const TAG_CUSTOMER_ENDED_AT = "auvious.conversation.endedAt";
export const TAG_CUSTOMER_RECORDING_URL =
  "auvious.conversation.recordingLocation";
export const TAG_CUSTOMER_RECORDING_INSTANCE_ID =
  "auvious.conversation.recordingInstanceId";
export const TAG_CUSTOMER_RECORDING_RECORDER_ID =
  "auvious.conversation.recordingRecorderId";
export const TAG_CUSTOMER_RECORDING_STORAGE_PROVIDER =
  "auvious.conversation.recordingStorageProvider";
export const TAG_CUSTOMER_CALLBACK_ORIGIN =
  "auvious.conversation.callback.origin";
export const TAG_CUSTOMER_CALLBACK_PREFERRED_AGENT_ID =
  "auvious.conversation.callback.preferredAgentId";
export const KEY_PC_ENVIRONMENT = "auvious.pcEnvironment";
export const KEY_APPLICATION_ID = "auvious.pcApplication";
export const KEY_CONVERSATION_ID = "auvious.pcConversation";
export const KEY_ROOM_NAME = "auvious.roomName";
export const KEY_TICKET_ID = "auvious.ticketId";
export const KEY_LANG = "auvious.lang";
export const KEY_LANG_COUNTRY = "auvious.lang-country";
export const KEY_TOKEN = "auvious_auth_data";
export const KEY_TERMS = "auvious.terms";
export const KEY_TERMS_ACCEPTED_AT = "auvious.terms.acceptedAt";
export const KEY_APPOINTMENT_AGENT_USER_ID = "auvious.agentUserId";
export const KEY_APPOINTMENT_ID = "auvious.appointment.id";
export const KEY_APPOINTMENT_CONVERSATION = "auvious.appointment.conversation";
export const KEY_USER_DISPLAY_NAME = "auvious.user.displayName";
export const INSTALLED_TOKEN = "/welcome?";
export const KEY_JWT_TOKEN = "auvious.token";
export const KEY_COBROWSE_INTERACTION = "auvious.cobrowse.interaction";
export const WIDGET_REFERRER_ID = "auvious.origin.widget.videoCall";
export const WIDGET_CONVERSATION_TYPE =
  "auvious.origin.widget.conversation.type";
export const WIDGET_MODE = "auvious.origin.widget.mode";
export const WIDGET_CONTACT = "auvious.origin.widget.contact";
export const PARAM_APPLICATION_ID = "aid";
export const PARAM_TALKDESK_ID = "talkdesk_installation_id";
export const PARAM_CONVERSATION_ID = "cid";
export const PARAM_CONVERSATION_DESTINATION = "cdestination";
export const PARAM_CONVERSATION_ORIGIN = "corigin";
export const PARAM_CONVERSATION_CHANNEL = "channel";
export const PARAM_GENESYS_ENV = "pcEnvironment";
export const PARAM_CXEXCHANGE_ISS = "iss";
export const PARAM_KIOSK_MODE = "kiosk";
export const PARAM_WHISPER_MODE = "whisper";
export const PARAM_CALL_TYPE = "call-type";
export const PARAM_WIDGET_ORIGIN_URL = "widget-origin-url";
export const PARAM_ROOM_ID = "roomId";
export const PARAM_COBROWSE_AVAILABLE = "cobrowse-on";
export const PARAM_DISPLAY_NAME = "name";
export const PARAM_APPOINTMENT_ID = "sid";
export const PARAM_CUSTOMER_ID = "customer_id";
export const PARAM_MESSAGING = "messaging";
export const PARAM_GUEST = "guest";
export const PERMISSION_EDIT_INTEGRATION = "integrations:integration:edit";
export const KEY_EMAIL = "auvious.email";
export const AUVIOUS_PURECLOUD_VERSION = "AUVIOUS-PURECLOUD-VERSION";
export const CALLBACK_NAME_PREFIX = "Video Call";
export const DEFAULT_EMAIL = "email_placeholder@mail.com";
export const CHAT_KEY_EMAIL = "context.email";
export const PHONE_REGEX = /^\+?[1-9]\d{1,14}$/; // valid E.164
export const SECURE_URL_REGEX = /^(https):\/\/[^\s\/$.?#].[^\s]*$/i;
export const INTERACTION_WIDGET_TYPE = "embedded-client-app-interaction-widget";
export const WIDGET_COBROWSE_REQUEST_PREFIX = "auvious:cobrowse-request";
export const WIDGET_CUSTOMER_CALL_ACCEPTED_ACK = "auvious:call:accepted";

export const CUSTOMER_PHONE_NUMBER_ATTRIBUTE = "context.phoneNumber";
export const DEFAULT_CUSTOMER_ID = "Somebody";
export const AUVIOUS_VALID_URL_REGEX =
  /^https:\/\/(.*\.auvious.com|localhost|(.*auvious.video))(:\d+)?\/t\/.*$/i;

export const CUSTOMER_CALL_ACCEPTED_ACK = "auvious:call:accepted";
export const QUEUE_TIMEOUT_MESSAGE = "auvious:queue:timeout";

export const TALKDESK_API_URL = "https://api.talkdeskapp.com";
export const CLAIM_TALKDESK_REFRESH_TOKEN = "talkdesk_refresh_token";
export const CLAIM_TALKDESK_ACCESS_TOKEN = "talkdesk_access_token";

export const GENESYS_WORKFLOW_ADDRESS = "Flow.auviousToAddress";
export const GENESYS_WORKFLOW_MESSAGE = "Flow.auviousMessage";
export const GENESYS_WORKFLOW_CONVERSATION_ID = "Flow.conversationId";
export const GENESYS_WORKFLOW_AGENT_ID = "Flow.agentId";
export const GENESYS_WORKFLOW_AGENT_PARTICIPANT_ID = "Flow.agentParticipantId";

export enum GenesysCloudMemberState {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  ALERTING = "alerting",
  DIALING = "dialing",
  TERMINATED = "terminated",
}

export enum GenesysCloudMemberRole {
  AGENT = "agent",
  CUSTOMER = "customer",
  USER = "user",
  QUEUE = "acd",
}

export enum GenesysCloudMemberDirection {
  INBOUND = "inbound",
  OUTBOUND = "outbound",
}

export enum ErrorPageCode {
  ROOM_NOT_FOUND = "not-found",
  PAGE_NOT_FOUND = "404",
  NOT_SUPPORTED = "not-supported",
  SESSION_EXPIRED = "session-expired",
  SCHEDULED_LATER = "scheduled-later",
  ACCESS_DENIED = "access-denied",
  RTC_CONNECTION_FAILURE = "rtc-connection-failure",
  AUTHENTICATION_FAILURE = "authentication-failure",
  APPLICATION_NOT_FOUND = "application-not-found",
  PURECLOUD_INIT_ERROR = "purecloud-init-error",
  GENERIC_ERROR = "generic-error",
  SCHEDULE_NOT_FOUND = "schedule-not-found",
  COOKIES_DISABLED = "cookies-disabled",
  PARTICIPANT_LIMIT_REACHED = "participant-limit-reached",
  APPOINTMENT_COMPLETED = "appointment-completed",
  APPOINTMENT_NOT_FOUND = "appointment-not-found",
  TICKET_NOT_FOUND = "ticket-not-found",
  LICENSE_REQUIRED = "license-required",
  CONVERSATION_FAILED = "conversation-failed",
}

export enum ErrorPageSubCode {
  UNSUPPORTED_BROWSER = "unsupported-browser",
  APPOINTMENT_TOKEN_EXPIRED = "appointment-token-expired",
  STATE_MISMATCH = "state-mismatch", // as thrown by OAuth2CodeGrantAuthenticationStrategy
}

export const AMAZON_WS_REGIONS = [
  { label: "US East (Ohio)", value: "us-east-2" },
  { label: "US East (N. Virginia)", value: "us-east-1" },
  { label: "US West (N. California)", value: "us-west-1" },
  { label: "US West (Oregon)", value: "us-west-2" },
  { label: "Africa (Cape Town)", value: "af-south-1" },
  { label: "Asia Pacific (Hong Kong)", value: "ap-east-1" },
  { label: "Asia Pacific (Hyderabad)", value: "ap-south-2" },
  { label: "Asia Pacific (Jakarta)", value: "ap-southeast-3" },
  { label: "Asia Pacific (Melbourne)", value: "ap-southeast-4" },
  { label: "Asia Pacific (Mumbai)", value: "ap-south-1" },
  { label: "Asia Pacific (Osaka-Local)", value: "ap-northeast-3" },
  { label: "Asia Pacific (Seoul)", value: "ap-northeast-2" },
  { label: "Asia Pacific (Singapore)", value: "ap-southeast-1" },
  { label: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
  { label: "Asia Pacific (Tokyo)", value: "ap-northeast-1" },
  { label: "Canada (Central)", value: "ca-central-1" },
  { label: "Canada West (Calgary)", value: "ca-west-1" },
  { label: "China (Beijing)", value: "cn-north-1" },
  { label: "China (Ningxia)", value: "cn-northwest-1" },
  { label: "Europe (Frankfurt)", value: "eu-central-1" },
  { label: "Europe (Ireland)", value: "eu-west-1" },
  { label: "Europe (London)", value: "eu-west-2" },
  { label: "Europe (Milan)", value: "eu-south-1" },
  { label: "Europe (Paris)", value: "eu-west-3" },
  { label: "Europe (Spain)", value: "eu-south-2" },
  { label: "Europe (Stockholm)", value: "eu-north-1" },
  { label: "Europe (Zurich)", value: "eu-central-2" },
  { label: "Israel (Tel Aviv)", value: "il-central-1" },
  { label: "Middle East (Bahrain)", value: "me-south-1" },
  { label: "Middle East (UAE)", value: "me-central-1" },
  { label: "South America (São Paulo)", value: "sa-east-1" },
  { label: "AWS GovCloud (US-East)", value: "us-gov-east-1" },
  { label: "AWS GovCloud (US-West)", value: "us-gov-west-1" },
];

export type callbackNotificationTagType = "option" | "date";

export interface ICallbackFormat extends IAppointmentFormat {
  type: callbackNotificationTagType;
}

export const CALLBACK_NOTIFICATION_TAGS: ICallbackFormat[] = [
  { format: "name", example: "Name", type: "option" },
  {
    format: "dddd, MMMM Do YYYY",
    example: "Monday, January 1st 2006",
    type: "date",
  },
  { format: "dddd DD-MMM-YY", example: "Monday 02-Jan-06", type: "date" },
  { format: "ddd DD-MMM-YY", example: "Mon 02-Jan-06", type: "date" },
  { format: "dddd MMM DD YYYY", example: "Monday Jan 02 2006", type: "date" },
  { format: "ddd MMM DD YYYY", example: "Mon Jan 02 2006", type: "date" },
  { format: "dddd DD MMM YYYY", example: "Monday 02 Jan 2006", type: "date" },
  { format: "ddd DD MMM YYYY", example: "Mon 02 Jan 2006", type: "date" },
  { format: "DD MMM YY", example: "02 Jan 06", type: "date" },
  { format: "MMM DD YY", example: "Jan 02 06", type: "date" },
  { format: "MM-DD-YYYY", example: "01-31-2006", type: "date" },
  { format: "DD-MM-YYYY", example: "31-01-2006", type: "date" },
  { format: "YYYY-MM-DD", example: "2006-01-31", type: "date" },
  { format: "MM/DD/YYYY", example: "01/31/2006", type: "date" },
  { format: "DD/MM/YYYY", example: "31/01/2006", type: "date" },
  { format: "YYYY/MM/DD", example: "2006/01/31", type: "date" },
  { format: "HH:mm", example: "15:04", type: "date" },
  { format: "h:mm a", example: "3:04 pm", type: "date" },
  { format: "Z", example: "-07:00", type: "date" },
  { format: "roomLink", example: "https://video/link", type: "option" },
  {
    format: "googleCalendarLink",
    example: "https://example.org/cal/events/google/event",
    type: "option",
  },
  {
    format: "iCalendarLink",
    example: "https://example.org/cal/events/ical/event",
    type: "option",
  },
  {
    format: "outlookCalendarLink",
    example: "https://example.org/cal/events/outlook/event",
    type: "option",
  },
];

export enum recorderConfigState {
  input,
  verify,
  testSuccess,
  testFailure,
  saveSuccess,
  saveFailure,
}

export enum asrConfigState {
  input,
  saveSuccess,
  saveFailure,
}

export enum settingsMenuItemEnum {
  storage = "storage",
  recorder = "recorder",
  configuration = "configuration",
  integration = "integration",
  interactionWidget = "interactionWidget",
  schedule = "schedule",
  members = "members",
  snapshots = "snapshots",
  credentials = "credentials",
  disclaimer = "disclaimer",
  speechToText = "speechToText",
  transcriptAI = "transcriptAI",
  security = "security",
  videoQuality = "videoQuality",
  fileTransfer = "fileTransfer",
  backgroundEffects = "backgroundEffects",
  translations = "translations",
  appearance = "appearance",
  digitalConnect = "digitalConnect",
  webhooks = "webhooks",
  genesys = "genesys",
  visionAI = "visionAI"
}

export type auviousLicense = "freetrial" | "cobrowse" | "video" | "screenshare";
