<ng-container *ngIf="isEmailAvailable()">
  <av-menu-item
    [attr.aria-label]="'Send link via email' | translate"
    icon="envelope"
    [arrow]="emailQueues().length > 1"
    [expanded]="isSelectingEmailQueue()"
    (select)="shareLink()"
    (keyArrowRight)="expand()"
    (keyArrowLeft)="collapse()"
    data-tid="conference/send-email"
  >
    <span translate>Send via Email</span>
    <!-- <av-card [menu]="true" [popup]="true" [backdrop]="false">
            <av-card-body [noHeader]="true">
                <av-menu>
                    <av-menu-item type="header" translate>Please select a queue</av-menu-item>
                    <av-menu-item (select)="emailQueueSelected(q)" *ngFor="let q of emailQueues">
                    <span> {{q.name}}</span>
                    </av-menu-item>
                </av-menu>
            </av-card-body>
        </av-card> -->
  </av-menu-item>

  <div class="sub-item" type="sub-item" *ngIf="isSelectingEmailQueue()">
    <av-menu sub-menu>
      <av-menu-item type="header"
        ><span translate>Please select a queue</span></av-menu-item
      >
      <av-menu-item
        [attr.aria-label]="('email queue ' | translate) + q.name"
        (select)="emailQueueSelected(q)"
        *ngFor="let q of emailQueues()"
        data-tid="conference/select-queue"
      >
        {{ q.name }}
      </av-menu-item>
    </av-menu>
  </div>
</ng-container>
