import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChild,
  ViewChildren,
  computed,
  signal,
} from "@angular/core";
import { IEndpoint } from "@auvious/rtc";
import { filter, merge, Subscription } from "rxjs";
import { WindowService } from "../../services/window.service";

import {
  AgentParam,
  CoBrowseMetadata,
  CustomerParam,
  IEndpointMetadata,
  IInteraction,
} from "../../models";
import { FileTransferService } from "../../services/file-transfer.service";
import { slideFromUp } from "../../core-ui.animations";
import {
  ConferenceMetadataKeyEnum,
  ConversationTypeEnum,
  EndpointTypeEnum,
  SNAPSHOT_VISION_TYPE,
  UserCapabilityEnum,
  UserRoleEnum,
} from "../../core-ui.enums";
import {
  ActivityIndicatorService,
  AppConfigService,
  AuviousRtcService,
  ConferenceService,
  ConferenceStore,
  debugError,
  DeviceService,
  FEATURES,
  MediaRulesService,
  PointerService,
  SnapshotService,
  StreamState,
  UserService,
} from "../../services";
import { CobrowseService } from "../../services/cobrowse.service";
import { NotificationService } from "../../services/notification.service";
import { RoomComponent } from "../room/room.component";
import { TileComponent } from "../tile/tile.component";
import { IDevice } from "@auvious/media-tools";
import { VisionService } from "../../../core-ui/services/vision.service";
import { MenuItemComponent } from "../../../modules/shared/components";

@Component({
  selector: "app-tile-controls",
  templateUrl: "./tile-controls.component.html",
  styleUrls: ["./tile-controls.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideFromUp],
})
export class TileControlsComponent implements OnInit, OnDestroy {
  @Input() stream: StreamState;
  @Input() interaction: IInteraction;

  private subscriptions: Subscription;
  private isAgent: boolean;
  private isCustomer: boolean;

  isFullScreen = signal(false);
  isMenuOpen = signal(false);
  isPopedOut = signal(false);
  isTileTooSmall = false;
  isDesktopCaptureAvailable = false;
  isDesktopCaptureLoading = signal(false);
  isDesktopCaptureActive = signal(false);
  isSecondaryDeviceAvailable = signal(false);
  isSecondaryStreamStopAvailable = signal(false);
  isVisionAvailable = false;
  isWhisper = false;
  cameraDevices = signal<IDevice[]>([]);
  usedCameraDevices = signal<string[]>([]);
  cameraDeviceLoading = signal<string>(undefined);

  private screenStream = this.store.screenStream;
  private cobrowseOriginator: IEndpoint;
  private cobrowseTarget: IEndpoint;

  @ViewChild("menu", { read: ElementRef }) menu: ElementRef<HTMLElement>;
  @ViewChild("menuButton", { read: ElementRef })
  menuButton: ElementRef<HTMLElement>;
  @ViewChildren(MenuItemComponent) menuItems: QueryList<MenuItemComponent>;

  constructor(
    private pointerService: PointerService,
    private conferenceService: ConferenceService,
    private cdr: ChangeDetectorRef,
    private config: AppConfigService,
    private userService: UserService,
    private rtcService: AuviousRtcService,
    private cobrowseService: CobrowseService,
    private activityService: ActivityIndicatorService,
    private deviceService: DeviceService,
    private snapshotService: SnapshotService,
    private transferService: FileTransferService,
    private mediaRules: MediaRulesService,
    private store: ConferenceStore,
    private winService: WindowService,
    private notification: NotificationService,
    private visionService: VisionService,
    @Optional() private tile: TileComponent,
    @Optional() private room: RoomComponent
  ) {
    this.subscriptions = new Subscription();
    this.isAgent = this.userService.getActiveUser().hasRole(UserRoleEnum.agent);
    this.isCustomer = this.userService
      .getActiveUser()
      .hasRole(UserRoleEnum.customer);
  }

  ngOnInit(): void {
    this.isWhisper = !!this.interaction?.getWhisperMode();

    const participant =
      this.conferenceService.getParticipants()[this.stream.originator.endpoint];

    let isDisplayCaptureAvailable = false;
    if (participant && participant.metadata) {
      isDisplayCaptureAvailable = participant.metadata.capabilities?.includes(
        UserCapabilityEnum.displayCapture
      );
    }

    this.isSecondaryStreamStopAvailable.set(
      !this.stream.primary && this.isAgent && !this.isWhisper
    );

    this.isDesktopCaptureAvailable =
      this.isAgent &&
      this.interaction?.getType() !== ConversationTypeEnum.voiceCall &&
      !this.isLocal &&
      !this.tile?.isScreen &&
      isDisplayCaptureAvailable &&
      this.config.customerParamEnabled(CustomerParam.SCREEN_SHARE_ENABLED) &&
      this.config.agentParamEnabled(
        AgentParam.CUSTOMER_SCREEN_SHARE_REQUEST_ENABLED
      );

    this.isVisionAvailable =
      this.isAgent &&
      this.config.agentParamEnabled(AgentParam.AI_VISION_ENABLED) &&
      this.visionService.supported(participant);

    this.subscriptions.add(
      this.store.updated$.subscribe(() => {
        if (this.screenStream !== this.store.screenStream) {
          this.screenStream = this.store.screenStream;
          if (
            this.screenStream?.originator.endpoint ===
              this.stream?.originator.endpoint ||
            !this.screenStream
          ) {
            this.isDesktopCaptureActive.set(!!this.screenStream);
            this.isDesktopCaptureLoading.set(false);
          }
        }
        if (this.isAgent && !this.isLocal && this.stream.primary) {
          // the participant may switch cameras at any time so we need to update the devices constantly
          this.cameraDevices.set(
            participant.metadata?.mediaDevices?.filter(
              (d) =>
                d.kind === "videoinput" &&
                d.deviceId !== this.stream.video.deviceId
            ) || []
          );
          // list the camera devices of this endpoint that are already used in streams
          this.usedCameraDevices.set(
            this.store.streams
              .filter(
                (s) =>
                  s.originator.endpoint === this.stream.originator.endpoint &&
                  s.video?.deviceId
              )
              .map((s) => s.video.deviceId)
          );
          // in case we have requested a secondary camera and we got the stream, stop loading
          if (
            !!this.cameraDeviceLoading() &&
            this.usedCameraDevices().includes(this.cameraDeviceLoading())
          ) {
            this.cameraDeviceLoading.set(undefined);
            this.isMenuOpen.set(false);
          }

          this.isSecondaryDeviceAvailable.set(
            this.config.agentParam(
              AgentParam.CUSTOMER_CAMERAS_CONTROL_ENABLED
            ) &&
              this.cameraDevices()?.length > 0 &&
              this.interaction.getType() !== ConversationTypeEnum.voiceCall
          );
        }
      })
    );

    this.subscriptions.add(
      merge(
        this.cobrowseService.started$,
        this.cobrowseService.participantInvited$,
        this.snapshotService.started$,
        this.snapshotService.ended$,
        this.pointerService.pointAreaChange$
      ).subscribe((_) => this.cdr.detectChanges())
    );

    if (this.tile) {
      this.subscriptions.add(
        this.tile.viewSizeChanged.subscribe((size) => {
          this.isTileTooSmall = size.width < 180 || size.height < 180;
          this.cdr.detectChanges();
        })
      );
    }
    if (this.isAgent) {
      // TODO: we need a store to ask for this stuff, not check the conference metadata AND subscribe to observables for the same thing

      // try to detect as a guest agent if the co-browse has started by another agent
      const cobrowseMetadata = this.conferenceService.getConferenceMetadata(
        ConferenceMetadataKeyEnum.coBrowse
      ) as CoBrowseMetadata;
      if (cobrowseMetadata) {
        this.cobrowseOriginator = cobrowseMetadata.originator;
        this.cobrowseTarget = this.cobrowseService.target;
      }

      this.subscriptions.add(
        this.conferenceService.endpointJoined$
          .pipe(filter((e) => e.metadata?.type === EndpointTypeEnum.coBrowse))
          .subscribe((e) => {
            this.cobrowseOriginator = (
              e.metadata as IEndpointMetadata
            )?.cobrowseOriginator;
            this.cobrowseTarget = e;
            this.cdr.detectChanges();
          })
      );
      this.subscriptions.add(
        merge(
          this.conferenceService.endpointLeft$.pipe(
            filter((e) => e.endpoint === this.cobrowseTarget?.endpoint)
          ),
          this.conferenceService.conferenceMetadataRemoved$.pipe(
            filter((m) => m instanceof CoBrowseMetadata)
          ),
          this.cobrowseService.ended$
        ).subscribe((_) => {
          this.cobrowseOriginator = null;
          this.cobrowseTarget = null;
          this.cdr.detectChanges();
        })
      );
      this.subscriptions.add(
        this.mediaRules.desktopCaptureRejected$.subscribe((e) =>
          this.isDesktopCaptureLoading.set(false)
        )
      );
      this.subscriptions.add(
        this.mediaRules.secondaryStreamRejected$
          .pipe(
            filter((p) => p.sender.endpoint === this.stream.originator.endpoint)
          )
          .subscribe((p) => {
            this.cameraDeviceLoading.set(undefined);
            this.notification.info("Permission to camera was not granted", {
              body: "The customer either denied the browser permission to access their device or previously blocked the device. Instruct the customer on how to re-enable access to camera devices.",
              ttl: -1,
            });
          })
      );
    }
  }

  ngOnDestroy() {
    if (this.isPictureInPictureOn()) {
      document.exitPictureInPicture();
    }

    this.subscriptions.unsubscribe();
  }

  @HostListener("document:keydown.escape", ["$event"]) keydown(
    e: KeyboardEvent
  ) {
    if (this.isMenuOpen()) {
      this.isMenuOpen.set(false);
    }
  }

  @HostListener("document:fullscreenchange", ["$event"])
  @HostListener("document:webkitfullscreenchange", ["$event"])
  fullscreenchange($event) {
    if (
      !(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement
      )
    ) {
      // closed fullscreen
      this.isFullScreen.set(false);
      this.isMenuOpen.set(false);
    }
  }

  /* actions */
  async toggleMenu() {
    this.isMenuOpen.set(!this.isMenuOpen());
    this.cdr.detectChanges();

    if (this.isMenuOpen()) {
      this.winService
        .onClickedOutside(
          this.menu.nativeElement,
          this.menuButton.nativeElement
        )
        .then(() => {
          if (this.isMenuOpen()) this.toggleMenu();
        });
    }
  }

  jumpInMenu(event) {
    if (this.isMenuOpen() && event.key === "ArrowDown") {
      this.menuItems
        .toArray()
        .filter((m) => !m.disabled && m.type !== "separator")?.[0]
        ?.focus();
    }
  }

  async fileAdded(event) {
    if (event.target.files.length > 0) {
      let id;
      const file: File = event.target.files[0];
      this.isMenuOpen.set(false);

      this.transferService.sendFileToParticipant(
        file,
        this.stream.originator,
        this.interaction
      );
    }
  }

  togglePointer() {
    this.mediaRules.dismissPendingConfirmations();
    this.pointerService.togglePointArea(
      this.stream.originator.endpoint,
      this.stream.type,
      this.myself.endpoint
    );
    this.isMenuOpen.set(false);
  }

  visionRequest() {
    this.snapshotService.requestSnapshot(
      this.stream.originator,
      this.interaction,
      SNAPSHOT_VISION_TYPE
    );
    this.toggleMenu();
  }

  cobrowseRequest() {
    this.mediaRules.dismissPendingConfirmations();

    // cobrowseSessionComponent will request a new session once it opens. we just need to open it
    this.cobrowseService.inviteParticipant(
      this.stream.originator,
      this.interaction,
      "view"
    );

    // store it because after the sender accepts, we will get a new endpoint
    this.cobrowseService.addOldRemoteParticipant(this.stream.originator);

    // store the original target, others need to know
    this.conferenceService.setConferenceMetadata(
      new CoBrowseMetadata(this.myself, this.stream.originator)
    );
    this.cobrowseService.setStreamTarget(this.stream.originator);

    this.isMenuOpen.set(false);
  }

  snapshotRequest() {
    this.mediaRules.dismissPendingConfirmations();
    this.snapshotService.request(this.stream.id);
    this.isMenuOpen.set(false);
  }

  snapshotTerminate() {
    this.snapshotService.stop(this.stream.originator);
    this.isMenuOpen.set(false);
  }

  cobrowseTerminate() {
    this.cobrowseService.terminate();
    this.activityService.loading(true, "Ending co-browse...");
    this.isMenuOpen.set(false);
  }

  async toggleFullScreen() {
    const result = await this.rtcService.toggleFullScreen(this.tile.container);
    this.isFullScreen.set(result);
    this.isMenuOpen.set(false);
  }

  get isSecondaryDeviceDisabled() {
    // device: IDevice
    return computed(
      () =>
        this.cobrowseService.isPendingApproval ||
        this.cobrowseService.isStarted ||
        this.snapshotService.isStarted ||
        this.room?.isScreenShareActive ||
        this.pointerService.isStarted ||
        this.usedCameraDevices().length > 1
    );
    // if we want to support secondary camera switch on the fly (without stop and start)
    // disable only the active camera
    // return computed(() => this.usedCameraDevices().includes(device.deviceId));
  }

  async pip() {
    try {
      if (this.isPictureInPictureOn()) {
        document.exitPictureInPicture();
      } else {
        const videoElm = this.conferenceService.getElementById(
          this.stream.id
        ) as HTMLVideoElement;
        await videoElm.requestPictureInPicture();
        this.isPopedOut.set(true);
        this.mediaRules.setPictureInPicture(this.stream.id, true);
        videoElm.onleavepictureinpicture = (e) => {
          this.cdr.detectChanges();
          this.isPopedOut.set(false);
          this.mediaRules.setPictureInPicture(this.stream.id, false);
          setTimeout(() => {
            videoElm.play();
          }, 200);
        };
      }
    } catch (ex) {
      debugError(ex);
    }
    this.isMenuOpen.set(false);
  }

  bitrateChanged() {
    this.isMenuOpen.set(false);
  }

  desktopCaptureRequest() {
    this.mediaRules.requestRemoteDesktopCapture(this.stream.originator);
    this.isDesktopCaptureLoading.set(true);
    setTimeout(() => {
      this.isDesktopCaptureLoading.set(false);
    }, 60000); // 60 seconds
    this.isMenuOpen.set(false);
  }

  remoteStreamStartRequest(device: IDevice) {
    this.cameraDeviceLoading.set(device.deviceId);
    // in case we don't hear anything from the ecustomer
    setTimeout((_) => {
      if (!!this.cameraDeviceLoading()) this.cameraDeviceLoading.set(undefined);
    }, 10000);
    this.mediaRules.requestRemoteStreamStart(this.stream.originator, device);
  }

  remoteStreamStopRequest() {
    this.cameraDeviceLoading.set(undefined);
    this.mediaRules.requestRemoteStreamStop(
      this.stream.originator,
      this.stream.correlationId
    );
  }

  desktopCaptureTerminate() {
    this.mediaRules.terminateRemoteDesktopCapture(this.stream.originator);
    this.isMenuOpen.set(false);
  }

  /* view getters */

  get hAlign() {
    return this.tile?.isLayoutFloating && !this.isFullScreen()
      ? "right"
      : "left";
  }

  get isHidden() {
    return (
      this.pointerService.isStarted &&
      !this.isLaserTarget &&
      this.isTileTooSmall
    );
  }

  get isAvailable() {
    return (
      (this.isCustomer &&
        this.config.customerParamEnabled(
          CustomerParam.PARTICIPANT_CONTROLS_ENABLED
        )) ||
      !this.isCustomer
    );
  }

  get isFileTransferAvailable() {
    return (
      !this.isLocal &&
      !this.tile.isScreen &&
      !this.isVoiceCall &&
      ((this.isAgent &&
        this.config.agentParamEnabled(AgentParam.FILE_TRANSFER_ENABLED)) ||
        (this.isCustomer &&
          this.config.customerParamEnabled(
            CustomerParam.FILE_TRANSFER_ENABLED
          )))
    );
  }

  get isLaserAvailable() {
    return (
      this.isLaserEnabled /*&& !this.isLocal*/ &&
      this.isLaserAvailableForScreenShare
    );
  }

  get isLaserTarget() {
    return this.pointerService.isTarget(
      this.stream.originator.endpoint,
      this.stream.type
    );
  }

  get isLaserActive() {
    return (
      this.pointerService.isTarget(
        this.stream.originator.endpoint,
        this.stream.type
      ) && this.pointerService.isRequester(this.myself.endpoint)
    );
  }

  get isLaserDisabled() {
    return computed(() => {
      return (
        this.isMutedVideo ||
        this.isDesktopCaptureLoading() ||
        this.isPictureInPictureOn() ||
        (this.room?.isScreenShareActive &&
          (!this.tile?.isScreen ||
            (this.tile?.isScreen &&
              this.streamDisplaySurface === "monitor"))) ||
        this.cobrowseService.isPendingApproval ||
        this.cobrowseService.isStarted ||
        this.snapshotService.isStarted ||
        this.isFullScreen() ||
        this.pointerService.isStarted
      );
    });
  }

  get isLaserAvailableForScreenShare() {
    return (
      !this.tile?.isScreen ||
      (this.tile?.isScreen && this.streamDisplaySurface !== "monitor")
    );
  }

  get isVideoToolDisabled() {
    return computed(() => {
      return (
        this.isMutedVideo ||
        this.isPictureInPictureOn() ||
        this.room?.isScreenShareActive ||
        this.cobrowseService.isPendingApproval ||
        this.cobrowseService.isStarted ||
        this.snapshotService.isStarted ||
        this.isFullScreen() ||
        this.pointerService.isStarted
      );
    });
  }

  get isCobrowseAvailable() {
    return (
      this.isAgent &&
      this.config.agentParamEnabled(AgentParam.COBROWSE_ENABLED) &&
      this.isCobrowseSupportedByParticipant &&
      !this.tile?.isScreen
    );
  }

  get isCobrowseDisabled() {
    return computed(() => {
      return (
        this.isDesktopCaptureLoading() ||
        this.room?.isScreenShareActive ||
        this.cobrowseService.isStarted ||
        this.snapshotService.isStarted ||
        this.pointerService.isStarted ||
        // I did not start the cobrowse session
        (this.cobrowseOriginator &&
          this.cobrowseOriginator.username !== this.myself.username)
      );
    });
  }

  get isCobrowseStopDisabled() {
    return false; // this.cobrowseService.isPendingApproval;
  }

  get isLocal() {
    return this.stream?.originator.endpoint === this.myself.endpoint;
  }

  get isMutedVideo() {
    return this.stream.video.state !== "enabled";
  }

  get isLaserEnabled(): boolean {
    const participants = this.conferenceService.getParticipants();
    const participant = participants?.[this.stream.originator.endpoint];
    return (
      this.config.featureEnabled(FEATURES.LASER_TOOL) &&
      !this.isVoiceCall &&
      ((this.isAgent &&
        (this.pointerService.supported(participant) || this.isLocal)) ||
        (!this.isAgent &&
          !this.config.featureEnabled(FEATURES.LASER_TOOL_AGENT_ONLY)))
    );
  }

  get isCobrowseSupportedByParticipant() {
    const participants = this.conferenceService.getParticipants();
    const participant = participants?.[this.stream.originator.endpoint];
    return this.cobrowseService.supported(participant);
  }

  get isCobrowseActive() {
    return (
      this.isAgent &&
      this.cobrowseOriginator?.endpoint === this.myself.endpoint &&
      this.cobrowseService.getStreamTarget()?.username ===
        this.stream.originator.username
    );
    // return this.cobrowseService.isTarget(this.stream.originator.endpoint);
  }

  get isFullscreenAvailable() {
    return (
      !DeviceService.isMobile &&
      (!this.isAgent || // if agent, check if inside iframe
        !DeviceService.isIFrame || // if inside iframe, check if feature enabled
        this.config.featureEnabled(FEATURES.PC_IFRAME_ALLOW_FULLSCREEN))
    );
  }

  get isFullscreenDisabled() {
    return computed(() => {
      return (
        this.isPictureInPictureOn() ||
        this.isMutedVideo ||
        this.isLaserActive ||
        this.isSnapshotActive
      );
    });
  }

  get isMenuTriggerVisible() {
    // return  (!this.isLocal || (this.isLocal && this.isAgent)) && (
    return (
      this.isControlActionsVisible ||
      this.isControlEffectsVisible ||
      this.isBitratePickerAvailable
    );
    // );
  }

  get isControlActionsVisible() {
    return (
      this.isFileTransferAvailable ||
      this.isLaserAvailable ||
      this.isCobrowseAvailable ||
      this.isSnapshotAvailable ||
      this.isVisionAvailable
    );
  }

  get isControlEffectsVisible() {
    return this.isPictureInPictureAvailable || this.isFullscreenAvailable;
  }

  get isControlActionEffectsSeparatorVisible() {
    return this.isControlEffectsVisible && this.isControlActionsVisible;
  }

  get isBitrateSeparatorVisible() {
    return this.isControlEffectsVisible || this.isControlActionsVisible;
  }

  get isBitratePickerAvailable() {
    return this.isLocal;
  }

  get isPictureInPictureAvailable() {
    return (
      this.deviceService.isPictureInPictureEnabled && !DeviceService.isMobile
    );
  }

  get isPictureInPictureOn() {
    return computed(() => {
      // eslint-disable-next-line compat/compat
      return !!document.pictureInPictureElement && this.isPopedOut();
    });
  }

  get isPictureInPictureDisabled() {
    return computed(() => {
      return (
        // eslint-disable-next-line compat/compat
        (!!document.pictureInPictureElement && !this.isPopedOut()) ||
        this.isFullScreen() ||
        this.isMutedVideo ||
        this.isLaserActive ||
        this.isSnapshotActive
      );
    });
  }

  get isSnapshotAvailable() {
    return (
      this.isAgent &&
      !this.tile?.isScreen &&
      this.config.agentParamEnabled(AgentParam.SNAPSHOT_ENABLED) &&
      !this.isVoiceCall &&
      this.snapshotService.supported(this.stream.originator)
    );
  }

  get isSnapshotActive() {
    return (
      this.snapshotService.isTarget(this.stream.originator.endpoint) &&
      this.snapshotService.isRequester(this.myself.username)
    );
  }

  get isSnapshotDisabled() {
    return computed(() => {
      return this.isVideoToolDisabled() || this.isDesktopCaptureLoading();
    });
  }

  get isDesktopCaptureDisabled() {
    return computed(() => {
      return (
        this.isDesktopCaptureLoading() ||
        this.room?.isScreenShareActive ||
        this.cobrowseService.isPendingApproval ||
        this.cobrowseService.isStarted ||
        this.snapshotService.isStarted ||
        this.isFullScreen() ||
        this.pointerService.isStarted ||
        this.isLaserActive ||
        this.isSnapshotActive
      );
    });
  }

  get isVoiceCall() {
    return this.room?.interactionType === ConversationTypeEnum.voiceCall;
  }

  get myself(): IEndpoint {
    return this.rtcService.myself;
  }

  get streamDisplaySurface() {
    return this.stream.video.display;
  }
}
