<av-card
  class="device-setup"
  [attr.aria-label]="'Device setup panel' | translate"
  [backdrop]="true"
>
  <av-card-header *ngIf="isMediaAvailable">
    <span translate class="title"> Ready to join? </span>
  </av-card-header>
  <av-card-body>
    <div *ngIf="isMediaAvailable" class="tile-placeholder"></div>

    <div
      class="controls-container"
      [class.controls-container-2]="!isFilterAvailable"
    >
      <div
        *ngIf="!devicesOpen"
        class="fx-row fx-align-start fx-justify-space-between"
      >
        <div
          *ngIf="!isMediaAvailable"
          class="full-width fx-row fx-align-center fx-justify-center"
        >
          <h3 translate>No devices available</h3>
        </div>
        <ng-container *ngIf="isMediaAvailable">
          <div
            *ngIf="isFilterAvailable"
            class="control-container popup-container fx-column fx-align-center fx-flex-2"
          >
            <button
              avButton
              [attr.aria-label]="
                (isEffectsOpen ? 'Hide' : 'Show') + ' background effects'
                  | translate
              "
              #effectsMenuButton
              [disabled]="!camOn"
              ofType="icon"
              color="basic"
              [raised]="true"
              size="sm"
              (keydown)="effectsKeyDown($event)"
              id="effects-close"
              (click)="toggleEffects()"
              [attr.data-tid]="
                isEffectsOpen
                  ? 'device-setup/effects-close'
                  : 'device-setup/effects-open'
              "
            >
              <av-icon
                *ngIf="!isEffectsOpen"
                name="background-effect"
              ></av-icon>
              <av-icon *ngIf="isEffectsOpen" name="close-big"></av-icon>
            </button>
            <label aria-hidden="true" class="control-label" translate
              >Background effects</label
            >

            <av-card
              aria-labelledby="bkg-effects"
              #effectsMenu
              [class.hidden]="!isEffectsOpen"
              (focusout)="onEffectsFocusOut($event)"
              class="effects-card"
              [popup]="true"
              arrow="left-top"
            >
              <h1 class="hidden" id="bkg-effects" translate>
                Background effects
              </h1>
              <av-card-body type="slim">
                <app-background-effects #effectsRef (keyArrowLeft)="effectsNavigateOut()"></app-background-effects>
              </av-card-body>
            </av-card>
          </div>

          <!-- switch cam -->
          <div
            *ngIf="isMobile"
            class="control-container fx-column fx-align-center fx-flex-2"
          >
            <button
              [attr.aria-label]="'Switch camera' | translate"
              avButton
              [disabled]="isSwitching || !camOn"
              ofType="icon"
              color="basic"
              [raised]="true"
              size="sm"
              id="btn-drawer-test-sound"
              (click)="switchCam()"
              data-tid="device-setup/switch-camera"
            >
              <av-icon name="camera-reverse"></av-icon>
            </button>
            <label aria-hidden="true" class="control-label" translate
              >Switch</label
            >
          </div>

          <!-- sound test -->
          <div class="control-container fx-column fx-align-center fx-flex-2">
            <button
              [attr.aria-label]="'Test speakers' | translate"
              avButton
              ofType="icon"
              color="basic"
              [raised]="true"
              size="sm"
              id="btn-drawer-test-sound"
              (click)="testSpeaker()"
              data-tid="device-setup/test-speaker"
            >
              <av-icon name="speaker"></av-icon>
            </button>
            <label aria-hidden="true" class="control-label" translate
              >Test speakers</label
            >
          </div>

          <!-- devices -->
          <div
            *ngIf="!isMobile"
            class="control-container fx-column fx-align-center fx-flex-2"
          >
            <button
              [attr.aria-label]="'Open device settings' | translate"
              avButton
              ofType="icon"
              [color]="devicesOpen ? 'primary' : 'basic'"
              size="sm"
              [raised]="true"
              id="btn-drawer-devices"
              (click)="toggleDevicesPopup()"
              data-tid="device-setup/open-device-settings"
            >
              <av-icon [themable]="false" name="settings"></av-icon>
            </button>
            <label aria-hidden="true" class="control-label" translate
              >Devices</label
            >
          </div>
        </ng-container>
      </div>

      <div class="fx-row device-settings" *ngIf="devicesOpen">
        <!-- <div class="fx-column fx-align-center fx-justify-start">
          <button
            [attr.aria-label]="'Close device settings' | translate"
            avButton
            ofType="icon"
            [raised]="true"
            color="basic"
            size="sm"
            (click)="devicesOpen = false"
            data-tid="device-setup/close-device-settings"
          >
            &larr;
          </button>
          <span aria-hidden="true" translate>Back</span>
        </div> -->
        <app-device-settings
          (close)="devicesOpen = false"
        ></app-device-settings>
        <!-- <div class="fx-column fx-align-center fx-justify-start">
          <av-tooltip position="bottom" message="Save as default devices">
            <button
              [attr.aria-label]="
                'Save device configuration as default' | translate
              "
              avButton
              ofType="icon"
              color="basic"
              size="sm"
              [raised]="true"
              (click)="setDefaults()"
              data-tid="device-setup/set-defaults"
            >
              <av-icon name="tick"></av-icon>
            </button>
          </av-tooltip>
          <span translate>Save</span>
        </div> -->
      </div>
    </div>
  </av-card-body>
  <av-card-footer *ngIf="devicesOpen">
    <div class="fx-row fx-align-center">
      <button
        [attr.aria-label]="'Close device settings' | translate"
        data-tid="device-setup/close-device-settings"
        avButton
        (click)="devicesOpen = false"
        class="fx-flex-1"
        ofType="basic"
        shape="block"
      >
        <span aria-hidden="true" translate>Close settings</span>
      </button>
      <button
        [attr.aria-label]="'Save device configuration as default' | translate"
        avButton
        data-tid="device-setup/set-defaults"
        (click)="setDefaults()"
        class="fx-flex-1"
        color="accent"
        shape="block"
      >
        <span aria-hidden="true" translate>Save as default devices</span>
      </button>
    </div>
  </av-card-footer>
  <av-card-footer *ngIf="!devicesOpen">
    <div class="fx-column fx-align-center device-container-actions">
      <form #f="ngForm" class="fx-row fx-align-center full-width">
        <ng-container *ngIf="isRequestingName">
          <input
            [attr.aria-label]="'Participant display name' | translate"
            type="text"
            class="margin-right-10 fx-flex-3"
            avInput
            required
            #displayName="ngModel"
            name="participant-name"
            placeholder="{{ 'Enter name' | translate }}"
            [(ngModel)]="participantName"
          />
          <div
            role="tooltip"
            translate
            class="form-error"
            *ngIf="displayName.touched && displayName.hasError('required')"
          >
            Required
          </div>
        </ng-container>
        <!-- join call-->
        <ng-container *ngIf="isMediaAvailable">
          <button
            avButton
            class="fx-flex-1"
            color="accent"
            shape="block"
            id="btn-drawer-join"
            [disabled]="isJoinDisabled"
            (click)="acceptConstraints(f.form, $event)"
            data-tid="device-setup/accepts-constraints"
          >
            <span translate *ngIf="!isAgent">Join Call</span>
            <span translate *ngIf="isAgent">Start Call</span>
          </button>
        </ng-container>
      </form>

      <!-- remember -->
      <div class="footer-options">
        <div
          *ngIf="!isOriginWidget"
          class="fx-row fx-align-center fx-justify-start fx-flex-2 footer-option-1"
        >
          <label>
            <input [(ngModel)]="skipSetup" type="checkbox" />
            <span translate>Don't show this again for today</span>
          </label>
        </div>

        <!-- leave call-->
        <div *ngIf="isAgent || !isMediaAvailable" class="footer-option-2">
          <button
            [attr.aria-label]="'Leave call' | translate"
            class="fx-flex-2"
            avButton
            ofType="basic"
            id="btn-drawer-leave"
            shape="block"
            (click)="cancel()"
            data-tid="device-setup/leave"
          >
            <span translate>Leave</span>
          </button>
        </div>
      </div>
    </div>
  </av-card-footer>
</av-card>

<div class="tile-container" *ngIf="isMediaAvailable">
  <app-tile
    #tile
    *ngIf="stream"
    [stream]="stream"
    [class.ready]="isTileReady"
    (ready)="tileReady($event)"
  >
  </app-tile>

  <div *ngIf="!stream" class="no-media">
    <av-icon name="user"></av-icon>
  </div>

  <div class="tile-media-controls fx-row fx-align-center fx-justify-center">
    <div class="container-relative">
      <div
        [attr.aria-label]="
          'Microphone ' + (micOn ? 'enabled' : 'disabled') | translate
        "
        class="device"
        *ngIf="isAudioVisible"
        [class.device-disabled]="isAudioDeviceMissing || !micOn"
        [attr.data-tid]="
          !isAudioDeviceMissing && micOn
            ? 'device-setup/mic-on'
            : 'device-setup/mic-off'
        "
      >
        <av-icon
          name="mic"
          mode="light"
          *ngIf="!isAudioDeviceMissing && micOn"
        ></av-icon>
        <av-icon
          name="mic-mute"
          mode="dark"
          *ngIf="isAudioDeviceMissing || !micOn"
        ></av-icon>
        <div class="volume" #volumeRef></div>
      </div>
      <av-tooltip
        class="control-error-tooltip"
        *ngIf="isAudioDeviceMissing"
        message="No microphone devices available"
      >
        <div
          [attr.aria-label]="'No devices available' | translate"
          class="control-error"
        >
          !
        </div>
      </av-tooltip>
    </div>

    <div *ngIf="isVideoDeviceAvailable">
      <av-switch
        [attr.aria-label]="(camOn ? 'Close' : 'Open') + ' camera' | translate"
        type="icon"
        [(ngModel)]="camOn"
        [disabled]="isTogglingCam || isVideoDeviceMissing"
        (ngModelChange)="toggleCam($event)"
        [attr.data-tid]="
          camOn ? 'device-setup/cam-close' : 'device-setup/cam-open'
        "
      >
        <av-icon *ngIf="camOn" mode="light" name="cam"></av-icon>
        <av-icon *ngIf="!camOn" mode="light" name="cam-mute"></av-icon>
      </av-switch>
      <av-tooltip
        class="control-error-tooltip"
        *ngIf="isVideoDeviceMissing"
        message="No devices available"
      >
        <div
          [attr.aria-label]="'No camera devices available' | translate"
          class="control-error"
        >
          !
        </div>
      </av-tooltip>
    </div>
  </div>
</div>
