import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Signal,
  signal,
  ViewChild,
} from "@angular/core";
import { IEndpoint, StreamTypes } from "@auvious/rtc";
import { merge, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";

import { Device } from "@auvious/compatibility";
import { IDevice, MediaDevices } from "@auvious/media-tools";
import { slideInOut } from "../../core-ui.animations";
import {
  ConversationChannelEnum,
  ConversationOriginEnum,
  ConversationTypeEnum,
  LifecycleStateEnum,
  STREAM_CONTROLS_LEFT_OFFSET_THRESHOLD,
  SizeEnum,
  StreamTrackKindEnum,
  UserRoleEnum,
  WhisperModeEnum,
} from "../../core-ui.enums";
import {
  AgentParam,
  CustomerParam,
  DEFAULT_CALL_HOLD_STATE,
  EndpointStateEnum,
  IApplicationLifecycleEventHandlers,
  ICallHoldState,
  PublicParam,
} from "../../models";
import { IInteraction } from "../../models/IInteraction";
import {
  ActivityIndicatorService,
  AppConfigService,
  ApplicationService,
  AssistantService,
  AuviousRtcService,
  CobrowseTerminateRequestAction,
  ConferenceService,
  ConferenceStore,
  debugError,
  DeviceService,
  FileTransferService,
  KeyboardService,
  KeyCodes,
  LocalMediaService,
  MediaEffectsService,
  MediaRulesService,
  MuteChanged,
  PointerService,
  SnapshotService,
  StreamState,
  UserService,
  VoiceDetectionService,
  windowActionType,
  WindowEventService,
  WindowService,
} from "../../services";
import { CobrowseService } from "../../services/cobrowse.service";
import { NotificationService } from "../../services/notification.service";
import { RoomComponent } from "../room/room.component";
import {
  ButtonComponent,
  MenuItemComponent,
} from "../../../modules/shared/components";
import { ActivatedRoute } from "@angular/router";
import { AuthState } from "../../../core-ui/models/AuthState";
import { PARAM_WHISPER_MODE } from "../../../app/app.enums";
import { TranslateService } from "@ngx-translate/core";
import { BackgroundEffectsComponent } from "../background-effects/background-effects.component";

@Component({
  selector: "app-stream-controls",
  templateUrl: "./stream-controls.component.html",
  styleUrls: ["./stream-controls.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOut],
})
export class StreamControlsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() interaction: IInteraction;
  @Input() mediaControlsEnabled: boolean;

  @Output() leave: EventEmitter<void> = new EventEmitter();
  @Output() terminate: EventEmitter<void> = new EventEmitter();
  @Output() exit: EventEmitter<void> = new EventEmitter();

  @Output() mediaError: EventEmitter<Error> = new EventEmitter();

  @ViewChild("volumeElem") volumeElem: ElementRef<HTMLDivElement>;
  @ViewChild("centerControls") centerControlsRef: ElementRef<HTMLDivElement>;
  @ViewChild("leave") leaveButtonRef: ButtonComponent;
  @ViewChild("fileInput") fileInputRef: ElementRef<HTMLInputElement>;
  @ViewChild("effectsRef") effectsRef: BackgroundEffectsComponent;
  @ViewChild("effectsMenuItemRef") effectsMenuItemRef: MenuItemComponent;
  @ViewChild("muteMicRef") muteMicMenuItemRef: MenuItemComponent;
  @ViewChild("muteCamRef") muteCamMenuItemRef: MenuItemComponent;
  @ViewChild("btnCamRef") btnCamRef: ButtonComponent;
  @ViewChild("btnMicRef") btnMicRef: ButtonComponent;

  @HostBinding("class") get cssClass() {
    return {
      "is-mobile": this.isMobile,
    };
  }

  isSwitching = false;
  _lifecycleState: LifecycleStateEnum;
  isOnHoldFromBlur = signal(false);
  subscriptions: Subscription;
  kindAudio = StreamTrackKindEnum.audio;
  kindVideo = StreamTrackKindEnum.video;
  callHoldState = signal<ICallHoldState>(DEFAULT_CALL_HOLD_STATE);
  mediaFilterError = false;
  hasMultipleAgents = signal(false);
  isPopedOut = false;
  applicationId: string;
  isPublishingStream = false;
  isCustomerJoined = false;
  buttonSize = SizeEnum.small;
  isLeavePopupOpen = signal(false);
  buttonToReFocus: HTMLButtonElement;
  isFileTransferAvailable = signal(false);
  fileTransferTargets = signal<IEndpoint[]>([]);
  isCobrowseCustomerActive = signal(false);
  isGuestAgent = false;
  isWhisper = false;
  isAssistantOpen = false;
  // isControlOptionsToggleFocus = signal<StreamTrackKindEnum>(undefined);
  // isControlOptionsVisible = signal<StreamTrackKindEnum>(undefined);

  // map store
  public localStream: StreamState = this.store.mystream;
  public screenStream: StreamState = this.store.screenStream;

  isFacingModeSupported =
    !!navigator.mediaDevices.getSupportedConstraints().facingMode;

  constructor(
    private conferenceService: ConferenceService,
    private activityService: ActivityIndicatorService,
    private cdr: ChangeDetectorRef,
    private windowEventService: WindowEventService,
    private rtcService: AuviousRtcService,
    private applicationService: ApplicationService,
    private config: AppConfigService,
    private voiceDetectionService: VoiceDetectionService,
    private notification: NotificationService,
    private mediaEffects: MediaEffectsService,
    private mediaRules: MediaRulesService,
    private local: LocalMediaService,
    private pointerService: PointerService,
    private snapshotService: SnapshotService,
    private cobrowseService: CobrowseService,
    public user: UserService,
    private store: ConferenceStore,
    private zone: NgZone,
    private keyboard: KeyboardService,
    private windowService: WindowService,
    private transferService: FileTransferService,
    private conferenceStore: ConferenceStore,
    private translate: TranslateService,
    private assistant: AssistantService,
    @Optional() private room: RoomComponent
  ) {
    this.subscriptions = new Subscription();

    const application = this.applicationService.getActiveApplication();
    this.applicationId = application.getId();
    this.isGuestAgent = user.isGuestAgent;

    // lifecycle events fire on genesys cloud interaction widget
    const lifecycleHandlers: IApplicationLifecycleEventHandlers = {
      focused: () => {
        this.lifecycleStateChange(LifecycleStateEnum.focus);
      },
      blurred: () => {
        this.lifecycleStateChange(LifecycleStateEnum.blur);
      },
      stopped: () => {
        this.lifecycleStateChange(LifecycleStateEnum.stop);
      },
    };
    application.registerLifecycleEventHandlers(lifecycleHandlers);
  }

  ngOnInit(): void {
    this.isWhisper = !!this.interaction.getWhisperMode();
    this.conferenceStore.updated$.subscribe((_) => {
      this.fileTransferTargets.set(
        this.conferenceStore.streams
          .filter(
            (s) =>
              s.originator.username !== this.myself.username &&
              s.primary &&
              s.type !== "screen"
          )
          .map((s) => s.originator)
      );
    });

    this.isFileTransferAvailable.set(
      !this.isWhisper &&
        this.config.publicParam(
          PublicParam.FILE_TRANSFER_PARTICIPANT_PICKER_ENABLED
        ) &&
        this.interaction.getType() !== ConversationTypeEnum.voiceCall &&
        ((this.user.isAgent &&
          this.config.agentParamEnabled(AgentParam.FILE_TRANSFER_ENABLED)) ||
          (this.user.isCustomer &&
            this.config.customerParamEnabled(
              CustomerParam.FILE_TRANSFER_ENABLED
            )))
    );

    this.subscriptions.add(
      this.store.updated$.subscribe(() => {
        if (
          this.localStream !== this.store.mystream ||
          this.screenStream !== this.store.screenStream
        ) {
          this.localStream = this.store.mystream;
          this.screenStream = this.store.screenStream;

          this.cdr.detectChanges();
        }
      })
    );

    this.subscriptions.add(
      this.conferenceService.callHoldStateChange$.subscribe((state) => {
        this.callHoldState.set(state);
      })
    );

    this.subscriptions.add(
      merge(this.local.addedDevices$, this.local.removedDevices$).subscribe(
        () => {
          // this.mediaDevices = MediaDevices.getDeviceList();
          this.cdr.detectChanges();
        }
      )
    );

    this.subscriptions.add(
      this.conferenceService.endpointState$.subscribe((state) => {
        const participants = this.conferenceService.getParticipants();
        // more than two participants, ask to terimate or leave
        this.hasMultipleAgents.set(
          Object.keys(participants).filter(
            (id) =>
              // state.joined is not for myself. we only count extra agents here
              state[id] === EndpointStateEnum.Joined &&
              participants[id].metadata?.roles?.includes(UserRoleEnum.agent) &&
              !participants[id].metadata.whisperMode
          ).length > 0
        );
      })
    );

    this.subscriptions.add(
      merge(
        this.mediaEffects.filterLoaded$,
        this.mediaEffects.filterDisabled$,
        this.local.tracksChanged$,
        this.snapshotService.started$,
        this.snapshotService.ended$,
        this.pointerService.pointAreaChange$,
        this.cobrowseService.started$,
        this.cobrowseService.ended$
      ).subscribe(() => {
        this.cdr.detectChanges();
      })
    );
    this.subscriptions.add(
      this.mediaEffects.filterError$.pipe(take(1)).subscribe(() => {
        this.mediaFilterError = true;
        this.cdr.detectChanges();
      })
    );

    this.subscriptions.add(
      this.conferenceService.localStreamWillPublish$
        .pipe(filter((s) => !!s && s.type !== StreamTypes.SCREEN))
        .subscribe((options) => {
          this.isPublishingStream = true;
          this.cdr.detectChanges();
        })
    );

    this.subscriptions.add(
      this.conferenceService.localStreamPublished$
        .pipe(filter((s) => !!s && s.type !== StreamTypes.SCREEN))
        .subscribe((stream) => {
          this.isPublishingStream = false;
          this.cdr.detectChanges();
        })
    );
    this.subscriptions.add(
      this.mediaRules.pictureInPictureChanged$
        .pipe(filter((e) => e.streamId === this.localStream?.id))
        .subscribe((e) => {
          this.isPopedOut = e.on;
          this.cdr.detectChanges();
        })
    );
    this.subscriptions.add(
      this.assistant.openChange$.subscribe(
        (open) => (this.isAssistantOpen = open)
      )
    );
    if (this.room.isWidget) {
      this.subscriptions.add(
        this.windowEventService
          .init(this.interaction.getParentFrameUrl())
          .subscribe((action) => {
            if (!action) {
              return;
            }
            switch (action.type) {
              case windowActionType.MUTE_CHANGE_REQUESTED:
                this.toggleMute(action.payload);
                break;
              case windowActionType.COBROWSE_STARTED:
                this.isCobrowseCustomerActive.set(true);
                break;
              case windowActionType.COBROWSE_TERMINATED:
                this.isCobrowseCustomerActive.set(false);
                break;
            }
          })
      );
    }
    if (this.user.isAgent) {
      this.subscriptions.add(
        this.conferenceService.endpointJoined$
          .pipe(
            filter((e) => e.metadata?.roles?.includes(UserRoleEnum.customer))
          )
          .subscribe((e) => {
            this.isCustomerJoined = true;
            this.cdr.detectChanges();
          })
      );

      this.subscriptions.add(
        this.windowService.streamControlsContainerPositionChange$.subscribe(
          (p) => {
            this.buttonSize =
              p.left < STREAM_CONTROLS_LEFT_OFFSET_THRESHOLD
                ? SizeEnum.extraSmall
                : SizeEnum.small;
            this.cdr.detectChanges();
          }
        )
      );
    }

    if (this.isAudioDeviceAvailable) {
      this.keyboard.listen(KeyCodes.A, this.onAKey);
    }
    if (this.isVideoDeviceAvailable) {
      this.keyboard.listen(KeyCodes.V, this.onVKey);
    }
    if (this.isHoldEnabled) {
      this.keyboard.listen(KeyCodes.H, this.onHKey);
    }
  }

  ngAfterViewInit() {
    if (!this.isAudioDeviceAvailable) {
      return;
    }

    this.subscriptions.add(
      this.voiceDetectionService.volumeLevel$
        .pipe(filter((v) => v.streamId === this.localStream?.id))
        .subscribe((volume) => {
          if (this.volumeElem) {
            this.volumeElem.nativeElement.style.height = volume.level + "%"; // "px"
          }
        })
    );

    try {
      this.zone.runOutsideAngular(() => {
        window.addEventListener("resize", (e) => {
          this.notifyContainerSizeChange();
        });
        this.notifyContainerSizeChange();
      });
    } catch (ex) {
      debugError(ex);
    }
  }

  private onAKey = () => {
    if (!this.isMicDisabled()) {
      this.toggleMute(this.kindAudio);
    }
  };

  private onVKey = () => {
    if (!this.isCamDisabled()) {
      this.toggleMute(this.kindVideo);
    }
  };

  private onHKey = () => {
    if (!this.isHoldDisabled()) {
      this.toggleHold();
    }
  };

  focus(ev: KeyboardEvent, kind: StreamTrackKindEnum) {
    if (
      [KeyCodes.Space, KeyCodes.Enter, KeyCodes.NumpadEnter].includes(
        ev.code as KeyCodes
      )
    ) {
      this.buttonToReFocus = ev.target as HTMLButtonElement;
    } else {
      if (ev.key === "ArrowUp") {
        switch (kind) {
          case StreamTrackKindEnum.audio:
            this.muteMicMenuItemRef?.focus();
            break;
          case StreamTrackKindEnum.video:
            this.muteCamMenuItemRef?.focus();
            break;
        }
      }
    }
    // if ((ev.code as KeyCodes) === KeyCodes.Tab) {
    //   this.isControlOptionsToggleFocus.set(kind);
    // }
  }

  // controlOptionsToggle(kind: StreamTrackKindEnum) {
  //   this.isControlOptionsVisible.set(
  //     !!this.isControlOptionsVisible() ? undefined : kind
  //   );
  // }

  muteCamArrowDown() {
    this.btnCamRef.host.nativeElement.focus();
  }

  muteMicArrowDown() {
    this.btnMicRef.host.nativeElement.focus();
  }

  private notifyContainerSizeChange() {
    const entry = this.centerControlsRef?.nativeElement.getBoundingClientRect();
    this.windowService.notifyStreamControlsContainerPositionChange(entry);
  }

  ngOnDestroy() {
    this.keyboard.unlisten(KeyCodes.A, this.onAKey);
    this.keyboard.unlisten(KeyCodes.V, this.onVKey);
    this.keyboard.unlisten(KeyCodes.H, this.onHKey);
    this.subscriptions.unsubscribe();
  }

  private lifecycleStateChange(value: LifecycleStateEnum) {
    if (!this.config.agentParamEnabled(AgentParam.AUTO_HOLD_ON_BLUR)) {
      return;
    }
    if (value !== this._lifecycleState) {
      switch (value) {
        case LifecycleStateEnum.blur:
          if (!this.isOnHold()) {
            this.toggleHold();
            this.isOnHoldFromBlur.set(true);
          }
          break;
        case LifecycleStateEnum.focus:
          if (this.isOnHoldFromBlur()) {
            this.toggleHold();
            this.isOnHoldFromBlur.set(false);
          }
          break;
        case LifecycleStateEnum.stop:
          // do nothing..?
          break;
      }
      this._lifecycleState = value;
    }
  }

  /* actions */

  selectFile() {
    this.fileInputRef.nativeElement.click();
  }

  togglePortraitMode() {
    this.mediaEffects.setPortraitModeId(
      this.localStream.id,
      !this.isPortraitMode
    );
  }

  async switchCam() {
    if (this.isSwitching) {
      return;
    }

    this.isSwitching = true;
    this.activityService.loading(true, "switching");

    try {
      await this.local.switchCamera();
    } catch (ex) {
      debugError(ex);
    } finally {
      this.isSwitching = false;
      this.activityService.hide();
      this.cdr.detectChanges();
    }
  }

  private togglingKind = {
    audio: null as Promise<void>,
    video: null as Promise<void>,
  };

  toggleAssistant() {
    this.assistant.toggleOpen(!this.isAssistantOpen);
  }

  async toggleMute(kind: StreamTrackKindEnum) {
    if (this.togglingKind[kind]) {
      return;
    }

    let toggleError = false;

    if (kind === StreamTrackKindEnum.audio) {
      this.isMutedMic
        ? this.activityService.showIconMessage("mic", "Please wait...")
        : this.activityService.showIconMessage("mic-mute", "Muted");
    } else if (kind === StreamTrackKindEnum.video) {
      this.isMutedCam
        ? this.activityService.showIconMessage("cam", "Please wait...")
        : this.activityService.showIconMessage("cam-mute", "Cam Off");
    }

    this.togglingKind[kind] = (
      kind === StreamTrackKindEnum.audio ? this.isMutedMic : this.isMutedCam
    )
      ? this.local.unmute(kind)
      : this.local.mute(kind);

    try {
      await this.togglingKind[kind];
    } catch (ex) {
      debugError(ex);
      toggleError = true;
      this.mediaError.emit(ex);
      // this.notification.error(ex.message, { body: "enable access" });
    } finally {
      this.togglingKind[kind] = null;
      if (this.buttonToReFocus) {
        this.buttonToReFocus.focus();
        this.buttonToReFocus = undefined;
      }
    }
    toggleError ? this.activityService.hide() : this.toggleMuteResult(kind);
  }

  toggleMuteResult(kind: StreamTrackKindEnum) {
    let enabled: boolean;

    if (kind === StreamTrackKindEnum.audio) {
      this.isMutedMic
        ? this.activityService.showIconMessage("mic-mute", "Muted", 1)
        : this.activityService.showIconMessage("mic", "Unmuted", 1);
      enabled = !this.isMutedMic;
    } else if (kind === StreamTrackKindEnum.video) {
      this.isMutedCam
        ? this.activityService.showIconMessage("cam-mute", "Cam Off", 1)
        : this.activityService.showIconMessage("cam", "Cam On", 1);
      enabled = !this.isMutedCam;
    }

    if (this.room.isChildWindow) {
      this.windowEventService.sendMessage(new MuteChanged({ kind, enabled }));
    }
  }

  toggleHold() {
    this.conferenceService.toggleCallHold();
  }

  leaveConference() {
    if (this.user.isAgent) {
      return;
    }
    this.leave.emit();
  }

  navigateToEffects() {
    this.effectsRef.navigateIn();
  }

  navigateOutOfEffects() {
    this.effectsMenuItemRef.focus();
  }

  leaveKeyUp(ev: KeyboardEvent) {
    if (ev.code === KeyCodes.Space) {
      this.isLeavePopupOpen.set(!this.isLeavePopupOpen());
      if (this.isLeavePopupOpen()) {
        this.windowService
          .onClickedOutside(this.leaveButtonRef.host.nativeElement)
          .then(() => this.isLeavePopupOpen.set(false));
      }
    }
  }

  async changeDevice(device: IDevice) {
    try {
      await this.local.openDeviceStream(device.kind, device.deviceId);
      this.cdr.detectChanges();
    } catch (ex) {
      this.notification.error("DeviceService switch failed", {
        body: ex.message || ex,
      });
    }
  }

  terminateConference() {
    this.terminate.emit();
  }

  exitConference() {
    this.exit.emit();
  }

  fileAdded(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];

      // broadcast to all participants
      this.fileTransferTargets().forEach((target) => {
        this.transferService.sendFileToParticipant(
          file,
          target,
          this.interaction
        );
      });
    }

    this.fileInputRef.nativeElement.value = "";
  }

  // this is terminate as a customer, we need to start it from the widget (sender)
  terminateCobrowse() {
    this.windowEventService.sendMessage(new CobrowseTerminateRequestAction());
  }

  /* getters */

  get canTerminate() {
    return (
      !this.isWhisper &&
      !this.interaction.isTransfer() &&
      this.interaction.getType() !== ConversationTypeEnum.callback &&
      // in a web messaging application the agent cannot terminate the conversation via API?
      !(
        this.interaction.getOrigin() === ConversationOriginEnum.APPOINTMENT &&
        this.interaction.getChannel() ===
          ConversationChannelEnum.genesysWebMessaging
      ) &&
      // in any other appointment, the agent can terminate if the customer has not yet joined the call
      !this.isWaitingForCustomerInAppointment
    );
  }

  get isSizeMedium() {
    return this.size === SizeEnum.medium;
  }

  get isSizeLarge() {
    return this.size === SizeEnum.large;
  }

  get isPortraitMode() {
    return this.localStream?.video.portraitMode === true;
  }

  get isPortraitModeAvailable() {
    return this.user.isAgent;
  }

  get isPortraitModeDisabled() {
    return (
      this.isPointerActive ||
      this.isCobrowseAgentActive ||
      this.isSnapshotActive ||
      this.isPopedOut ||
      this.isMutedCam
    );
  }

  get isCobrowseAgentActive() {
    return this.cobrowseService.isTarget(this.myself.endpoint);
  }

  get isCobrowseTerminateAvailable() {
    return computed(
      () => this.user.isCustomer && this.isCobrowseCustomerActive()
    );
  }

  get isCamDisabled() {
    return computed(
      () =>
        this.isVideoDeviceMissing ||
        this.isOnHold() ||
        this.isMutingCam ||
        this.isSwitching ||
        this.isPointerActive ||
        this.isSnapshotActive ||
        this.isPublishingStream
    );
  }

  get isMicDisabled() {
    return computed(
      () =>
        this.isAudioDeviceMissing ||
        this.isOnHold() ||
        this.isMutingMic ||
        this.isPublishingStream
      // || this.isPointerActive
    );
  }

  get isPointerActive() {
    return (
      this.localStream &&
      this.pointerService.isTarget(this.myself.endpoint, this.localStream.type)
    );
  }

  get isSnapshotActive() {
    return this.snapshotService.isTarget(this.myself.endpoint);
  }

  get isFilterAvailable() {
    return (
      this.isVideoDeviceAvailable && this.mediaEffects.supportsMediaFilters
    );
  }

  get isFilterEnabled() {
    return !this.isMutedCam; // && !this.mediaFilterError;
  }

  get isFilterOn() {
    return this.mediaEffects.isMediaFilterOn;
  }

  get isMutingCam(): boolean {
    return this.localStream?.video.state === "muting";
  }

  get isMutingMic(): boolean {
    return this.localStream?.audio.state === "muting";
  }

  get isUnmutingCam(): boolean {
    return this.localStream?.video.state === "unmuting";
  }
  get isUnmutingMic(): boolean {
    return this.localStream?.audio.state === "unmuting";
  }

  get isOnHold(): Signal<boolean> {
    return computed(() => this.callHoldState().isOnHold);
  }

  get isHoldDisabled() {
    return computed(
      () =>
        (this.isOnHold() && !this.isHoldLocal()) ||
        this.isMutingCam ||
        this.isMutingMic ||
        this.isUnmutingCam ||
        this.isUnmutingMic
    );
  }

  get isHoldLocal() {
    return computed(
      () =>
        this.callHoldState().username === this.myself.username ||
        this.user.isAgent
    );
  }
  get isHoldEnabled(): boolean {
    return (
      this.mediaRules.isHoldAvailable &&
      this.mediaControlsEnabled &&
      !this.isWhisper
    );
  }

  get isAudioDeviceAvailable(): boolean {
    return (
      this.mediaRules.isAudioControlAvailable &&
      this.mediaControlsEnabled &&
      !this.isWhisper
    );
  }

  get isAudioDeviceMissing(): boolean {
    return MediaDevices.has.audioinput === 0;
  }

  get isAudioDevicePermissionMissing(): boolean {
    return !MediaDevices.permissions.audioinput;
  }

  get isVideoDeviceAvailable(): boolean {
    return (
      this.mediaRules.isVideoControlAvailable &&
      this.interaction.getType() !== ConversationTypeEnum.voiceCall &&
      this.mediaControlsEnabled &&
      !this.isWhisper
    );
  }

  get isVideoDeviceMissing(): boolean {
    return MediaDevices.has.videoinput === 0;
  }

  get isVideoDevicePermissionMissing(): boolean {
    return !MediaDevices.permissions.videoinput;
  }

  get activeAudioDevice(): IDevice {
    return (
      this.local.audioDevice || {
        label: this.translate.instant("No microphone"),
        kind: "audiooutput",
        deviceId: null,
      }
    );
  }

  get activeSpeakerDevice(): IDevice {
    return this.local.speakerDevice;
  }

  get activeVideoDevice(): IDevice {
    return (
      this.local.videoDevice || {
        label: this.translate.instant("No camera"),
        kind: "videoinput",
        deviceId: null,
      }
    );
  }

  get audioDevices(): IDevice[] {
    return MediaDevices.getDeviceList("audioinput");
  }

  get speakerDevices(): IDevice[] {
    return MediaDevices.getDeviceList("audiooutput");
  }

  get videoDevices(): IDevice[] {
    return MediaDevices.getDeviceList("videoinput");
  }

  get isMutedMic(): boolean {
    return this.localStream?.audio.state !== "enabled";
  }

  get isMutedCam(): boolean {
    return this.localStream?.video.state !== "enabled";
  }

  get hasMultipleCameras() {
    return MediaDevices.has.videoinput > 1;
  }
  get isLeaveAvailable() {
    return this.mediaRules.isLeaveAvailable(this.interaction);
  }

  get isWaitingForCustomerInAppointment() {
    return (
      !this.isCustomerJoined &&
      this.interaction.getOrigin() === ConversationOriginEnum.APPOINTMENT
    );
  }

  get isMobile() {
    return DeviceService.isMobile;
  }

  get isSwitchCamDisabled() {
    return computed(
      () =>
        this.isOnHold() ||
        this.isMutedCam ||
        this.isSwitching ||
        this.isPointerActive
    );
  }

  get isSwitchCamAvailable() {
    return (
      this.isVideoDeviceAvailable &&
      this.isFacingModeSupported &&
      this.isMobile &&
      this.hasMultipleCameras &&
      this.mediaControlsEnabled
    );
  }

  get conferenceId() {
    return this.interaction.getRoom();
  }

  private get myself(): IEndpoint {
    return this.rtcService.myself;
  }

  get isCaptionAvailable() {
    return this.mediaRules.isCaptionsControlAvailable && !!this.localStream;
  }

  get isDisplayCaptureAvailable() {
    return (
      this.mediaRules.isDisplayCaptureAvailable &&
      this.mediaControlsEnabled &&
      !this.isWhisper
    );
  }

  get size() {
    return this.user.isCustomer
      ? this.config.customerParam(CustomerParam.MEDIA_CONTROL_SIZE)
      : this.buttonSize;
  }

  get isCenterControlVisible() {
    return (
      this.isAudioDeviceAvailable ||
      this.isVideoDeviceAvailable ||
      this.isSwitchCamAvailable ||
      this.isHoldEnabled ||
      this.isDisplayCaptureAvailable
    );
  }

  get isSpeakerAvailable() {
    return (
      /* this.mediaRules.isSpeakerAvailable && */ Device.isSpeakerSelectionSupported &&
      this.speakerDevices.length
    );
  }

  get istream() {
    return this.local.mainStream.stream;
  }

  get isFileTransferDisabled() {
    return computed(
      () => this.isOnHold() || this.fileTransferTargets().length == 0
    );
  }
}
