import { Injectable } from "@angular/core";
import {
  IVisionStrategy,
  OpenAITranscriptStrategy,
} from "../models/strategies";
import { AppConfigService } from "./app.config.service";
import { UserService } from "./user.service";
import { AgentParam, IVisionSnapshotState, VisionState } from "../models";
import {
  AIVisionEvent,
  ApplicationProviderTypeEnum,
  SNAPSHOT_VISION_TYPE,
  UserRoleEnum,
} from "../core-ui.enums";
import { GenericErrorHandler } from "./error-handlers.service";
import { AuviousRtcService } from "./rtc.service";
import { ApplicationService } from "./application.service";
import { IEndpoint } from "@auvious/rtc";
import { SnapshotService } from "./snapshot.service";
import { BehaviorSubject, firstValueFrom, map, Subject } from "rxjs";
import { ISnapshot } from "@auvious/snapshot";
import { blobToBase64, debugError } from "./utils";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class VisionService {
  private impl: IVisionStrategy;

  private snapshotState: Map<
    string,
    Pick<IVisionSnapshotState, "result" | "state">
  > = new Map();

  private requestState: Map<string, string> = new Map();

  private _imageStateChange = new BehaviorSubject<IVisionSnapshotState>(
    undefined
  );
  public imageStateChange$ = this._imageStateChange.asObservable();

  private _imageChange = new BehaviorSubject<ISnapshot>(undefined);
  public imageChange$ = this._imageChange.asObservable();

  private _resultChange = new BehaviorSubject<{
    snapshotId: string;
    result: string;
  }>(undefined);
  public resultChange$ = this._resultChange.asObservable();

  constructor(
    private config: AppConfigService,
    private user: UserService,
    private rtc: AuviousRtcService,
    private logger: GenericErrorHandler,
    private application: ApplicationService,
    private snapshotService: SnapshotService,
    private http: HttpClient
  ) {
    this.config.configChanged$.subscribe((c) => {
      let provider;
      if (this.user.isAdmin) {
        provider = c.serviceParameters?.transcriptProvider;
      } else if (this.user.isAgent) {
        if (c.agentParameters?.[AgentParam.TRANSCRIPT_PROVIDER]) {
          provider = c.agentParameters[AgentParam.TRANSCRIPT_PROVIDER];
        }
      }
      if (provider) this.providerChanged(provider);
    });

    this.snapshotService.snapshotsAvailable$
      .pipe(map((a) => a.filter((s) => s.type === SNAPSHOT_VISION_TYPE)))
      .subscribe((snapshots) => {
        const latestSnapshot = snapshots.find(
          (s) =>
            !this.snapshotState.has(s.id) ||
            this.snapshotState.get(s.id)?.state === "snapshotRequested"
        );
        if (latestSnapshot) {
          this._imageChange.next(latestSnapshot);
          if (latestSnapshot.signedUrl) {
            // we have the actual snapshot
            this.setSnapshotState(latestSnapshot.id, "snapshotReady");
            this.loadImageAndRecognize(latestSnapshot);
          } else {
            // this is the temp snapshot until we get the new one
            this.setSnapshotState(latestSnapshot.id, "snapshotRequested");
          }
        }
      });
  }

  private setSnapshotState(id: string, state: VisionState, result?: string) {
    this.snapshotState.set(id, { state, result });
    this._imageStateChange.next({ id, state, result });
  }

  private async loadImageAndRecognize(snapshot: ISnapshot) {
    try {
      const url = await firstValueFrom(snapshot.signedUrl);
      const imageBase64 = await this.convertImageToBase64(url);
      const response = await this.recognize(imageBase64);
      this.setSnapshotState(snapshot.id, "analysisRequested");
      this.requestState.set(response.id, snapshot.id);
    } catch (ex) {
      debugError(ex);
    }
  }

  public supported(endpoint: IEndpoint): boolean {
    const roles = endpoint?.metadata?.roles || [];
    // const capabilities = endpoint?.metadata?.capabilities || [];
    const hasCustomerRole = roles.includes(UserRoleEnum.customer);
    return hasCustomerRole;
  }

  public providerChanged(provider: ApplicationProviderTypeEnum) {
    switch (provider) {
      case ApplicationProviderTypeEnum.OPEN_AI:
        this.impl = new OpenAITranscriptStrategy(
          this.logger,
          this.rtc,
          this.application
        );
        break;
    }
  }

  private async convertImageToBase64(imageUrl: string): Promise<string> {
    try {
      const blob = await firstValueFrom(
        this.http.get(imageUrl, { responseType: "blob" })
      );
      const base64 = await blobToBase64(blob);
      return base64;
    } catch (ex) {
      debugError(ex);
      throw ex;
    }
  }

  public propagateEvent(event: {
    id: string;
    result: string;
    timestamp: string;
    type: AIVisionEvent;
  }) {
    let snapshotId;
    switch (event.type) {
      case "AIRecognitionCreatedEvent":
        snapshotId = this.requestState.get(event.id);
        if (snapshotId) {
          this.setSnapshotState(snapshotId, "analysisReady", event.result);
        }
        break;
      case "AIRecognitionFailedEvent":
        snapshotId = this.requestState.get(event.id);
        if (snapshotId) {
          this.setSnapshotState(snapshotId, "analysisReady");
        }
    }
  }

  recognize(imageBase64: string, model?: string): Promise<{ id: string }> {
    return this.impl.recognize(
      this.config.agentParam(AgentParam.AI_VISION_PROMPT),
      imageBase64,
      model
    );
  }
}
