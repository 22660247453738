import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  WritableSignal,
  computed,
  signal,
} from "@angular/core";
import { ISnapshot } from "@auvious/snapshot";
import { firstValueFrom, merge, map, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import {
  fadeInOut,
  slideFromLeft,
  snapshotSlide,
} from "../../core-ui.animations";
import {
  LayoutEnum,
  SegmentState,
  SNAPSHOT_VISION_TYPE,
  TileTypeEnum,
  TorchStateEnum,
} from "../../core-ui.enums";
import {
  AgentParam,
  IArea,
  IInteraction,
  ISnapshotMetadata,
  ITag,
  ITile,
} from "../../models";
import {
  AuviousRtcService,
  ConferenceService,
  debug,
  KeyboardService,
  KeyCodes,
  SnapshotService,
  StreamState,
  AppConfigService,
} from "../../services";
import { NotificationService } from "../../services/notification.service";
import { ImageComponent } from "../../../modules/shared/components/image/image.component";
import { SnapshotComponent } from "../../../modules/shared/components/snapshot/snapshot.component";

@Component({
  selector: "app-snapshot-session",
  templateUrl: "./snapshot-session.component.html",
  styleUrls: ["./snapshot-session.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [snapshotSlide, slideFromLeft, fadeInOut],
})
export class SnapshotSessionComponent
  implements OnInit, OnDestroy, AfterViewInit, ITile
{
  @Input() stream: StreamState;
  @Input() review: boolean;
  @Input() interaction: IInteraction;

  @Output() destroyed: EventEmitter<SnapshotSessionComponent> =
    new EventEmitter();
  @Output() ready: EventEmitter<SnapshotSessionComponent> = new EventEmitter();

  @ViewChildren("snapshotThumbnails")
  snapshotThumbRefs: QueryList<SnapshotComponent>;
  @ViewChild("snapshotStrip") snapshotStripRef: ElementRef<HTMLDivElement>;
  // @ViewChildren('snapshotImages') snapshotsRefs: QueryList<ImageComponent>;
  @ViewChild("snapshotImage") snapshotRef: ImageComponent;

  @ViewChild("tagsStrip") tagsStripRef: ElementRef<HTMLDivElement>;
  @ViewChildren("tags") tagsRefs: QueryList<ElementRef<HTMLDivElement>>;

  videoElem: HTMLVideoElement;
  subscriptions: Subscription = new Subscription();
  snapshots: ISnapshot[] = [];
  torchState: TorchStateEnum;
  expandedMap: { [id: string]: boolean } = {};

  activeSnapshot: WritableSignal<ISnapshot> = signal(undefined);
  activeSnapshotMetadata: ISnapshotMetadata;

  // keeps a track of the loaded/loaded-with-error snapshots during review. boolean is if it has an error
  loadedSnapshotsWithError: Map<string, boolean> = new Map();

  layoutType: LayoutEnum;
  segmentState: WritableSignal<SegmentState> = signal(undefined);

  isConfirmOpen = signal(false);
  isCameraSwitchInProgress = signal(false);
  isCameraSwitchFailed = signal(false);
  isLoading = signal(false);
  isImageReady = signal(false);
  isSkipVisible = signal(false);
  isHealthySnapshotMissing = signal(false);
  isSegmentAvailable = false;

  constructor(
    private snapshotService: SnapshotService,
    private cd: ChangeDetectorRef,
    private conferenceService: ConferenceService,
    private renderer: Renderer2,
    private notification: NotificationService,
    private host: ElementRef<HTMLDivElement>,
    private rtcService: AuviousRtcService,
    private keyboard: KeyboardService,
    private config: AppConfigService
  ) {}

  ngOnInit(): void {
    this.isSegmentAvailable = this.config.agentParamEnabled(
      AgentParam.SNAPSHOT_SEGMENT_ENABLED
    );

    this.subscriptions.add(
      this.snapshotService.snapshotsAvailable$
        .pipe(map((a) => a.filter((s) => s.type !== SNAPSHOT_VISION_TYPE)))
        .subscribe((snapshots) => {
          this.snapshots = snapshots;
          this.cd.detectChanges();

          const width = this.snapshotThumbRefs.reduce(
            (prev, next) => prev + next.width + 5,
            0
          );
          this.renderer.setStyle(
            this.snapshotStripRef.nativeElement,
            "width",
            `${width}px`
          );
          if (!this.activeSnapshot()) {
            // calculate scroll offset
            const offset = width - this.host.nativeElement.clientWidth;
            if (offset > 0) {
              this.snapshotStripRef.nativeElement.parentElement.scrollTo({
                left: offset,
                behavior: "smooth",
              });
            }
          }
        })
    );
    this.subscriptions.add(
      this.snapshotService.torchStateChanged$
        .pipe(filter((s) => s.endpoint === this.stream?.originator.endpoint))
        .subscribe((s) => {
          this.torchState = s.state;
          this.cd.markForCheck();
        })
    );
    this.subscriptions.add(
      this.snapshotService.cameraFacingModeStateChanged$
        .pipe(filter((s) => s.endpoint === this.stream?.originator.endpoint))
        .subscribe((s) => {
          this.isCameraSwitchInProgress.set(false);
          this.isCameraSwitchFailed.set(!s.success);
          this.cd.markForCheck();
        })
    );
    this.subscriptions.add(
      merge(
        this.conferenceService.streamMetadataChanged$,
        this.conferenceService.endpointMetadataChanged$
      )
        .pipe(
          filter((s) => s?.userEndpointId === this.stream?.originator.endpoint)
        )
        .subscribe((s) => {
          this.cd.markForCheck();
        })
    );
  }

  ngAfterViewInit() {
    if (!this.review) {
      this.snapshotService.start(this.stream.id);
    }

    this.keyboard.listen(KeyCodes.Del, this.onDelKey);
    this.keyboard.listen(KeyCodes.Esc, this.onEscKey);

    this.ready.emit(this);
  }

  ngOnDestroy() {
    this.keyboard.unlisten(KeyCodes.Del, this.onDelKey);
    this.keyboard.unlisten(KeyCodes.Esc, this.onEscKey);
    this.subscriptions.unsubscribe();
    this.destroyed.emit(this);
  }

  snapshotReady(s: ISnapshot) {
    if (!this.review) {
      return;
    }
    if (!this.activeSnapshot()) {
      // we got the first snapshot that is ready, load it
      this.activeSnapshot.set(s);
    }
    this.loadedSnapshotsWithError.set(s.id, false);
    this.snasphotHealthCheck();
  }

  snapshotError(s: ISnapshot) {
    if (!this.review) {
      return;
    }
    this.loadedSnapshotsWithError.set(s.id, true);
    this.snasphotHealthCheck();
  }

  snasphotHealthCheck() {
    // check if have loaded all the snapshots and if all the snapshots have loaded with error.
    // If yes, then we can show the skip button
    if (
      Array.from(this.loadedSnapshotsWithError.values()).filter(
        (error) => error
      ).length === this.endpointSnapshots.length
    ) {
      this.isHealthySnapshotMissing.set(true);
      this.isSkipVisible.set(true);
    }
  }

  async selectSnapshot(snapshot: ISnapshot) {
    if (
      // do not select a snapshot without a url
      !snapshot?.signedUrl ||
      // do not select the same one
      (!!this.activeSnapshot() && snapshot.id === this.activeSnapshot().id)
    ) {
      return;
    }
    if (
      !!this.snapshotRef &&
      (this.isSegmentSet() || this.isSegmentLoading())
    ) {
      this.cancelSegment();
    }
    this.isImageReady.set(false);
    const s = this.snapshotThumbRefs.find(
      (t) => t.snapshot?.id === snapshot.id
    );
    if (s?.failed()) {
      return;
    }
    if (s?.isLoading()) {
      debug("Snapshot loading...");
      await firstValueFrom(s.ready);
    }
    debug("Snapshot loaded");
    if (this.activeSnapshot()) {
      this.expandedMap[this.activeSnapshot().id] = false;
    }
    this.activeSnapshot.set(snapshot);
    this.activeSnapshotMetadata = this.snapshotService.getSnapshotMetadata(
      snapshot.id
    );
    this.cd.detectChanges();

    // calculate tag strip size
    const width = this.tagsRefs.reduce(
      (prev, next) => prev + next.nativeElement.getBoundingClientRect().width,
      0
    );
    this.renderer.setStyle(
      this.tagsStripRef.nativeElement,
      "width",
      `${Math.round(width)}px`
    );
  }

  private onDelKey = () => {
    if (this.activeSnapshot) {
      this.removeSnapshot(this.activeSnapshot());
    }
  };

  private onEscKey = () => {
    this.end();
  };

  removeTag() {
    this.snapshotService.unTagSnapshot(this.activeSnapshot());
  }

  addTag(tag: ITag) {
    this.snapshotService.tagSnapshot(this.activeSnapshot(), tag);
  }

  async takeSnapshot() {
    if (this.activeSnapshot()) {
      this.activeSnapshot.set(null);
    } else {
      this.snapshotService.requestSnapshot(
        this.stream.originator,
        this.interaction
      );
    }
  }

  removeSnapshot(
    s: ISnapshot,
    index = this.endpointSnapshots.findIndex((es) => es === s)
  ) {
    this.snapshotService.discardSnapshot(s);
    if (this.endpointSnapshots.length > 0) {
      this.isImageReady.set(false);
      // auto-select the previous one
      const idx = index - 1 >= 0 ? index - 1 : 0;
      this.activeSnapshot.set(this.endpointSnapshots[idx]);
    } else {
      this.activeSnapshot.set(null);
    }
    this.cd.detectChanges();
  }

  end() {
    this.snapshotService.stop(this.stream.originator);
  }

  toggleTorch() {
    this.snapshotService.requestTorchChange(this.stream.originator);
  }

  switchCam() {
    if (this.isCameraSwitchInProgress()) {
      return;
    }
    this.snapshotService.requestSwitchCamera(this.stream.originator);
    this.isCameraSwitchInProgress.set(true);
  }

  rotateLeft() {
    this.snapshotRef.rotate(-90);
  }

  rotateRight() {
    this.snapshotRef.rotate(90);
  }

  zoomIn() {
    this.snapshotRef.zoom(25);
  }

  zoomOut() {
    this.snapshotRef.zoom(-25);
  }

  fillFit() {
    if (this.isExpanded) {
      this.snapshotRef.fit();
      this.expandedMap[this.activeSnapshot().id] = false;
    } else {
      this.snapshotRef.expand();
      this.expandedMap[this.activeSnapshot().id] = true;
    }
  }

  crop() {
    this.snapshotRef.crop();
  }

  segment() {
    this.snapshotRef.segmentImage();
  }

  async saveSegment() {
    this.snapshotService.updateSnapshot(
      this.activeSnapshot(),
      this.interaction,
      this.snapshotRef.src
    );

    this.snapshotRef.resetSegmentState();
  }

  cancelSegment() {
    this.snapshotRef.cancelSegmentation();
  }

  segmentStateChanged(state: SegmentState) {
    this.segmentState.set(state);
  }

  metadataChanged(payload: { id: string; metadata: ISnapshotMetadata }) {
    this.snapshotService.setSnapshotMedatata(
      this.activeSnapshot(),
      payload.metadata
    );
  }

  imageReady() {
    this.isImageReady.set(true);
    // this.segment();
  }

  async approveAll() {
    // check if all have types else notify to add type
    // todo: filter out discarded + error snapshots
    const error =
      this.endpointSnapshots.filter((s) => s.type === null).length > 0;
    if (error) {
      return this.notification.warn("Tag is missing", {
        body: "Not all snapshots have a tag. Please add a tag to all and try again",
      });
    }
    this.isLoading.set(true);
    try {
      await this.snapshotService.approveSnapshots(
        this.endpointSnapshots.filter((s) => s.state !== "APPROVED")
      );
    } catch (ex) {
      this.isSkipVisible.set(true);
      this.notification.error("Could not approve snapshots", {
        body: ex.message || ex,
      });
    } finally {
      this.isLoading.set(false);
      this.cd.detectChanges();
    }
  }

  skip() {
    this.snapshotService.skipApproval();
  }

  setLayoutType(type: LayoutEnum) {
    this.layoutType = type;
  }

  mediaElementReady(element: HTMLVideoElement) {
    this.videoElem = element;
  }

  // trackBy fn

  trackByFn(i, el: ISnapshot) {
    return el.id;
  }

  trackByTag(i, e: ITag) {
    return e.value;
  }

  // getters

  get isExpanded() {
    return this.expandedMap[this.activeSnapshot()?.id];
  }

  get isSegmentLoading() {
    return computed(() => this.segmentState() === SegmentState.Setting);
  }

  get isSegmentSet() {
    return computed(() => this.segmentState() === SegmentState.Set);
  }

  get isSegmentOff() {
    return computed(() => this.segmentState() === SegmentState.Off);
  }

  // get isSegmentOn() {
  //   return computed(() => this.segmentState() === SegmentState.On);
  // }

  empty(s: ISnapshot) {
    return !!s && !s.signedUrl ? "empty" : null;
  }

  get isTorchOn() {
    return this.torchState === TorchStateEnum.on;
  }

  get endpointSnapshots() {
    return this.review
      ? // get only my snapshots
        this.snapshots.filter(
          (s) => s.requesterUserId === this.rtcService.identity()
        )
      : // get only the snapshots for that participant
        this.snapshots.filter(
          (s) => s.userId === this.stream?.originator.username
        );
  }

  get isTorchAvailable() {
    return (
      !!this.stream && this.snapshotService.isTorchSupported(this.stream.id)
    );
  }

  get isTorchDisabled() {
    return computed(
      () =>
        this.torchState === TorchStateEnum.error ||
        !!this.activeSnapshot() ||
        !this.isTorchAvailable
    );
  }

  get hasMultipleCameras() {
    return (
      this.stream &&
      this.snapshotService.hasMutlipleCameras(this.stream.originator)
    );
  }

  get isCameraSwitchAvailable() {
    return this.snapshotService.isCameraSwitchAvailable;
  }

  get isCameraSwitchDisabled() {
    return computed(
      () =>
        !!this.activeSnapshot() ||
        this.isCameraSwitchFailed() ||
        !this.hasMultipleCameras
    );
  }

  get isStreamVisible() {
    return computed(
      () => !this.activeSnapshot() && !!this.stream && !this.review
    );
  }

  get isLoadingSnapshots() {
    return computed(
      () =>
        !this.isStreamVisible() &&
        !this.isHealthySnapshotMissing() &&
        this.endpointSnapshots.length > 0 &&
        (!this.activeSnapshot() || !this.isImageReady())
    );
  }

  get isEmptySnapshots() {
    return computed(
      () => !this.isStreamVisible() && this.endpointSnapshots.length === 0
    );
  }

  get tags() {
    return this.snapshotService.snapshotTypes;
  }

  get uuid() {
    return "snapshot-session";
  }

  get type() {
    return TileTypeEnum.snapshot;
  }

  get ratio() {
    return 4 / 3;
  }

  get container() {
    return this.host.nativeElement;
  }

  get videoSize(): IArea {
    return {
      width: this.videoElem?.videoWidth,
      height: this.videoElem?.videoHeight,
    };
  }

  get mediaStream() {
    return (
      this.stream &&
      this.conferenceService.getStream(this.stream.id)?.mediaStream
    );
  }
}
